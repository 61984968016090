import { Fragment, useState, useEffect, ChangeEvent } from "react"
import { useSelector } from "react-redux"

import { RootState } from "../app/store"

import { supabase } from "../supabaseClient"

import { Transition, Dialog, Combobox } from "@headlessui/react"

import { utils, writeFile } from "xlsx"

import { XIcon, ExclamationCircleIcon } from "@heroicons/react/solid"
import {
    RefreshIcon,
    PencilAltIcon,
    DocumentDownloadIcon,
} from "@heroicons/react/outline"

type SPV = {
    spvID: string
    name: string
    proID: string
    license: string
    signatory: string
    issue: string
    expiry: string
    created_at: string
}

type Pro = {
    proID: string
    address: string
    area: string
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const isInTheFuture = (givenDate: String) => {
    const now = new Date()

    now.setHours(23, 59, 59, 998)

    return (
        new Date(
            `${givenDate.substring(0, 4)}-${givenDate.substring(
                4,
                6
            )}-${givenDate.substring(6, 8)}`
        ) > now
    )
}

const RecSPVView = () => {
    const { recFormState, searchTermSPV } = useSelector(
        (state: RootState) => state.rec
    )

    const { role } = useSelector((state: RootState) => state.auth)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [edit, setEdit] = useState(false)
    const [saving, setSaving] = useState(false)
    const [errorDetails, setErrorDetails] = useState({
        code: "",
        message: "",
        details: "",
    })

    const [pros, setPros] = useState<Pro[]>([])
    const [propertyQuery, setPropertyQuery] = useState("")
    const [propertiesShown, setPropertiesShown] = useState(false)

    const filteredProperties =
        propertyQuery === ""
            ? []
            : pros.filter((recPro) => {
                  return recPro.proID
                      .toLowerCase()
                      .includes(propertyQuery.toLowerCase())
              })

    const [spvData, setSPVData] = useState<SPV>({
        spvID: "",
        name: "",
        proID: "",
        license: "",
        signatory: "",
        issue: "",
        expiry: "",
        created_at: "",
    })

    const [spvs, setSPVs] = useState<SPV[]>([])
    const [spvsResult, setSPVsResult] = useState<SPV[]>([])

    const [invalid, setInvalid] = useState(false)

    const { spvID, name, proID, license, signatory, issue, expiry } = spvData

    const getSPVs = async () => {
        setSPVs([])
        setLoading(true)
        setError(false)
        const { data, error } = await supabase
            .from<SPV>("R_SPV")
            .select()
            .order("spvID", { ascending: true })
        if (data) {
            setSPVs(data)
            setSPVsResult(data)
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }

        setLoading(false)
    }

    const getPros = async () => {
        setPros([])
        setLoading(true)
        setError(false)
        const { data, error } = await supabase
            .from<Pro>("R_Pro")
            .select("proID,address,area")
            .order("proID", { ascending: true })
        if (data) {
            setPros(data)
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }

        setLoading(false)
    }

    useEffect(() => {
        getSPVs()
        getPros()
    }, [recFormState])

    useEffect(() => {
        if (searchTermSPV === "") {
            setSPVsResult(spvs)
        } else {
            setSPVsResult(
                spvs.filter((recSPV) => {
                    return (
                        recSPV.spvID
                            .toLowerCase()
                            .includes(searchTermSPV.toLowerCase()) ||
                        recSPV.name
                            .toLowerCase()
                            .includes(searchTermSPV.toLowerCase()) ||
                        recSPV.license
                            .toLowerCase()
                            .includes(searchTermSPV.toLowerCase()) ||
                        recSPV.proID
                            .toLowerCase()
                            .includes(searchTermSPV.toLowerCase())
                    )
                })
            )
        }
    }, [searchTermSPV])

    const onSubmit = async () => {
        if (
            spvID === "" ||
            name === "" ||
            proID === "" ||
            license === "" ||
            signatory === "" ||
            issue === "" ||
            isInTheFuture(issue) ||
            expiry === ""
        ) {
            setInvalid(true)
        } else {
            setInvalid(false)
            setSaving(true)
            const { error } = await supabase
                .from<SPV>("R_SPV")
                .update(
                    {
                        spvID: spvID,
                        name: name,
                        proID: proID,
                        license: license,
                        signatory: signatory,
                        issue: issue,
                        expiry: expiry,
                    },
                    { returning: "minimal" }
                )
                .match({ spvID: spvID })

            setSaving(false)
            if (error) {
                setError(true)
                setErrorDetails({
                    code: error.code,
                    message: error.message,
                    details: error.details,
                })
            } else {
                getSPVs()
                setEdit(false)
            }
        }
    }

    const onChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => {
        if (e.target.name === "issue" || e.target.name === "expiry") {
            setSPVData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        } else {
            setSPVData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        }
    }

    const xlsxExprt = () => {
        const today = new Date()
        const worksheet = utils.json_to_sheet(spvs)
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, "SPVs")
        writeFile(
            workbook,
            `SPVs_${
                today.getDate().toString().length === 1
                    ? "0" + today.getDate().toString()
                    : today.getDate().toString()
            }-${
                today.getMonth().toString().length === 1
                    ? "0" + (today.getMonth() + 1).toString()
                    : (today.getMonth() + 1).toString()
            }-${today.getFullYear()}.xlsx`
        )
    }

    return (
        <>
            {loading ? (
                <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                    <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                        <svg
                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                        >
                            <circle
                                className='opacity-25'
                                cx='12'
                                cy='12'
                                r='10'
                                stroke='currentColor'
                                strokeWidth='4'
                            ></circle>
                            <path
                                className='opacity-75'
                                fill='currentColor'
                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                            ></path>
                        </svg>
                        Loading ...
                    </div>
                </div>
            ) : (
                <div className='mt-7'>
                    <div className='flex flex-row justify-between '>
                        <div className='pt-2 justify-self-start '>
                            <h3 className='text-lg leading-6 font-medium text-gray-200'>
                                SPV List
                            </h3>
                            <p className='mt-1 max-w-2xl text-sm text-gray-300'></p>
                        </div>
                        <div className='justify-self-end'>
                            <div className='flex flex-col'>
                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            getSPVs()
                                        }}
                                        type='button'
                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <RefreshIcon
                                            className='h-5 w-5 pr-1'
                                            aria-hidden='true'
                                        />
                                        <span>Refresh</span>
                                    </button>
                                </span>
                                <span className='mt-6 relative z-0 inline-flex shadow-sm rounded-md'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            xlsxExprt()
                                        }}
                                        type='button'
                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <DocumentDownloadIcon
                                            className='h-5 w-5 pr-1'
                                            aria-hidden='true'
                                        />
                                        <span>Excel file export</span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='mx-8 mt-8 flex flex-col '>
                        <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-auto'>
                            <div className='inline-block min-w-full py-2 align-middle'>
                                <div className='shadow-sm ring-1 ring-black ring-opacity-5 max-h-[43rem]'>
                                    <table
                                        className='min-w-full border-separate'
                                        style={{ borderSpacing: 0 }}
                                    >
                                        <thead className='bg-gray-900'>
                                            <tr>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'
                                                >
                                                    SPV ID
                                                </th>
                                                {role.rec_e ? (
                                                    <th
                                                        scope='col'
                                                        className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filtersm:pr-6 lg:pr-8'
                                                    >
                                                        <span className='sr-only'>
                                                            Edit
                                                        </span>
                                                    </th>
                                                ) : (
                                                    ""
                                                )}
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'
                                                >
                                                    Name (Trade Name)
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:table-cell'
                                                >
                                                    License Number
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter lg:table-cell'
                                                >
                                                    Authorized Signatory
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Issue Date
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Expiry Date
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Property ID
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='bg-gray-700'>
                                            {spvsResult.map(
                                                (recSPV, recSPVIdx) => (
                                                    <tr key={recSPV.spvID}>
                                                        <td
                                                            className={classNames(
                                                                recSPVIdx !==
                                                                    spvs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6 lg:pl-8"
                                                            )}
                                                        >
                                                            {recSPV.spvID}
                                                        </td>
                                                        {role.rec_e ? (
                                                            <td
                                                                className={classNames(
                                                                    recSPVIdx !==
                                                                        spvs.length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                                                                )}
                                                            >
                                                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                                                    <button
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault()
                                                                            setSPVData(
                                                                                recSPV
                                                                            )

                                                                            setEdit(
                                                                                true
                                                                            )
                                                                        }}
                                                                        type='button'
                                                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                                    >
                                                                        <PencilAltIcon
                                                                            className='h-5 w-5 pr-1'
                                                                            aria-hidden='true'
                                                                        />
                                                                        <span>
                                                                            Edit
                                                                        </span>
                                                                    </button>
                                                                </span>
                                                            </td>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <td
                                                            className={classNames(
                                                                recSPVIdx !==
                                                                    spvs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-7 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recSPV.name}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recSPVIdx !==
                                                                    spvs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recSPV.license}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recSPVIdx !==
                                                                    spvs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recSPV.signatory}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recSPVIdx !==
                                                                    spvs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {`${recSPV.issue.substring(
                                                                8,
                                                                11
                                                            )}-${recSPV.issue.substring(
                                                                5,
                                                                7
                                                            )}-${recSPV.issue.substring(
                                                                0,
                                                                4
                                                            )}`}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recSPVIdx !==
                                                                    spvs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {`${recSPV.expiry.substring(
                                                                8,
                                                                11
                                                            )}-${recSPV.expiry.substring(
                                                                5,
                                                                7
                                                            )}-${recSPV.expiry.substring(
                                                                0,
                                                                4
                                                            )}`}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recSPVIdx !==
                                                                    spvs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recSPV.proID}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className='z-20 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={error}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                {`Error ${errorDetails.code}: ${errorDetails.details}`}
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                {errorDetails.message}
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
            <Transition.Root show={edit} as={Fragment}>
                <Dialog as='div' className='relative z-10' onClose={setEdit}>
                    <div className='fixed inset-0' />

                    <div className='fixed inset-0 overflow-hidden'>
                        <div className='absolute inset-0 overflow-hidden'>
                            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='transform transition ease-in-out duration-500 sm:duration-700'
                                    enterFrom='translate-x-full'
                                    enterTo='translate-x-0'
                                    leave='transform transition ease-in-out duration-500 sm:duration-700'
                                    leaveFrom='translate-x-0'
                                    leaveTo='translate-x-full'
                                >
                                    <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                                        <form className='flex h-full flex-col divide-y divide-gray-200 bg-gray-900 shadow-xl'>
                                            <div className='h-0 flex-1 overflow-y-auto'>
                                                <div className='bg-yellow-500 py-6 px-4 sm:px-6'>
                                                    <div className='flex items-center justify-between'>
                                                        <Dialog.Title className='text-lg font-medium text-gray-900'>
                                                            {`Editing SPV ${spvID}`}
                                                        </Dialog.Title>
                                                        <div className='ml-3 flex h-7 items-center'></div>
                                                    </div>
                                                    {/* <div className='mt-1'>
                                                        <p className='text-sm text-indigo-300'>
                                                            Get started by
                                                            filling in the
                                                            information below to
                                                            create your new
                                                            project.
                                                        </p>
                                                    </div> */}
                                                </div>
                                                {saving ? (
                                                    <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                                                        <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                                                            <svg
                                                                className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                fill='none'
                                                                viewBox='0 0 24 24'
                                                            >
                                                                <circle
                                                                    className='opacity-25'
                                                                    cx='12'
                                                                    cy='12'
                                                                    r='10'
                                                                    stroke='currentColor'
                                                                    strokeWidth='4'
                                                                ></circle>
                                                                <path
                                                                    className='opacity-75'
                                                                    fill='currentColor'
                                                                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                                ></path>
                                                            </svg>
                                                            Saving ...
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='m-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-1'>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='name'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Name (Trade
                                                                Name)
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={name}
                                                                    name='name'
                                                                    id='name'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            name === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    SPV's name
                                                                    is empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='license'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                License Number
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={
                                                                        license
                                                                    }
                                                                    name='license'
                                                                    id='license'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            license === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    SPV's
                                                                    license
                                                                    number is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='signatory'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Authorized
                                                                Signatory
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={
                                                                        signatory
                                                                    }
                                                                    name='signatory'
                                                                    id='signatory'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            signatory === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    SPV's
                                                                    authorized
                                                                    signatory is
                                                                    invalid or
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='issue'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                License Issue
                                                                Date
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )
                                                                    }}
                                                                    value={
                                                                        issue
                                                                    }
                                                                    type='date'
                                                                    name='issue'
                                                                    id='issue'
                                                                    className=' pb-1 appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            (isInTheFuture(
                                                                issue
                                                            ) ||
                                                                issue ===
                                                                    "") ? (
                                                                <span className='text-sm text-red-500'>
                                                                    SPV's issue
                                                                    date cannot
                                                                    be in the
                                                                    future or
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='expiry'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                License Expiry
                                                                Date
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )
                                                                    }}
                                                                    value={
                                                                        expiry
                                                                    }
                                                                    type='date'
                                                                    name='expiry'
                                                                    id='expiry'
                                                                    className=' pb-1 appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            expiry === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    SPV's expiry
                                                                    date is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        {loading ? (
                                                            <div className='sm:col-span-2'>
                                                                <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                                                                    <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                                                                        <svg
                                                                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            fill='none'
                                                                            viewBox='0 0 24 24'
                                                                        >
                                                                            <circle
                                                                                className='opacity-25'
                                                                                cx='12'
                                                                                cy='12'
                                                                                r='10'
                                                                                stroke='currentColor'
                                                                                strokeWidth='4'
                                                                            ></circle>
                                                                            <path
                                                                                className='opacity-75'
                                                                                fill='currentColor'
                                                                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                                            ></path>
                                                                        </svg>
                                                                        Loading
                                                                        ...
                                                                    </div>
                                                                </div>{" "}
                                                            </div>
                                                        ) : (
                                                            <div className='sm:col-span-2'>
                                                                <label
                                                                    htmlFor='last-name'
                                                                    className='block text-sm font-medium text-gray-300'
                                                                >
                                                                    Property ID
                                                                </label>

                                                                <Combobox
                                                                    onChange={() => {}}
                                                                    value={
                                                                        proID
                                                                    }
                                                                >
                                                                    <div className='relative'>
                                                                        <Combobox.Input
                                                                            className=' mt-1 bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                setPropertiesShown(
                                                                                    true
                                                                                )
                                                                                setPropertyQuery(
                                                                                    event
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                                setInvalid(
                                                                                    false
                                                                                )
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    {filteredProperties.length >
                                                                        0 &&
                                                                        propertiesShown && (
                                                                            <Combobox.Options
                                                                                static
                                                                                className='bg-gray-700 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-200 rounded-b-xl'
                                                                            >
                                                                                {filteredProperties.map(
                                                                                    (
                                                                                        recPro
                                                                                    ) => (
                                                                                        <Combobox.Option
                                                                                            onClick={() => {
                                                                                                setSPVData(
                                                                                                    (
                                                                                                        prevState
                                                                                                    ) => ({
                                                                                                        ...prevState,
                                                                                                        proID: recPro.proID,
                                                                                                    })
                                                                                                )
                                                                                                setPropertiesShown(
                                                                                                    false
                                                                                                )
                                                                                            }}
                                                                                            key={
                                                                                                recPro.proID
                                                                                            }
                                                                                            value={
                                                                                                recPro.proID
                                                                                            }
                                                                                            className={({
                                                                                                active,
                                                                                            }) =>
                                                                                                classNames(
                                                                                                    "cursor-default select-none px-4 py-2",
                                                                                                    active
                                                                                                        ? "bg-blue-600 text-white"
                                                                                                        : ""
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            {`${recPro.proID} ${recPro.area} ${recPro.address}`}
                                                                                        </Combobox.Option>
                                                                                    )
                                                                                )}
                                                                            </Combobox.Options>
                                                                        )}

                                                                    {propertyQuery !==
                                                                        "" &&
                                                                        filteredProperties.length ===
                                                                            0 && (
                                                                            <p className='p-4 text-sm text-gray-500'>
                                                                                No
                                                                                property
                                                                                found.
                                                                            </p>
                                                                        )}
                                                                </Combobox>
                                                                {invalid &&
                                                                proID === "" ? (
                                                                    <span className='text-sm text-red-500'>
                                                                        Property
                                                                        ID
                                                                        cannot
                                                                        be empty
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {saving ? (
                                                ""
                                            ) : (
                                                <div className='flex flex-shrink-0 justify-end px-4 py-4'>
                                                    <button
                                                        type='button'
                                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-gray-200 text-sm font-medium text-gray-900 hover:bg-blue-600 hover:text-gray-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setEdit(false)
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            onSubmit()
                                                        }}
                                                        className='py-2 px-5 ml-5 relatives items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            )}
                                        </form>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default RecSPVView
