import { Fragment, useState, useEffect, ChangeEvent } from "react"
import { useSelector } from "react-redux"

import { RootState } from "../app/store"

import { supabase } from "../supabaseClient"

import { Transition, Switch } from "@headlessui/react"
import { XIcon, ExclamationCircleIcon } from "@heroicons/react/solid"

import { RefreshIcon } from "@heroicons/react/outline"

type Role = {
    id: string
    user_email: string
    ctr_v: boolean
    acc_v: boolean
    rep_v: boolean
    tra_v: boolean
    tra_i: boolean
    tra_e: boolean
    rec_v: boolean
    rec_i: boolean
    rec_e: boolean
    set_r: boolean
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const SetRolView = () => {
    const { setState } = useSelector((state: RootState) => state.set)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [errorDetails, setErrorDetails] = useState({
        code: "",
        message: "",
        details: "",
    })

    const [rols, setRols] = useState<Role[]>([])

    const getRols = async () => {
        setRols([])
        setLoading(true)
        setError(false)
        const { data, error } = await supabase
            .from<Role>("S_Roles")
            .select()
            .order("id", { ascending: true })
        if (data) {
            setRols(data)
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }

        setLoading(false)
    }

    useEffect(() => {
        if (setState.id === "CR") {
            getRols()
        }
    }, [setState])

    const onChange = async (rol: Role) => {
        setLoading(true)
        if (rol) {
            const { error } = await supabase
                .from<Role>("S_Roles")
                .update(
                    {
                        ctr_v: rol.ctr_v,
                        acc_v: rol.acc_v,
                        rep_v: rol.rep_v,
                        tra_v: rol.tra_v,
                        tra_i: rol.tra_i,
                        tra_e: rol.tra_e,
                        rec_v: rol.rec_v,
                        rec_i: rol.rec_i,
                        rec_e: rol.rec_e,
                    },
                    { returning: "minimal" }
                )
                .match({ id: rol.id })

            if (error) {
                setError(true)
                setErrorDetails({
                    code: error.code,
                    message: error.message,
                    details: error.details,
                })
            } else {
                getRols()
            }
        }
    }

    return (
        <>
            {loading ? (
                <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                    <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                        <svg
                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                        >
                            <circle
                                className='opacity-25'
                                cx='12'
                                cy='12'
                                r='10'
                                stroke='currentColor'
                                strokeWidth='4'
                            ></circle>
                            <path
                                className='opacity-75'
                                fill='currentColor'
                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                            ></path>
                        </svg>
                        Loading ...
                    </div>
                </div>
            ) : (
                <div className='mt-7'>
                    <div className='flex flex-row justify-between '>
                        <div className='pt-2 justify-self-start '>
                            <h3 className='text-lg leading-6 font-medium text-gray-200'>
                                Configure Roles
                            </h3>
                            <p className='mt-1 max-w-2xl text-sm text-gray-300'></p>
                        </div>
                        <div className='justify-self-end'>
                            <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        getRols()
                                    }}
                                    type='button'
                                    className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                >
                                    <RefreshIcon
                                        className='h-5 w-5 pr-1'
                                        aria-hidden='true'
                                    />
                                    <span>Refresh</span>
                                </button>
                            </span>
                        </div>
                    </div>

                    <div className='mx-8 mt-8 flex flex-col '>
                        <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-auto'>
                            <div className='inline-block min-w-full py-2 align-middle'>
                                <div className='shadow-sm ring-1 ring-black ring-opacity-5'>
                                    <table
                                        className='min-w-full border-separate'
                                        style={{ borderSpacing: 0 }}
                                    >
                                        <thead className='bg-gray-900'>
                                            <tr>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'
                                                >
                                                    User Email
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'
                                                >
                                                    View Control Panel
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:table-cell'
                                                >
                                                    View Accounts
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter lg:table-cell'
                                                >
                                                    View Reports
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    View Transactions
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Add Transactions
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Edit Transactions
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    View Records
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Add Records
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Edit Records
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='bg-gray-700'>
                                            {rols.map((role, roleIndx) => (
                                                <tr key={role.id}>
                                                    <td
                                                        className={classNames(
                                                            roleIndx !==
                                                                rols.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6 lg:pl-8"
                                                        )}
                                                    >
                                                        {role.user_email}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            roleIndx !==
                                                                rols.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "text-center whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                        )}
                                                    >
                                                        <Switch
                                                            checked={role.ctr_v}
                                                            onChange={() => {
                                                                onChange({
                                                                    ...role,
                                                                    ctr_v: !role.ctr_v,
                                                                })
                                                            }}
                                                            className={classNames(
                                                                role.ctr_v
                                                                    ? "bg-yellow-400"
                                                                    : "bg-gray-200",
                                                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            )}
                                                        >
                                                            <span
                                                                className={classNames(
                                                                    role.ctr_v
                                                                        ? "translate-x-5"
                                                                        : "translate-x-0",
                                                                    "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                                )}
                                                            >
                                                                <span
                                                                    className={classNames(
                                                                        role.ctr_v
                                                                            ? "opacity-0 ease-out duration-100"
                                                                            : "opacity-100 ease-in duration-200",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-gray-400'
                                                                        fill='none'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path
                                                                            d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                                                                            stroke='currentColor'
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        role.ctr_v
                                                                            ? "opacity-100 ease-in duration-200"
                                                                            : "opacity-0 ease-out duration-100",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-indigo-600'
                                                                        fill='currentColor'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </Switch>
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            roleIndx !==
                                                                rols.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "text-center whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                        )}
                                                    >
                                                        <Switch
                                                            checked={role.acc_v}
                                                            onChange={() => {
                                                                onChange({
                                                                    ...role,
                                                                    acc_v: !role.acc_v,
                                                                })
                                                            }}
                                                            className={classNames(
                                                                role.acc_v
                                                                    ? "bg-yellow-400"
                                                                    : "bg-gray-200",
                                                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            )}
                                                        >
                                                            <span
                                                                className={classNames(
                                                                    role.acc_v
                                                                        ? "translate-x-5"
                                                                        : "translate-x-0",
                                                                    "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                                )}
                                                            >
                                                                <span
                                                                    className={classNames(
                                                                        role.acc_v
                                                                            ? "opacity-0 ease-out duration-100"
                                                                            : "opacity-100 ease-in duration-200",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-gray-400'
                                                                        fill='none'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path
                                                                            d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                                                                            stroke='currentColor'
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        role.acc_v
                                                                            ? "opacity-100 ease-in duration-200"
                                                                            : "opacity-0 ease-out duration-100",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-indigo-600'
                                                                        fill='currentColor'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </Switch>
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            roleIndx !==
                                                                rols.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "text-center whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                        )}
                                                    >
                                                        <Switch
                                                            checked={role.rep_v}
                                                            onChange={() => {
                                                                onChange({
                                                                    ...role,
                                                                    rep_v: !role.rep_v,
                                                                })
                                                            }}
                                                            className={classNames(
                                                                role.rep_v
                                                                    ? "bg-yellow-400"
                                                                    : "bg-gray-200",
                                                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            )}
                                                        >
                                                            <span
                                                                className={classNames(
                                                                    role.rep_v
                                                                        ? "translate-x-5"
                                                                        : "translate-x-0",
                                                                    "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                                )}
                                                            >
                                                                <span
                                                                    className={classNames(
                                                                        role.rep_v
                                                                            ? "opacity-0 ease-out duration-100"
                                                                            : "opacity-100 ease-in duration-200",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-gray-400'
                                                                        fill='none'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path
                                                                            d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                                                                            stroke='currentColor'
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        role.rep_v
                                                                            ? "opacity-100 ease-in duration-200"
                                                                            : "opacity-0 ease-out duration-100",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-indigo-600'
                                                                        fill='currentColor'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </Switch>
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            roleIndx !==
                                                                rols.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "text-center whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                        )}
                                                    >
                                                        <Switch
                                                            checked={role.tra_v}
                                                            onChange={() => {
                                                                onChange({
                                                                    ...role,
                                                                    tra_v: !role.tra_v,
                                                                    tra_e:
                                                                        !role.tra_v ===
                                                                        false
                                                                            ? false
                                                                            : role.tra_e,
                                                                })
                                                            }}
                                                            className={classNames(
                                                                role.tra_v
                                                                    ? "bg-yellow-400"
                                                                    : "bg-gray-200",
                                                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            )}
                                                        >
                                                            <span
                                                                className={classNames(
                                                                    role.tra_v
                                                                        ? "translate-x-5"
                                                                        : "translate-x-0",
                                                                    "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                                )}
                                                            >
                                                                <span
                                                                    className={classNames(
                                                                        role.tra_v
                                                                            ? "opacity-0 ease-out duration-100"
                                                                            : "opacity-100 ease-in duration-200",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-gray-400'
                                                                        fill='none'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path
                                                                            d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                                                                            stroke='currentColor'
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        role.tra_v
                                                                            ? "opacity-100 ease-in duration-200"
                                                                            : "opacity-0 ease-out duration-100",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-indigo-600'
                                                                        fill='currentColor'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </Switch>
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            roleIndx !==
                                                                rols.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "text-center whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                        )}
                                                    >
                                                        <Switch
                                                            checked={role.tra_i}
                                                            onChange={() => {
                                                                onChange({
                                                                    ...role,
                                                                    tra_i: !role.tra_i,
                                                                })
                                                            }}
                                                            className={classNames(
                                                                role.tra_i
                                                                    ? "bg-yellow-400"
                                                                    : "bg-gray-200",
                                                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            )}
                                                        >
                                                            <span
                                                                className={classNames(
                                                                    role.tra_i
                                                                        ? "translate-x-5"
                                                                        : "translate-x-0",
                                                                    "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                                )}
                                                            >
                                                                <span
                                                                    className={classNames(
                                                                        role.tra_i
                                                                            ? "opacity-0 ease-out duration-100"
                                                                            : "opacity-100 ease-in duration-200",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-gray-400'
                                                                        fill='none'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path
                                                                            d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                                                                            stroke='currentColor'
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        role.tra_i
                                                                            ? "opacity-100 ease-in duration-200"
                                                                            : "opacity-0 ease-out duration-100",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-indigo-600'
                                                                        fill='currentColor'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </Switch>
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            roleIndx !==
                                                                rols.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "text-center whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                        )}
                                                    >
                                                        {role.tra_v ? (
                                                            <Switch
                                                                checked={
                                                                    role.tra_e
                                                                }
                                                                onChange={() => {
                                                                    onChange({
                                                                        ...role,
                                                                        tra_e: !role.tra_e,
                                                                    })
                                                                }}
                                                                className={classNames(
                                                                    role.tra_e
                                                                        ? "bg-yellow-400"
                                                                        : "bg-gray-200",
                                                                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                )}
                                                            >
                                                                <span
                                                                    className={classNames(
                                                                        role.tra_e
                                                                            ? "translate-x-5"
                                                                            : "translate-x-0",
                                                                        "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                                    )}
                                                                >
                                                                    <span
                                                                        className={classNames(
                                                                            role.tra_e
                                                                                ? "opacity-0 ease-out duration-100"
                                                                                : "opacity-100 ease-in duration-200",
                                                                            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                        )}
                                                                        aria-hidden='true'
                                                                    >
                                                                        <svg
                                                                            className='h-3 w-3 text-gray-400'
                                                                            fill='none'
                                                                            viewBox='0 0 12 12'
                                                                        >
                                                                            <path
                                                                                d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                                                                                stroke='currentColor'
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                strokeLinecap='round'
                                                                                strokeLinejoin='round'
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    <span
                                                                        className={classNames(
                                                                            role.tra_e
                                                                                ? "opacity-100 ease-in duration-200"
                                                                                : "opacity-0 ease-out duration-100",
                                                                            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                        )}
                                                                        aria-hidden='true'
                                                                    >
                                                                        <svg
                                                                            className='h-3 w-3 text-indigo-600'
                                                                            fill='currentColor'
                                                                            viewBox='0 0 12 12'
                                                                        >
                                                                            <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                            </Switch>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            roleIndx !==
                                                                rols.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "text-center whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                        )}
                                                    >
                                                        <Switch
                                                            checked={role.rec_v}
                                                            onChange={() => {
                                                                onChange({
                                                                    ...role,
                                                                    rec_v: !role.rec_v,
                                                                    rec_e:
                                                                        !role.rec_v ===
                                                                        false
                                                                            ? false
                                                                            : role.rec_e,
                                                                })
                                                            }}
                                                            className={classNames(
                                                                role.rec_v
                                                                    ? "bg-yellow-400"
                                                                    : "bg-gray-200",
                                                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            )}
                                                        >
                                                            <span
                                                                className={classNames(
                                                                    role.rec_v
                                                                        ? "translate-x-5"
                                                                        : "translate-x-0",
                                                                    "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                                )}
                                                            >
                                                                <span
                                                                    className={classNames(
                                                                        role.rec_v
                                                                            ? "opacity-0 ease-out duration-100"
                                                                            : "opacity-100 ease-in duration-200",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-gray-400'
                                                                        fill='none'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path
                                                                            d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                                                                            stroke='currentColor'
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        role.rec_v
                                                                            ? "opacity-100 ease-in duration-200"
                                                                            : "opacity-0 ease-out duration-100",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-indigo-600'
                                                                        fill='currentColor'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </Switch>
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            roleIndx !==
                                                                rols.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "text-center whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                        )}
                                                    >
                                                        <Switch
                                                            checked={role.rec_i}
                                                            onChange={() => {
                                                                onChange({
                                                                    ...role,
                                                                    rec_i: !role.rec_i,
                                                                })
                                                            }}
                                                            className={classNames(
                                                                role.rec_i
                                                                    ? "bg-yellow-400"
                                                                    : "bg-gray-200",
                                                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            )}
                                                        >
                                                            <span
                                                                className={classNames(
                                                                    role.rec_i
                                                                        ? "translate-x-5"
                                                                        : "translate-x-0",
                                                                    "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                                )}
                                                            >
                                                                <span
                                                                    className={classNames(
                                                                        role.rec_i
                                                                            ? "opacity-0 ease-out duration-100"
                                                                            : "opacity-100 ease-in duration-200",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-gray-400'
                                                                        fill='none'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path
                                                                            d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                                                                            stroke='currentColor'
                                                                            strokeWidth={
                                                                                2
                                                                            }
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span
                                                                    className={classNames(
                                                                        role.rec_i
                                                                            ? "opacity-100 ease-in duration-200"
                                                                            : "opacity-0 ease-out duration-100",
                                                                        "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                    )}
                                                                    aria-hidden='true'
                                                                >
                                                                    <svg
                                                                        className='h-3 w-3 text-indigo-600'
                                                                        fill='currentColor'
                                                                        viewBox='0 0 12 12'
                                                                    >
                                                                        <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                        </Switch>
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            roleIndx !==
                                                                rols.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "text-center whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                        )}
                                                    >
                                                        {role.rec_v ? (
                                                            <Switch
                                                                checked={
                                                                    role.rec_e
                                                                }
                                                                onChange={() => {
                                                                    onChange({
                                                                        ...role,
                                                                        rec_e: !role.rec_e,
                                                                    })
                                                                }}
                                                                className={classNames(
                                                                    role.rec_e
                                                                        ? "bg-yellow-400"
                                                                        : "bg-gray-200",
                                                                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                )}
                                                            >
                                                                <span
                                                                    className={classNames(
                                                                        role.rec_e
                                                                            ? "translate-x-5"
                                                                            : "translate-x-0",
                                                                        "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                                    )}
                                                                >
                                                                    <span
                                                                        className={classNames(
                                                                            role.rec_e
                                                                                ? "opacity-0 ease-out duration-100"
                                                                                : "opacity-100 ease-in duration-200",
                                                                            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                        )}
                                                                        aria-hidden='true'
                                                                    >
                                                                        <svg
                                                                            className='h-3 w-3 text-gray-400'
                                                                            fill='none'
                                                                            viewBox='0 0 12 12'
                                                                        >
                                                                            <path
                                                                                d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                                                                                stroke='currentColor'
                                                                                strokeWidth={
                                                                                    2
                                                                                }
                                                                                strokeLinecap='round'
                                                                                strokeLinejoin='round'
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    <span
                                                                        className={classNames(
                                                                            role.rec_e
                                                                                ? "opacity-100 ease-in duration-200"
                                                                                : "opacity-0 ease-out duration-100",
                                                                            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                        )}
                                                                        aria-hidden='true'
                                                                    >
                                                                        <svg
                                                                            className='h-3 w-3 text-indigo-600'
                                                                            fill='currentColor'
                                                                            viewBox='0 0 12 12'
                                                                        >
                                                                            <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                                                                        </svg>
                                                                    </span>
                                                                </span>
                                                            </Switch>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className='z-20 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={error}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                {`Error ${errorDetails.code}: ${errorDetails.details}`}
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                {errorDetails.message}
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        </>
    )
}

export default SetRolView
