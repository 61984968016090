import { useState } from "react"

import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../app/store"

import {
    BookOpenIcon,
    DocumentTextIcon,
    DocumentDownloadIcon,
} from "@heroicons/react/outline"

import { ChevronLeftIcon } from "@heroicons/react/solid"
import { setAccFormState, setAccState } from "../features/auth/accSlice"

import { utils, writeFile } from "xlsx"
import { supabase } from "../supabaseClient"

import AccClass from "./AccClass"
import AccView from "./AccView"
import AccSearch from "./AccSearch"

const recDashboard = [
    {
        id: "A",
        title: "Assets",
        description: "",
        icon: BookOpenIcon,
        background: "bg-blue-500",
    },
    {
        id: "E",
        title: "Equity",
        description: "",
        icon: BookOpenIcon,
        background: "bg-green-500",
    },
    {
        id: "L",
        title: "Liabilities",
        description: "",
        icon: BookOpenIcon,
        background: "bg-yellow-500",
    },
    {
        id: "S",
        title: "SPV",
        description: "",
        icon: DocumentTextIcon,
        background: "bg-purple-500",
    },
]

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

type Acc = {
    id: string
    not: string
    date: string
    proID: string
    invID: string
    split: string
    debit: number
    credit: number
    traType: string
    traRef: string
    notes: string
    traID: string
    created_at: string
}

const AccMain = () => {
    const dispatch = useDispatch()
    const { accFormState, accState } = useSelector(
        (state: RootState) => state.acc
    )

    const [loading, setLoading] = useState(false)

    const accNames = [
        { accID: "A_A_CMA", accName: "Client Money Account" },
        { accID: "A_A_RA", accName: "Rental Account" },
        { accID: "A_A_SDA", accName: "Security Deposit Account" },
        { accID: "A_A_CMWA", accName: "Client Money Wallet Account" },
        { accID: "A_A_CA", accName: "Corporate Account" },
        { accID: "A_A_P", accName: "Properties" },
        { accID: "A_E_SC", accName: "Share Capital" },
        // { accID: "A_E_RE", accName: "Retained Earnings" },
        { accID: "A_E_R", accName: "Reserve" },
        { accID: "A_L_IWL", accName: "Investor Wallet (Locked)" },
        { accID: "A_L_IWO", accName: "Investor Wallet (Open)" },
        { accID: "A_L_BI", accName: "Baytuki Income" },
        { accID: "A_L_OL", accName: "Other Liability" },
        { accID: "A_SPV_Pro", accName: "SPV Properties" },
        {
            accID: "A_SPV_RBTI",
            accName: "SPV Receivable from BT - Intercompany",
        },
        { accID: "A_SPV_PMGF", accName: "SPV Property Management Fees" },
        { accID: "A_SPV_PMTF", accName: "SPV Property Maintenance Fees" },
        { accID: "A_SPV_SPEF", accName: "SPV Sale of Property - Exit Fees" },
        { accID: "A_SPV_AAF", accName: "SPV Annual Administration Fee" },
        { accID: "A_SPV_SFP", accName: "SPV Service Fee for Platform" },
        { accID: "A_SPV_RI", accName: "SPV Rental Income" },
        { accID: "A_SPV_SPI", accName: "SPV Sale of Property - Income" },
        { accID: "A_SPV_UD", accName: "SPV Utilities Deposit" },
        {
            accID: "A_SPV_RSD",
            accName: "SPV Refundable Security Deposit to Clients",
        },
        { accID: "A_SPV_SC", accName: "SPV Share Capital" },
        { accID: "A_SPV_Div", accName: "SPV Dividend" },
    ]

    const getData = async (accID: string) => {
        const { data } = await supabase
            .from<Acc>(accID)
            .select()
            .order("created_at", { ascending: false })
        if (data) {
            return data
        }
    }

    const xlsxExprt = async () => {
        setLoading(true)
        const today = new Date()
        const workbook = utils.book_new()

        for (const acc of accNames) {
            const data = await getData(acc.accID)

            if (data) {
                const newAcc = data.map((acc) => {
                    return {
                        ...acc,
                        credit: acc.credit / 100,
                        debit: acc.debit / 100,
                    }
                })
                const worksheet = utils.json_to_sheet(newAcc)
                utils.book_append_sheet(
                    workbook,
                    worksheet,
                    acc.accName.substring(0, 30)
                )
            }
        }
        setLoading(false)

        writeFile(
            workbook,
            `all_accounts_${
                today.getDate().toString().length === 1
                    ? "0" + today.getDate().toString()
                    : today.getDate().toString()
            }-${
                today.getMonth().toString().length === 1
                    ? "0" + (today.getMonth() + 1).toString()
                    : (today.getMonth() + 1).toString()
            }-${today.getFullYear()}.xlsx`
        )
    }

    return (
        <div className='flex flex-col content-evenly bg-gray-800 h-full'>
            {loading ? (
                <div className='m-auto'>
                    <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                        <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                            <svg
                                className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                            >
                                <circle
                                    className='opacity-25'
                                    cx='12'
                                    cy='12'
                                    r='10'
                                    stroke='currentColor'
                                    strokeWidth='4'
                                ></circle>
                                <path
                                    className='opacity-75'
                                    fill='currentColor'
                                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                ></path>
                            </svg>
                            Loading ...
                        </div>
                    </div>{" "}
                </div>
            ) : (
                <>
                    <div className='content-start mx-10 mt-5'>
                        {(accState.id === "E" ||
                            accState.id === "A" ||
                            accState.id === "L" ||
                            accState.id === "S") &&
                        accFormState === 1 ? (
                            <AccSearch />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className=' content-start mx-10 mt-10 '>
                        {accFormState > 0 && accFormState < 3 ? (
                            <div className='mb-5'>
                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()

                                            dispatch(
                                                setAccFormState(
                                                    accFormState - 1
                                                )
                                            )
                                        }}
                                        type='button'
                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <ChevronLeftIcon
                                            className='h-5 w-5'
                                            aria-hidden='true'
                                        />
                                        <span>Previous</span>
                                    </button>
                                </span>
                            </div>
                        ) : accState.id !== "X" && accFormState < 3 ? (
                            <div className='mb-5'>
                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()

                                            dispatch(
                                                setAccState({
                                                    id: "X",
                                                    name: "Dashboard",
                                                })
                                            )
                                        }}
                                        type='button'
                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <ChevronLeftIcon
                                            className='h-5 w-5'
                                            aria-hidden='true'
                                        />
                                        <span>Previous</span>
                                    </button>
                                </span>
                            </div>
                        ) : accFormState === 0 ? (
                            <div className='absolute mb-5 mr-10 right-0'>
                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            xlsxExprt()
                                        }}
                                        type='button'
                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <DocumentDownloadIcon
                                            className='h-5 w-5 pr-1'
                                            aria-hidden='true'
                                        />
                                        <span>
                                            Export all accounts (Excel File)
                                        </span>
                                    </button>
                                </span>
                            </div>
                        ) : (
                            ""
                        )}
                        {accState.id === "X" ? (
                            <div className=''>
                                <h2 className='text-lg font-medium text-gray-200'>
                                    Accounts
                                </h2>
                                <p className='mt-1 text-sm text-gray-300'></p>
                                <ul className='mt-6 border-t border-b border-yellow-400 py-6 grid grid-cols-1 gap-6 sm:grid-cols-4'>
                                    {recDashboard.map((item, itemIdx) => (
                                        <li key={itemIdx} className='flow-root'>
                                            <div className='relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-slate-600 focus-within:ring-2 focus-within:ring-indigo-500'>
                                                <div
                                                    className={classNames(
                                                        item.background,
                                                        "flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg"
                                                    )}
                                                >
                                                    <item.icon
                                                        className='h-6 w-6 text-white'
                                                        aria-hidden='true'
                                                    />
                                                </div>
                                                <div>
                                                    <h3 className='text-sm font-medium text-gray-200 hover:text-gray-100'>
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault()

                                                                dispatch(
                                                                    setAccState(
                                                                        {
                                                                            id: item.id,
                                                                            name: item.title,
                                                                        }
                                                                    )
                                                                )
                                                            }}
                                                            className='focus:outline-none'
                                                        >
                                                            <span
                                                                className='absolute inset-0'
                                                                aria-hidden='true'
                                                            />
                                                            {item.title}
                                                            <span aria-hidden='true'>
                                                                {" "}
                                                                &rarr;
                                                            </span>
                                                        </button>
                                                    </h3>
                                                    <p className='mt-1 text-sm text-gray-400'>
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (accState.id === "E" ||
                              accState.id === "A" ||
                              accState.id === "L" ||
                              accState.id === "S") &&
                          accFormState === 0 ? (
                            <AccClass />
                        ) : (accState.id === "E" ||
                              accState.id === "A" ||
                              accState.id === "L" ||
                              accState.id === "S") &&
                          accFormState === 1 ? (
                            <AccView />
                        ) : (
                            ""
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default AccMain
