import { createSlice } from "@reduxjs/toolkit"

interface state {
    accState: { id: string; name: string }
    accFormState: number
    accClass: { id: string; name: string }
    accType: { id: string; name: string }
    recAcc: {
        invID: string
        title: string
        fore: string
        sur: string
        email: string
        mobile: string
        dob: string
        occupation: string
        bracket: string
        risk: string
    }
    searchTerm: string
}

const initialState: state = {
    accState: { id: "X", name: "Dashboard" },
    accFormState: 0,
    accClass: { id: "", name: "" },
    accType: { id: "", name: "" },
    recAcc: {
        invID: "",
        title: "",
        fore: "",
        sur: "",
        email: "",
        mobile: "",
        dob: "",
        occupation: "",
        bracket: "",
        risk: "",
    },
    searchTerm: "",
}

export const accSlice = createSlice({
    name: "acc",
    initialState,
    reducers: {
        reset: (state) => {
            state.accFormState = 0
            state.accState = { id: "X", name: "Dashboard" }
            state.accClass = { id: "", name: "" }
            state.accType = { id: "", name: "" }
            state.recAcc = {
                invID: "",
                title: "",
                fore: "",
                sur: "",
                email: "",
                mobile: "",
                dob: "",
                occupation: "",
                bracket: "",
                risk: "",
            }
            state.searchTerm = ""
        },
        setAccState: (state, action) => {
            state.accState = action.payload
        },
        setAccFormState: (state, action) => {
            state.accFormState = action.payload
        },
        setAccClass: (state, action) => {
            state.accClass = action.payload
        },
        setAccType: (state, action) => {
            state.accType = action.payload
        },
        setRecAcc: (state, action) => {
            state.recAcc = action.payload
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload
        },
    },
    extraReducers: (builder) => {},
})

export const {
    reset,
    setAccState,
    setAccFormState,
    setAccClass,
    setAccType,
    setRecAcc,
    setSearchTerm,
} = accSlice.actions
export default accSlice.reducer
