import { useSelector, useDispatch } from "react-redux"

import { setAccClass, setAccFormState } from "../features/auth/accSlice"
import { RootState } from "../app/store"

import { DocumentIcon } from "@heroicons/react/outline"

const accClasses = [
    {
        type: "A",
        id: "A_A_CMA",
        title: "Client Money Account",
        description: "",
        icon: DocumentIcon,
        background: "bg-blue-500",
    },
    {
        type: "A",
        id: "A_A_RA",
        title: "Rental Account",
        description: "",
        icon: DocumentIcon,
        background: "bg-blue-500",
    },
    {
        type: "A",
        id: "A_A_SDA",
        title: "Security Deposit Account",
        description: "",
        icon: DocumentIcon,
        background: "bg-blue-500",
    },
    {
        type: "A",
        id: "A_A_CMWA",
        title: "Client Money Wallet Account",
        description: "",
        icon: DocumentIcon,
        background: "bg-blue-500",
    },
    {
        type: "A",
        id: "A_A_CA",
        title: "Corporate Account",
        description: "",
        icon: DocumentIcon,
        background: "bg-blue-500",
    },
    {
        type: "A",
        id: "A_A_P",
        title: "Properties",
        description: "",
        icon: DocumentIcon,
        background: "bg-blue-500",
    },
    {
        type: "E",
        id: "A_E_SC",
        title: "Share Capital",
        description: "",
        icon: DocumentIcon,
        background: "bg-green-500",
    },
    // {
    //     type: "E",
    //     id: "A_E_RE",
    //     title: "Retained Earnings",
    //     description: "",
    //     icon: DocumentIcon,
    //     background: "bg-green-500",
    // },
    {
        type: "E",
        id: "A_E_R",
        title: "Reserve",
        description: "",
        icon: DocumentIcon,
        background: "bg-green-500",
    },
    {
        type: "L",
        id: "A_L_IWL",
        title: "Investor Wallet (Locked)",
        description: "",
        icon: DocumentIcon,
        background: "bg-yellow-500",
    },
    {
        type: "L",
        id: "A_L_IWO",
        title: "Investor Wallet (Open)",
        description: "",
        icon: DocumentIcon,
        background: "bg-yellow-500",
    },
    {
        type: "L",
        id: "A_L_BI",
        title: "Baytuki Income",
        description: "",
        icon: DocumentIcon,
        background: "bg-yellow-500",
    },
    {
        type: "L",
        id: "A_L_OL",
        title: "Other Liability",
        description: "",
        icon: DocumentIcon,
        background: "bg-yellow-500",
    },
    {
        type: "S",
        id: "A_SPV_Pro",
        title: "SPV Properties",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
    {
        type: "S",
        id: "A_SPV_RBTI",
        title: "SPV Receivable from BT - Intercompany",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
    {
        type: "S",
        id: "A_SPV_PMGF",
        title: "SPV Property Management Fees",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
    {
        type: "S",
        id: "A_SPV_PMTF",
        title: "SPV Property Maintenance Fees",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
    {
        type: "S",
        id: "A_SPV_SPEF",
        title: "SPV Sale of Property - Exit Fees",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
    {
        type: "S",
        id: "A_SPV_AAF",
        title: "SPV Annual Administration Fee",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
    {
        type: "S",
        id: "A_SPV_SFP",
        title: "SPV Service Fee for Platform",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
    {
        type: "S",
        id: "A_SPV_RI",
        title: "SPV Rental Income",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
    {
        type: "S",
        id: "A_SPV_SPI",
        title: "SPV Sale of Property Income",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
    {
        type: "S",
        id: "A_SPV_UD",
        title: "SPV Utilities Deposit",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
    {
        type: "S",
        id: "A_SPV_RSD",
        title: "SPV Refundable Security Deposit to Clients",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
    {
        type: "S",
        id: "A_SPV_SC",
        title: "SPV Share Capital",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
    {
        type: "S",
        id: "A_SPV_Div",
        title: "SPV Dividend",
        description: "",
        icon: DocumentIcon,
        background: "bg-purple-500",
    },
]

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const RecClass = () => {
    const dispatch = useDispatch()
    const { accFormState, accState } = useSelector(
        (state: RootState) => state.acc
    )

    return (
        <div className=''>
            <h2 className='text-lg font-medium text-gray-200'>
                {accState.name}
            </h2>
            <p className='mt-1 text-sm text-gray-300'>
                Select the appropriate account.
            </p>
            <ul className='mt-6 border-t border-b border-yellow-400 py-6 grid grid-cols-1 gap-6 sm:grid-cols-3'>
                {accClasses
                    .filter((acc) => {
                        return acc.type === accState.id
                    })
                    .map((item, itemIdx) => (
                        <li key={itemIdx} className='flow-root'>
                            <div className='relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-slate-600 focus-within:ring-2 focus-within:ring-indigo-500'>
                                <div
                                    className={classNames(
                                        item.background,
                                        "flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg"
                                    )}
                                >
                                    <item.icon
                                        className='h-6 w-6 text-white'
                                        aria-hidden='true'
                                    />
                                </div>
                                <div>
                                    <h3 className='text-sm font-medium text-gray-200 hover:text-gray-100'>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault()
                                                dispatch(
                                                    setAccClass({
                                                        id: item.id,
                                                        name: item.title,
                                                    })
                                                )
                                                dispatch(
                                                    setAccFormState(
                                                        accFormState + 1
                                                    )
                                                )
                                            }}
                                            className='focus:outline-none'
                                        >
                                            <span
                                                className='absolute inset-0'
                                                aria-hidden='true'
                                            />
                                            {item.title}
                                        </button>
                                    </h3>
                                    <p className='mt-1 text-sm text-gray-400'>
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    )
}

export default RecClass
