import { useState, useEffect } from "react"
import {
    ClipboardCheckIcon,
    CreditCardIcon,
    ChartBarIcon,
    BookOpenIcon,
    CollectionIcon,
    UserCircleIcon,
    AdjustmentsIcon,
    LockClosedIcon,
} from "@heroicons/react/outline"

import { setNav } from "../features/auth/appSlice"

import { useDispatch, useSelector } from "react-redux"
import { RootState, AppDispatch } from "../app/store"

import { logout } from "../features/auth/authSlice"
import "./SideNav.css"

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

type NavItem = {
    name: string
    icon: React.FC<React.SVGProps<SVGSVGElement>>
    current: boolean
}

const SideNav = () => {
    const dispatch = useDispatch<AppDispatch>()
    const { role, session } = useSelector((state: RootState) => state.auth)
    const { nav } = useSelector((state: RootState) => state.app)

    const env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : ""

    // const [navigation, setNavigation] = useState([
    //     { name: "Dashboard", icon: HomeIcon, current: false },
    // {
    //     name: "Transactions",
    //     icon: CreditCardIcon,

    //     current: true,
    // },
    //     {
    //         name: "Accounts",
    //         icon: BookOpenIcon,

    //         current: false,
    //     },
    // { name: "Records", icon: CollectionIcon, current: false },
    // { name: "Reports", icon: ChartBarIcon, current: false },
    // ])

    const [navigation, setNavigation] = useState<NavItem[]>([])

    useEffect(() => {
        setNavigation([])
        if (role.ctr_v) {
            setNavigation((prev) => [
                ...prev,
                {
                    name: "Control Panel",
                    icon: ClipboardCheckIcon,
                    current: false,
                },
            ])
        }
        if (role.tra_e || role.tra_v || role.tra_i) {
            setNavigation((prev) => [
                ...prev,
                {
                    name: "Transactions",
                    icon: CreditCardIcon,

                    current: false,
                },
            ])
        }
        if (role.acc_v) {
            setNavigation((prev) => [
                ...prev,
                {
                    name: "Accounts",
                    icon: BookOpenIcon,

                    current: false,
                },
            ])
        }
        if (role.rec_e || role.rec_v || role.rec_i) {
            setNavigation((prev) => [
                ...prev,
                { name: "Records", icon: CollectionIcon, current: false },
            ])
        }
        if (role.rep_v) {
            setNavigation((prev) => [
                ...prev,
                { name: "Reports", icon: ChartBarIcon, current: false },
            ])
        }

        if (role.set_r) {
            setNavigation((prev) => [
                ...prev,
                { name: "Settings", icon: AdjustmentsIcon, current: false },
            ])
        }

        setNavigation((prev) =>
            prev.map((item) =>
                item.name === nav
                    ? { ...item, current: true }
                    : { ...item, current: false }
            )
        )
    }, [role])

    const changeNavigation = (name: string) => {
        const newNavigation = navigation.map((nav) => {
            const newNav = nav
            if (nav.name === name) {
                newNav.current = true
                return nav
            } else {
                newNav.current = false
                return nav
            }
        })
        setNavigation(newNavigation)
        dispatch(setNav(name))
    }

    return (
        <div className='flex-1 flex flex-col h-full border-r border-yellow-400 bg-gray-90'>
            <div className='flex-1 flex flex-col pt-5 pb-4 overflow-y-auto'>
                <div className='flex items-center flex-shrink-0 px-4'>
                    <img
                        className=' h-14 w-auto mr-3'
                        src='logo.png'
                        alt='Workflow'
                    />
                    {env === "dev" && (
                        <div className='relative'>
                            <div className='animate-pulse absolute -inset-1 bg-cyan-600  blur-xl'></div>

                            <div className='  relative py-3 px-3 bg-gray-900 text-yellow-400 text-center  leading-tight'>
                                Release Candidate
                            </div>

                            <svg className=' absolute w-full h-full inset-0 '>
                                <rect
                                    pathLength={100}
                                    style={{
                                        strokeDasharray: "20px 30px",
                                        strokeDashoffset: "-25px",
                                        strokeLinecap: "round",
                                    }}
                                    className='animate-pulse w-full h-full fill-transparent stroke-5 stroke-yellow-500'
                                >
                                    <animate
                                        id='p1'
                                        attributeName='stroke-dashoffset'
                                        // begin='0.1s;f1.end+0.4s'
                                        values='-25;0;25'
                                        dur='3s'
                                        repeatCount='indefinite'
                                    />
                                </rect>
                            </svg>
                        </div>
                    )}
                </div>

                <nav
                    className='mt-5 flex-1 px-2 bg-gray-900 space-y-1'
                    aria-label='Sidebar'
                >
                    {navigation.map((item) => (
                        <a
                            key={item.name}
                            onClick={(e) => {
                                e.preventDefault()
                                changeNavigation(item.name)
                            }}
                            className={classNames(
                                item.current
                                    ? " bg-gray-600 text-gray-200 hover:text-yellow-400 hover:bg-gray-600"
                                    : "text-gray-300 hover:text-gray-900 hover:bg-gray-50",
                                "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            )}
                        >
                            <item.icon
                                className={classNames(
                                    item.current
                                        ? "text-gray-200 group-hover:text-yellow-400"
                                        : "text-yellow-500 group-hover:text-gray-500",
                                    "mr-3 flex-shrink-0 h-6 w-6"
                                )}
                                aria-hidden='true'
                            />
                            <span className='flex-1'>{item.name}</span>
                        </a>
                    ))}
                </nav>
            </div>
            <div className=' flex border-t border-yellow-400 p-4 overflow-y-auto'>
                <div className='w-full group block'>
                    <div className='flex flex-row'>
                        <div className='flex items-center'>
                            <div className=''>
                                <UserCircleIcon className='inline-block h-10 w-auto rounded-full text-gray-400' />
                            </div>
                            <div className='ml-3'>
                                <p className='text-sm font-medium text-gray-300'>
                                    {session?.user?.user_metadata.name}
                                </p>
                            </div>
                            <div className='mt-1 ml-5'>
                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            dispatch(logout())
                                        }}
                                        type='button'
                                        className='py-2 px-3 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <span className=''>
                                            <LockClosedIcon
                                                className=' mr-2 h-5 w-5 '
                                                aria-hidden='true'
                                            />
                                        </span>
                                        <span>Sign out</span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SideNav
