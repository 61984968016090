import { SearchIcon } from "@heroicons/react/solid"
import { ChangeEvent } from "react"

import { setSearchTermSPVS } from "../features/auth/recSlice"
import { useDispatch } from "react-redux"

const RecSPVSSearch = () => {
    const dispatch = useDispatch()
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchTermSPVS(e.target.value))
    }
    return (
        <div className='w-1/3 mx-auto'>
            <div className='mt-1 relative rounded-md shadow-sm'>
                <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                    <SearchIcon
                        className='h-5 w-5 text-yellow-400'
                        aria-hidden='true'
                    />
                </div>
                <input
                    type='email'
                    name='email'
                    id='email'
                    className='focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-yellow-400 bg-gray-700 rounded-md text-gray-100'
                    placeholder='Search ...'
                    onChange={(e) => {
                        onChange(e)
                    }}
                />
            </div>
        </div>
    )
}

export default RecSPVSSearch
