import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import Login from "./pages/Login"

import { useState, useEffect } from "react"
import { supabase } from "./supabaseClient"

import { Session } from "@supabase/gotrue-js/src/lib/types"

import { useSelector, useDispatch } from "react-redux"

import { setSession, updateSession, setRole } from "./features/auth/authSlice"
import { checkMtn, setMtn } from "./features/auth/appSlice"

import { RootState, AppDispatch } from "./app/store"
import Main2 from "./pages/Main2"
import Mtn from "./pages/Mtn"
import MtnAlert from "./components/MtnAlert"

type Role = {
    id: string
    user_email: string
    ctr_v: boolean
    acc_v: boolean
    rep_v: boolean
    tra_v: boolean
    tra_i: boolean
    tra_e: boolean
    rec_v: boolean
    rec_i: boolean
    rec_e: boolean
    set_r: boolean
}

const App = () => {
    const dispatch = useDispatch<AppDispatch>()

    const { session, isLoading } = useSelector((state: RootState) => state.auth)
    const { mtn, loading } = useSelector((state: RootState) => state.app)

    const getRole = async (session: Session) => {
        const { data, error } = await supabase.from<Role>("S_Roles").select()
        if (data && session) {
            const role = data.filter((recRole) => {
                return recRole.user_email === session.user?.email
            })[0]
            dispatch(setRole(role))
        }
    }

    useEffect(() => {
        dispatch(checkMtn()).finally(() => {
            if (!mtn.maintenance) {
                dispatch(setSession())

                supabase.auth.onAuthStateChange((_event, session) => {
                    dispatch(updateSession(session))
                })
            }
        })
    }, [])

    useEffect(() => {
        const mtnSub = supabase
            .from("App_Mtn:id=eq.1")
            .on("UPDATE", (data) => {
                dispatch(setMtn(data.new))
            })
            .subscribe()
    }, [])

    useEffect(() => {
        if (session) {
            getRole(session)
        }
    }, [session])

    const width = window.innerWidth

    if (width >= 768) {
        return (
            <>
                {/* <Router>
                    <Routes>
                        <Route path='/login' element={<Login />}></Route>
                    </Routes>
                </Router> */}
                {mtn.message ? <MtnAlert /> : ""}

                {isLoading || loading ? (
                    <div className='m-auto h-screen flex items-center justify-center animate-pulse'>
                        <img
                            className='h-32 w-auto animate-bounce'
                            src='logo_l.png'
                            alt='Workflow'
                        />
                    </div>
                ) : mtn.maintenance ? (
                    <Mtn />
                ) : !session ? (
                    <Login />
                ) : (
                    <Main2 />
                )}
                {/* <Main2 /> */}
            </>
        )
    } else {
        return (
            <>
                <div className='grid place-items-center h-screen'>
                    <p className='text-yellow-500'>
                        Baytuki Integrated is optimized for computer displays
                    </p>
                </div>
            </>
        )
    }
}

export default App
