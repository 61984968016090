import { createSlice } from "@reduxjs/toolkit"

interface state {
    tranState: string
    tranFormState: number
    tranSteps: { id: number; name: string; status: string }[]
    tranClass: { id: string; name: string }
    tranType: { id: string; name: string }
    searchTerm: string
}

const initialState: state = {
    tranState: "X",
    tranFormState: 0,
    tranSteps: [
        { id: 0, name: "Transaction Class", status: "current" },
        { id: 1, name: "Transaction Type", status: "upcoming" },
        { id: 2, name: "Transaction Details", status: "upcoming" },
        { id: 3, name: "Transaction Preview", status: "upcoming" },
        {
            id: 4,
            name: "Transaction Submission",
            status: "upcoming",
        },
    ],
    tranClass: { id: "", name: "" },
    tranType: { id: "", name: "" },
    searchTerm: "",
}

export const tranSlice = createSlice({
    name: "tran",
    initialState,
    reducers: {
        reset: (state) => {},
        setTranState: (state, action) => {
            state.tranState = action.payload
        },
        setTranFormState: (state, action) => {
            state.tranFormState = action.payload
        },
        setTranSteps: (state, action) => {
            state.tranSteps = action.payload
        },
        resetTranSteps: (state) => {
            state.tranSteps = [
                { id: 0, name: "Transaction Class", status: "current" },
                { id: 1, name: "Transaction Type", status: "upcoming" },
                { id: 2, name: "Transaction Details", status: "upcoming" },
                { id: 3, name: "Transaction Preview", status: "upcoming" },
                {
                    id: 4,
                    name: "Transaction Submission",
                    status: "upcoming",
                },
            ]
        },
        setTranClass: (state, action) => {
            state.tranClass = action.payload
        },
        setTranType: (state, action) => {
            state.tranType = action.payload
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload
        },
    },
    extraReducers: (builder) => {},
})

export const {
    setTranState,
    setTranFormState,
    setTranSteps,
    resetTranSteps,
    setTranClass,
    setTranType,
    setSearchTerm,
} = tranSlice.actions
export default tranSlice.reducer
