import { KeyIcon } from "@heroicons/react/solid"

import { Fragment, useState, SyntheticEvent } from "react"

import { useSelector, useDispatch } from "react-redux"

import { login } from "../features/auth/authSlice"

import { RootState, AppDispatch } from "../app/store"

import { Transition } from "@headlessui/react"
import { ExclamationCircleIcon } from "@heroicons/react/solid"

const Login = () => {
    const [email, setEmail] = useState("")
    const env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : ""

    const dispatch = useDispatch<AppDispatch>()

    const { isLoading, isError, isSuccess } = useSelector(
        (state: RootState) => state.auth
    )

    const handleLogin = async (e: SyntheticEvent) => {
        e.preventDefault()
        dispatch(login(email))
    }

    return (
        <>
            <div className='mx-auto h-screen'>
                <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
                    <div className='max-w-md w-full space-y-8 text-center'>
                        {env === "dev" && (
                            <div className='relative'>
                                <div className='animate-pulse absolute -inset-3 bg-cyan-600  blur-xl'></div>

                                <div className='  relative py-3 px-3 bg-gray-900 text-yellow-400 text-center  leading-tight'>
                                    Release Candidate
                                </div>

                                <svg className=' absolute w-full h-full inset-0 '>
                                    <rect
                                        pathLength={100}
                                        style={{
                                            strokeDasharray: "20px 30px",
                                            strokeDashoffset: "-25px",
                                            strokeLinecap: "round",
                                        }}
                                        className='animate-pulse w-full h-full fill-transparent stroke-5 stroke-yellow-500'
                                    >
                                        <animate
                                            id='p1'
                                            attributeName='stroke-dashoffset'
                                            // begin='0.1s;f1.end+0.4s'
                                            values='-25;0;25'
                                            dur='3s'
                                            repeatCount='indefinite'
                                        />
                                    </rect>
                                </svg>
                            </div>
                        )}
                        <div>
                            <img
                                className='mx-auto h-40 w-auto mt-10'
                                src='logo.png'
                                alt='Workflow'
                            />
                            <h2 className='mt-4 -mb-4 text-center text-2xl font-medium text-gray-200'>
                                Sign in
                            </h2>
                        </div>
                        {isLoading ? (
                            <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                                <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                                    <svg
                                        className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                    >
                                        <circle
                                            className='opacity-25'
                                            cx='12'
                                            cy='12'
                                            r='10'
                                            stroke='currentColor'
                                            strokeWidth='4'
                                        ></circle>
                                        <path
                                            className='opacity-75'
                                            fill='currentColor'
                                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                        ></path>
                                    </svg>
                                    Loading ...
                                </div>
                            </div>
                        ) : isSuccess ? (
                            <div>
                                <p className='text-yellow-400'>
                                    Sign in link has been sent to your
                                    registered email address
                                </p>
                            </div>
                        ) : (
                            <form className='space-y-6' onSubmit={handleLogin}>
                                <input
                                    type='hidden'
                                    name='remember'
                                    defaultValue='true'
                                />
                                <div className='rounded-md shadow-sm -space-y-px'>
                                    <div>
                                        <label
                                            htmlFor='email-address'
                                            className='sr-only'
                                        >
                                            Email address
                                        </label>
                                        <input
                                            id='email-address'
                                            name='email'
                                            type='email'
                                            autoComplete='email'
                                            required
                                            className='appearance-none  relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                                            placeholder='Email address'
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>

                                <div className='flex items-center justify-between'>
                                    {/* <div className='flex items-center'>
                                    <input
                                        id='remember-me'
                                        name='remember-me'
                                        type='checkbox'
                                        className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                                    />
                                    <label
                                        htmlFor='remember-me'
                                        className='ml-2 block text-sm text-gray-900'
                                    >
                                        Remember me
                                    </label>
                                </div> */}

                                    {/* <div className='text-sm'>
                                    <a
                                        href='#'
                                        className='font-medium text-blue-500 hover:text-gray-300'
                                    >
                                        Forgot your password?
                                    </a>
                                </div> */}
                                </div>

                                <div>
                                    <button
                                        type='submit'
                                        className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-100 bg-blue-600 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                    >
                                        <span className='absolute left-0 inset-y-0 flex items-center pl-3'>
                                            <KeyIcon
                                                className='h-5 w-5 '
                                                aria-hidden='true'
                                            />
                                        </span>
                                        Sign in
                                    </button>
                                </div>
                            </form>
                        )}
                        <p className='text-gray-300 text-xs text-center'>
                            2023.03
                        </p>
                    </div>
                </div>
            </div>
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className=' z-20 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={isError}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                Login Failed
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                Incorrect credentials
                                            </p>
                                        </div>
                                        {/* <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        </>
    )
}

export default Login
