import { createSlice } from "@reduxjs/toolkit"

interface state {
    recState: { id: string; name: string }
    recFormState: number
    recSteps: { id: number; name: string; status: string }[]
    recClass: { id: string; name: string }
    recType: { id: string; name: string }
    recInv: {
        invID: string
        title: string
        fore: string
        sur: string
        email: string
        mobile: string
        dob: string
        occupation: string
        bracket: string
        risk: string
    }
    recPro: {
        proID: string
        shares: number
        address: string
        area: string
        size: string
        bed: string
        bath: string
        utility: string
        amenities: string
        tenanted: false
        rent: number
        deposit: number
        other: number
        price: number
        purCost: number
        traCost: number
        status: string
    }
    recSPV: {
        spvID: string
        name: string
        proID: string
        license: string
        signatory: string
        issue: string
        expiry: string
    }
    SPVSs: {
        spvID: string
        invID: string
        shares: number
    }[]
    recSPVS: {
        spvID: string
        invID: string
        shares: number
        stake: number
    }[]
    sharePros: { proID: string; shares: number }[]
    shareSPVs: { spvID: string; proID: string }[]
    searchTermInv: string
    searchTermPro: string
    searchTermSPV: string
    searchTermSPVS: string
    success: boolean
    successCountdown: number
    successType: string
    csvComplete: boolean
}

const initialState: state = {
    recState: { id: "X", name: "Dashboard" },
    recFormState: 0,
    recSteps: [
        { id: 0, name: "Record Type", status: "current" },
        { id: 1, name: "Record Details", status: "upcoming" },
        { id: 2, name: "Record Preview", status: "upcoming" },
        {
            id: 3,
            name: "Record Submission",
            status: "upcoming",
        },
    ],
    recClass: { id: "", name: "" },
    recType: { id: "", name: "" },
    recInv: {
        invID: "",
        title: "",
        fore: "",
        sur: "",
        email: "",
        mobile: "",
        dob: "",
        occupation: "",
        bracket: "",
        risk: "",
    },
    recPro: {
        proID: "",
        shares: 0,
        address: "",
        area: "",
        size: "",
        bed: "",
        bath: "",
        utility: "",
        amenities: "",
        tenanted: false,
        rent: 0,
        deposit: 0,
        other: 0,
        price: 0,
        purCost: 0,
        traCost: 0,
        status: "",
    },
    recSPV: {
        spvID: "",
        name: "",
        proID: "",
        license: "",
        signatory: "",
        issue: "",
        expiry: "",
    },
    SPVSs: [],
    recSPVS: [],
    sharePros: [],
    shareSPVs: [],
    searchTermInv: "",
    searchTermPro: "",
    searchTermSPV: "",
    searchTermSPVS: "",
    success: false,
    successCountdown: 5,
    successType: "",
    csvComplete: false,
}

export const recSlice = createSlice({
    name: "rec",
    initialState,
    reducers: {
        reset: (state) => {
            state.recFormState = 0
            state.recState = { id: "X", name: "Dashboard" }
            state.recSteps = [
                { id: 0, name: "Record Type", status: "current" },
                { id: 1, name: "Record Details", status: "upcoming" },
                { id: 2, name: "Record Preview", status: "upcoming" },
                {
                    id: 3,
                    name: "Record Submission",
                    status: "upcoming",
                },
            ]
            state.recInv = {
                invID: "",
                title: "",
                fore: "",
                sur: "",
                email: "",
                mobile: "",
                dob: "",
                occupation: "",
                bracket: "",
                risk: "",
            }
            state.recPro = {
                proID: "",
                shares: 0,
                address: "",
                area: "",
                size: "",
                bed: "",
                bath: "",
                utility: "",
                amenities: "",
                tenanted: false,
                rent: 0,
                deposit: 0,
                other: 0,
                price: 0,
                purCost: 0,
                traCost: 0,
                status: "",
            }
            state.recSPV = {
                spvID: "",
                name: "",
                proID: "",
                license: "",
                signatory: "",
                issue: "",
                expiry: "",
            }
            state.recSPVS = []
            state.SPVSs = []
            state.sharePros = []
            state.shareSPVs = []
            state.searchTermInv = ""
            state.searchTermPro = ""
            state.searchTermSPV = ""
            state.searchTermSPVS = ""
            state.csvComplete = false
        },
        setRecState: (state, action) => {
            state.recState = action.payload
        },
        setRecFormState: (state, action) => {
            state.recFormState = action.payload
        },
        setRecSteps: (state, action) => {
            state.recSteps = action.payload
        },
        setRecClass: (state, action) => {
            state.recClass = action.payload
        },
        setRecType: (state, action) => {
            state.recType = action.payload
        },
        setRecInv: (state, action) => {
            state.recInv = action.payload
        },
        setRecPro: (state, action) => {
            state.recPro = action.payload
        },
        setRecSPV: (state, action) => {
            state.recSPV = action.payload
        },
        setSPVSs: (state, action) => {
            state.SPVSs.push(action.payload)
        },
        clearSPVSs: (state) => {
            state.SPVSs = []
        },
        setRecSPVS: (state, action) => {
            state.recSPVS = action.payload
        },
        setshareSPVs: (state, action) => {
            state.shareSPVs = action.payload
        },
        setsharePros: (state, action) => {
            state.sharePros = action.payload
        },
        setSearchTermInv: (state, action) => {
            state.searchTermInv = action.payload
        },
        setSearchTermPro: (state, action) => {
            state.searchTermPro = action.payload
        },
        setSearchTermSPV: (state, action) => {
            state.searchTermSPV = action.payload
        },
        setSearchTermSPVS: (state, action) => {
            state.searchTermSPVS = action.payload
        },
        setSuccess: (state, action) => {
            state.success = action.payload
        },
        setSuccessCountdown: (state, action) => {
            state.successCountdown = action.payload
        },
        setSuccessType: (state, action) => {
            state.successType = action.payload
        },
        setCSVComplete: (state, action) => {
            state.csvComplete = action.payload
        },
    },
    extraReducers: (builder) => {},
})

export const {
    reset,
    setRecState,
    setRecFormState,
    setRecSteps,
    setRecClass,
    setRecType,
    setRecInv,
    setRecPro,
    setRecSPV,
    setSPVSs,
    setRecSPVS,
    setshareSPVs,
    setsharePros,
    setSearchTermInv,
    setSearchTermPro,
    setSearchTermSPV,
    setSearchTermSPVS,
    setSuccess,
    setSuccessCountdown,
    setSuccessType,
    setCSVComplete,
    clearSPVSs,
} = recSlice.actions
export default recSlice.reducer
