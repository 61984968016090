import { useSelector, useDispatch } from "react-redux"

import { setRecClass, setRecFormState } from "../features/auth/recSlice"
import { RootState } from "../app/store"

import {
    HomeIcon,
    UserGroupIcon,
    DocumentTextIcon,
} from "@heroicons/react/outline"

const recClasses = [
    {
        id: "I",
        title: "Investors",
        description: "",
        icon: UserGroupIcon,
        background: "bg-green-500",
    },
    {
        id: "P",
        title: "Properties",
        description: "",
        icon: HomeIcon,
        background: "bg-blue-500",
    },
    {
        id: "S",
        title: "SPV",
        description: "",
        icon: DocumentTextIcon,
        background: "bg-yellow-500",
    },
    {
        id: "SS",
        title: "SPV Shareholding",
        description: "",
        icon: DocumentTextIcon,
        background: "bg-yellow-500",
    },
]

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const RecClass = () => {
    const dispatch = useDispatch()
    const { recFormState, recState } = useSelector(
        (state: RootState) => state.rec
    )

    return (
        <div className=''>
            <h2 className='text-lg font-medium text-gray-200'>
                {recState.name}
            </h2>
            <p className='mt-1 text-sm text-gray-300'>
                Select the appropriate record.
            </p>
            <ul className='mt-6 border-t border-b border-yellow-400 py-6 grid grid-cols-1 gap-6 sm:grid-cols-4'>
                {recClasses.map((item, itemIdx) => (
                    <li key={itemIdx} className='flow-root'>
                        <div className='relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-slate-600 focus-within:ring-2 focus-within:ring-indigo-500'>
                            <div
                                className={classNames(
                                    item.background,
                                    "flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg"
                                )}
                            >
                                <item.icon
                                    className='h-6 w-6 text-white'
                                    aria-hidden='true'
                                />
                            </div>
                            <div>
                                <h3 className='text-sm font-medium text-gray-200 hover:text-gray-100'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            dispatch(
                                                setRecClass({
                                                    id: item.id,
                                                    name: item.title,
                                                })
                                            )
                                            dispatch(
                                                setRecFormState(
                                                    recFormState + 1
                                                )
                                            )
                                        }}
                                        className='focus:outline-none'
                                    >
                                        <span
                                            className='absolute inset-0'
                                            aria-hidden='true'
                                        />
                                        {item.title}
                                    </button>
                                </h3>
                                <p className='mt-1 text-sm text-gray-400'>
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default RecClass
