import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import authReducer from "../features/auth/authSlice"
import tranReducer from "../features/auth/traSlice"
import appReducer from "../features/auth/appSlice"
import recReducer from "../features/auth/recSlice"
import accReducer from "../features/auth/accSlice"
import setReducer from "../features/auth/setSlice"

export const store = configureStore({
    reducer: {
        auth: authReducer,
        tran: tranReducer,
        app: appReducer,
        rec: recReducer,
        acc: accReducer,
        set: setReducer,
    },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
