import { useState, Fragment, useEffect, ChangeEvent } from "react"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../app/store"
import {
    setTranState,
    setTranFormState,
    resetTranSteps,
    setTranClass,
    setTranType,
} from "../features/auth/traSlice"
import { Combobox, Transition } from "@headlessui/react"

import TranSteps from "./TraSteps"

import { supabase } from "../supabaseClient"

import {
    HomeIcon,
    CashIcon,
    CreditCardIcon,
    EmojiSadIcon,
    ClipboardCheckIcon,
    ArrowSmUpIcon,
    ClipboardCopyIcon,
    ViewGridAddIcon,
    ReceiptRefundIcon,
    ArrowsExpandIcon,
    ReceiptTaxIcon,
    PlusCircleIcon,
    ViewListIcon,
} from "@heroicons/react/outline"

import {
    ChevronLeftIcon,
    ChevronRightIcon,
    XIcon,
    ExclamationCircleIcon,
    CheckCircleIcon,
} from "@heroicons/react/solid"
import NumberFormat from "react-number-format"

import TraView from "./TraView"
import TraSearch from "./TraSearch"

const mainTranClasses = [
    {
        id: "IF",
        title: "1. Initial Funding",
        description: "",
        icon: CashIcon,
        background: "bg-green-500",
    },
    {
        id: "P",
        title: "2. Purchase",
        description: "",
        icon: CreditCardIcon,
        background: "bg-yellow-500",
    },
    {
        id: "PNFF",
        title: "3. PNFF/IW",
        description:
            "Property not fully funded / Investment withdrawn in cooling off period",
        icon: EmojiSadIcon,
        background: "bg-red-500",
    },
    {
        id: "R",
        title: "4. Rental",
        description: "",
        icon: ClipboardCheckIcon,
        background: "bg-blue-500",
    },
    {
        id: "IW",
        title: "5. Investor Withdrawal",
        description: "",
        icon: ArrowSmUpIcon,
        background: "bg-red-500",
    },
    {
        id: "PE",
        title: "6. Property Expense",
        description: "",
        icon: HomeIcon,
        background: "bg-yellow-500",
    },
    {
        id: "RR",
        title: "7. Rental",
        description: "Including property expense reimbursement",
        icon: ClipboardCopyIcon,
        background: "bg-blue-500",
    },
    {
        id: "FP",
        title: "8. Funding property from wallet",
        description: "",
        icon: ViewGridAddIcon,
        background: "bg-red-500",
    },
    // {
    //     id: "BW",
    //     title: "9. Baytuki Withdrawal",
    //     description: "",
    //     icon: ReceiptRefundIcon,
    //     background: "bg-green-500",
    // },
    {
        id: "AIE",
        title: "10. All Investors Exit",
        description: "",
        icon: ArrowsExpandIcon,
        background: "bg-red-500",
    },
    {
        id: "BAAF",
        title: "11. Baytuki Annual Administration Fee",
        description: "",
        icon: ReceiptTaxIcon,
        background: "bg-green-500",
    },
    {
        id: "WT",
        title: "12. Wallet Top-Up",
        description: "",
        icon: CashIcon,
        background: "bg-green-500",
    },
]

const mainTranTypes = [
    {
        class: "IF",
        id: "1.1",
        title: "1.1 Initial Investor Funding",
        description: "",
        icon: CashIcon,
        background: "bg-green-500",
        account1: {
            id: "A_CMA",
            name: "Baytuki Tech Ltd Client Money Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account4: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["P", "I"],
    },
    {
        class: "P",
        id: "2.1",
        title: "2.1 Payment Seller",
        description: "",
        icon: CreditCardIcon,
        background: "bg-yellow-500",
        account1: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_CMA",
            name: "Baytuki Tech Ltd Client Money Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "SPV_Pro",
            name: "SPV Properties",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "SPV_SC",
            name: "SPV Share Capital",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "P",
        id: "2.2",
        title: "2.2 Purchase Cost",
        description: "",
        icon: CreditCardIcon,
        background: "bg-yellow-500",
        account1: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_CMA",
            name: "Baytuki Tech Ltd Client Money Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "SPV_Pro",
            name: "SPV Properties",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "SPV_SC",
            name: "SPV Share Capital",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "P",
        id: "2.3",
        title: "2.3 Baytuki Income",
        description: "",
        icon: CreditCardIcon,
        background: "bg-yellow-500",
        account1: {
            id: "A_CA",
            name: "Baytuki Tech Ltd Corporate Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_CMA",
            name: "Baytuki Tech Ltd Client Money Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "L_BI",
            name: "Baytuki Income",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "SPV_Pro",
            name: "SPV Properties",
            type: "d",
            mult: 1,
        },
        account6: {
            id: "SPV_SC",
            name: "SPV Share Capital",
            type: "c",
            mult: 1,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "X",
        id: "2.4",
        title: "2.4 Addition to Properties",
        description: "",
        icon: CreditCardIcon,
        background: "bg-yellow-500",
        account1: {
            id: "A_P",
            name: "Properties",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "E_SC",
            name: "Share Capital",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account4: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "P",
        id: "2.5",
        title: "2.5 Transaction Cost",
        description: "",
        icon: CreditCardIcon,
        background: "bg-yellow-500",
        account1: {
            id: "A_CA",
            name: "Baytuki Tech Ltd Corporate Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_CMA",
            name: "Baytuki Tech Ltd Client Money Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "L_BI",
            name: "Baytuki Income",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "SPV_Pro",
            name: "SPV Properties",
            type: "d",
            mult: 1,
        },
        account6: {
            id: "SPV_SC",
            name: "SPV Share Capital",
            type: "c",
            mult: 1,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "PNFF",
        id: "3.0",
        title: "3.0 Property Not Funded and Withdrawn",
        description: "",
        icon: EmojiSadIcon,
        background: "bg-red-500",
        account1: {
            id: "A_CMWA",
            name: "Baytuki Tech Ltd Client Money Wallet Acount",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_CMA",
            name: "Baytuki Tech Ltd Client Money Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "L_IWO",
            name: "Investor Wallet (Open)",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["P", "I"],
    },
    {
        class: "PNFF",
        id: "3.1",
        title: "3.1 Property Not Fully Funded",
        description: "",
        icon: EmojiSadIcon,
        background: "bg-red-500",
        account1: {
            id: "A_CMWA",
            name: "Baytuki Tech Ltd Client Money Wallet Acount",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_CMA",
            name: "Baytuki Tech Ltd Client Money Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account4: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["P", "I"],
    },
    {
        class: "PNFF",
        id: "3.2",
        title: "3.2 Withdrawn (Cooling-Off)",
        description: "",
        icon: EmojiSadIcon,
        background: "bg-red-500",
        account1: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "L_IWO",
            name: "Investor Wallet (Open)",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account4: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["P", "I"],
    },
    {
        class: "R",
        id: "4.1",
        title: "4.1 Security Deposit",
        description: "",
        icon: ClipboardCheckIcon,
        background: "bg-blue-500",
        account1: {
            id: "A_SDA",
            name: "Baytuki Tech Ltd Security Deposit Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "L_OL",
            name: "Other Liability",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account4: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["P"],
    },
    {
        class: "R",
        id: "4.2",
        title: "4.2 Rental Income",
        description: "",
        icon: ClipboardCheckIcon,
        background: "bg-blue-500",
        account1: {
            id: "A_RA",
            name: "Baytuki Tech Ltd Rental Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "SPV_RI",
            name: "SPV Rental Income",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "R",
        id: "4.3",
        title: "4.3 Baytuki Income",
        description: "",
        icon: ClipboardCheckIcon,
        background: "bg-blue-500",
        account1: {
            id: "A_CA",
            name: "Baytuki Tech Ltd Corporate Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_RA",
            name: "Baytuki Tech Ltd Rental Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "L_BI",
            name: "Baytuki Income",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "SPV_PMGF",
            name: "SPV Property Management Fee",
            type: "d",
            mult: 1,
        },
        account6: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "R",
        id: "4.4",
        title: "4.4 Property Expense",
        description: "",
        icon: ClipboardCheckIcon,
        background: "bg-blue-500",
        account1: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_RA",
            name: "Baytuki Tech Ltd Rental Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "SPV_PMTF",
            name: "SPV Property Maintenance Fees",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "R",
        id: "4.5",
        title: "4.5 Dividend Declared And Distributed",
        description: "",
        icon: ClipboardCheckIcon,
        background: "bg-blue-500",
        account1: {
            id: "A_CMWA",
            name: "Baytuki Tech Ltd Client Money Wallet Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_RA",
            name: "Baytuki Tech Ltd Rental Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "SPV_Div",
            name: "SPV Dividend",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account6: {
            id: "L_IWO",
            name: "Investor Wallet (Open)",
            type: "c",
            mult: 1,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "IW",
        id: "5.1",
        title: "5.1 Investor Withdrawal",
        description: "",
        icon: ArrowSmUpIcon,
        background: "bg-red-500",
        account1: {
            id: "L_IWO",
            name: "Investor Wallet (Open)",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_CMWA",
            name: "Baytuki Tech Ltd Client Money Wallet Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account4: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["I"],
    },
    {
        class: "PE",
        id: "6.1",
        title: "6.1 Property Expenses Paid by Baytuki",
        description: "",
        icon: HomeIcon,
        background: "bg-yellow-500",
        account1: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_CA",
            name: "Baytuki Tech Ltd Corprate Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "SPV_PMTF",
            name: "SPV Property Maintenance Fees",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "RR",
        id: "7.1",
        title: "7.1 Rental Income",
        description: "",
        icon: ClipboardCheckIcon,
        background: "bg-blue-500",
        account1: {
            id: "A_RA",
            name: "Baytuki Tech Ltd Rental Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "SPV_RI",
            name: "SPV Rental Income",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "RR",
        id: "7.2",
        title: "7.2 Property Expense Reimbursement",
        description: "",
        icon: ClipboardCheckIcon,
        background: "bg-blue-500",
        account1: {
            id: "A_CA",
            name: "Baytuki Tech Ltd Corporate Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_RA",
            name: "Baytuki Tech Ltd Rental Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "SPV_PMTF",
            name: "SPV Property Maintenance Fees",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["P"],
    },
    {
        class: "RR",
        id: "7.3",
        title: "7.3 Baytuki Income",
        description: "",
        icon: ClipboardCheckIcon,
        background: "bg-blue-500",
        account1: {
            id: "A_CA",
            name: "Baytuki Tech Ltd Corporate Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_RA",
            name: "Baytuki Tech Ltd Rental Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "L_BI",
            name: "Baytuki Income",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "SPV_PMGF",
            name: "SPV Property Management Fees",
            type: "d",
            mult: 1,
        },
        account6: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "RR",
        id: "7.4",
        title: "7.4 Property Expense",
        description: "",
        icon: ClipboardCheckIcon,
        background: "bg-blue-500",
        account1: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_RA",
            name: "Baytuki Tech Ltd Rental Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "SPV_PMTF",
            name: "SPV Property Maintenance Fees",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "RR",
        id: "7.5",
        title: "7.5 Dividend Declared And Distributed",
        description: "",
        icon: ClipboardCheckIcon,
        background: "bg-blue-500",
        account1: {
            id: "A_CMWA",
            name: "Baytuki Tech Ltd Client Money Wallet Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_RA",
            name: "Baytuki Tech Ltd Rental Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "L_IWO",
            name: "Investor Wallet (Open)",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "SPV_Div",
            name: "SPV Dividend",
            type: "d",
            mult: 1,
        },
        account6: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "FP",
        id: "8.1",
        title: "8.1 Funding From Wallet",
        description: "",
        icon: ViewGridAddIcon,
        background: "bg-red-500",
        account1: {
            id: "A_CMA",
            name: "Baytuki Tech Ltd Client Money Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_CMWA",
            name: "Baytuki Tech Ltd Client Money Wallet Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "L_IWO",
            name: "Investor Wallet (Open)",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["I", "P"],
    },
    {
        class: "X",
        id: "9.1",
        title: "9.1 Baytuki Withdrawal",
        description: "",
        icon: ReceiptRefundIcon,
        background: "bg-green-500",
        account1: {
            id: "A_CA",
            name: "Baytuki Tech Ltd Corporate Account",
            type: "c",
            mult: 1,
        },
        account2: {
            id: "L_BI",
            name: "Baytuki Income",
            type: "d",
            mult: 1,
        },
        account3: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account4: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: [],
    },
    {
        class: "AIE",
        id: "10.1",
        title: "10.1 Property Sale",
        description: "",
        icon: ArrowsExpandIcon,
        background: "bg-red-500",
        account1: {
            id: "A_CMA",
            name: "Baytuki Tech Ltd Client Money Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "SPV_Pro",
            name: "SPV Properties",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "SPV_SPI",
            name: "SPV Sale of Property - Income",
            type: "c",
            mult: 1,
        },
        account6: {
            id: "SPV_SC",
            name: "SPV Share Capital",
            type: "d",
            mult: 1,
        },
        account7: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        fields: ["S", "D"],
    },
    {
        class: "AIE",
        id: "10.2",
        title: "10.2 Baytuki Income",
        description: "",
        icon: ArrowsExpandIcon,
        background: "bg-red-500",
        account1: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_CMA",
            name: "Baytuki Tech Ltd Client Money Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "A_CA",
            name: "Baytuki Tech Ltd Corporate Account",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "L_BI",
            name: "Baytuki Income",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "SPV_SPEF",
            name: "SPV Sale of Property - Exit Fees",
            type: "d",
            mult: 1,
        },
        account6: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "AIE",
        id: "10.3",
        title: "10.3 Disposal Cost",
        description: "",
        icon: ArrowsExpandIcon,
        background: "bg-red-500",
        account1: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_CMA",
            name: "Baytuki Tech Ltd Client Money Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "SPV_SPEF",
            name: "SPV Sale of Property - Exit Fees",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "AIE",
        id: "10.4",
        title: "10.4 Security Deposit Returned",
        description: "",
        icon: ArrowsExpandIcon,
        background: "bg-red-500",
        account1: {
            id: "L_OL",
            name: "Other Liability",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_SDA",
            name: "Baytuki Tech Ltd Security Deposit Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account4: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["P"],
    },
    {
        class: "AIE",
        id: "10.5",
        title: "10.5 Share Capital Extinguished",
        description: "",
        icon: ArrowsExpandIcon,
        background: "bg-red-500",
        account1: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "L_IWO",
            name: "Investor Wallet (Open)",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "A_CMWA",
            name: "Baytuki Tech Ltd Client Money Wallet Account",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "A_CMA",
            name: "Baytuki Tech Ltd Client Money Account",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "AIE",
        id: "10.6",
        title: "10.6 Investor Withdrawal",
        description: "",
        icon: ArrowsExpandIcon,
        background: "bg-red-500",
        account1: {
            id: "L_IWO",
            name: "Investor Wallet (Open)",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_CMWA",
            name: "Baytuki Tech Ltd Client Money Wallet Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account4: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["I"],
    },
    {
        class: "AIE",
        id: "10.7",
        title: "10.7 Distribution on Sale",
        description: "",
        icon: ArrowsExpandIcon,
        background: "bg-red-500",
        account1: {
            id: "SPV_Div",
            name: "SPV Dividend",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account4: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "BAAF",
        id: "11.1",
        title: "11.1 Baytuki Income",
        description: "",
        icon: ReceiptTaxIcon,
        background: "bg-green-500",
        account1: {
            id: "A_CA",
            name: "Baytuki Tech Ltd Corporate Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "A_RA",
            name: "Baytuki Tech Ltd Rental Account",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "L_IWL",
            name: "Investor Wallet (Locked)",
            type: "d",
            mult: 1,
        },
        account4: {
            id: "L_BI",
            name: "Baytuki Income",
            type: "c",
            mult: 1,
        },
        account5: {
            id: "SPV_AAF",
            name: "SPV Annual Administration fee",
            type: "d",
            mult: 1,
        },
        account6: {
            id: "SPV_RBTI",
            name: "SPV Receivable from BT - Intercompany",
            type: "c",
            mult: 1,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["S"],
    },
    {
        class: "WT",
        id: "12.1",
        title: "12.1 Wallet Top-Up",
        description: "",
        icon: CashIcon,
        background: "bg-green-500",
        account1: {
            id: "A_CMWA",
            name: "Baytuki Tech Ltd Client Money Wallet Account",
            type: "d",
            mult: 1,
        },
        account2: {
            id: "L_IWO",
            name: "Investor Wallet (Open)",
            type: "c",
            mult: 1,
        },
        account3: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account4: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account5: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account6: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        account7: {
            id: "NA",
            name: "",
            type: "",
            mult: 0,
        },
        fields: ["I"],
    },
]

const mainTranSubTypes = [
    {
        id: "1.1",
        subtype: ["Initial Investor Funding"],
    },
    {
        id: "2.1",
        subtype: ["Payment to Property Owner"],
    },
    {
        id: "2.2",
        subtype: [
            "Dubai Land Department Fees % of Property Value",
            "Trustee Fees",
            "Agent Fees % of Property Value",
            "DIFC NOC Fees",
            "DIFC PC Incl Fees",
            "DIFC PC Annual License Fee",
            "Admin Fees To Be Paid to DLD",
            "NOC Charges",
            "External Valuation Fee",
            "Fees for Issuing Title Deed",
            "Utilities Deposit",
            "District Cooling",
            "Renovation",
            "Other Purchase Costs",
        ],
    },
    {
        id: "2.3",
        subtype: ["Baytuki Income on Purchase"],
    },
    {
        id: "2.4",
        subtype: ["Additional Real Estate"],
    },
    {
        id: "2.5",
        subtype: ["Other Transaction Costs"],
    },
    {
        id: "3.0",
        subtype: ["Property Not Funded and Withdrawn"],
    },
    {
        id: "3.1",
        subtype: ["Property Not Fully Funded"],
    },
    {
        id: "3.2",
        subtype: ["Withdrawn (Cooling-Off)"],
    },
    {
        id: "4.1",
        subtype: ["Security Deposit"],
    },
    {
        id: "4.2",
        subtype: ["Rent"],
    },
    {
        id: "4.3",
        subtype: ["Baytuki Income On Platform"],
    },
    {
        id: "4.4",
        subtype: [
            "Service Charge",
            "Property Insurance",
            "Other Rental Costs",
            "A/C Repair",
            "Plumbing",
            "Interiors",
            "Other Property Expenses",
        ],
    },
    {
        id: "4.5",
        subtype: ["Dividend Declared and Distributed"],
    },
    {
        id: "5.1",
        subtype: ["Investor Funds Withdrawal"],
    },
    {
        id: "6.1",
        subtype: [
            "Service Charge",
            "Property Insurance",
            "Other Rental Costs",
            "A/C Repair",
            "Plumbing",
            "Interiors",
            "Other Property Expenses",
        ],
    },
    {
        id: "7.1",
        subtype: ["Rent"],
    },
    {
        id: "7.2",
        subtype: [
            "Service Charge",
            "Property Insurance",
            "Other Rental Costs",
            "A/C Repair",
            "Plumbing",
            "Interiors",
            "Other Property Expenses",
        ],
    },
    {
        id: "7.3",
        subtype: ["Baytuki Income On Platform"],
    },
    {
        id: "7.4",
        subtype: [
            "Service Charge",
            "Property Insurance",
            "Other Rental Costs",
            "A/C Repair",
            "Plumbing",
            "Interiors",
            "Other Property Expenses",
        ],
    },
    {
        id: "7.5",
        subtype: ["Dividend Declared and Distributed"],
    },
    {
        id: "8.1",
        subtype: ["Investment Funding From Funded Investor Wallet"],
    },
    {
        id: "9.1",
        subtype: ["Baytuki Withdrawal"],
    },
    {
        id: "10.1",
        subtype: ["Sale Of Property"],
    },
    {
        id: "10.2",
        subtype: ["Baytuki Income On Sale"],
    },
    {
        id: "10.3",
        subtype: ["Selling Costs Paid", "Other Disposal Cost"],
    },
    {
        id: "10.4",
        subtype: ["Security Deposit"],
    },
    {
        id: "10.5",
        subtype: ["Share Capital Extinguished"],
    },
    {
        id: "10.6",
        subtype: ["Investor Funds Withdrawal"],
    },
    {
        id: "10.7",
        subtype: ["Distribution on Sale"],
    },
    {
        id: "11.1",
        subtype: ["Baytuki Income On Administration"],
    },
    {
        id: "12.1",
        subtype: ["Wallet Top-Up"],
    },
]

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const isInTheFuture = (givenDate: string) => {
    const now = new Date()

    now.setHours(23, 59, 59, 998)

    return new Date(givenDate) > now
}

const fromNumber = (original: String) => {
    if (original.includes(".")) {
        switch (original.substring(original.lastIndexOf(".")).length) {
            case 1:
                return Number(`${original.replace(",", "")}00`) * 100

            case 2:
                return Number(`${original.replace(",", "")}0`) * 100

            case 3:
                return Number(`${original.replace(",", "")}`) * 100

            default:
                return (
                    Number(
                        original.substring(0, original.lastIndexOf(".") + 3)
                    ) * 100
                )
        }
    } else {
        return Number(original) * 100
    }
}

type DashboardItem = {
    id: string
    title: string
    description: string
    icon: React.FC<React.SVGProps<SVGSVGElement>>
    background: string
}

type TranTypes = {
    class: string
    id: string
    title: string
    description: string
    icon: React.FC<React.SVGProps<SVGSVGElement>>
    background: string
    account1: {
        id: string
        name: string
        type: string
        mult: number
    }
    account2: {
        id: string
        name: string
        type: string
        mult: number
    }
    account3: {
        id: string
        name: string
        type: string
        mult: number
    }
    account4: {
        id: string
        name: string
        type: string
        mult: number
    }
    account5: {
        id: string
        name: string
        type: string
        mult: number
    }
    account6: {
        id: string
        name: string
        type: string
        mult: number
    }
    account7: {
        id: string
        name: string
        type: string
        mult: number
    }
    fields: string[]
}
type TranSubTypes = {
    id: string
    subtype: string[]
}
type Pro = {
    proID: string
    address: string
    area: string
}
type Inv = {
    invID: string
    title: string
    fore: string
    sur: string
}

type SPV = {
    spvID: string
    name: string
    proID: string
}

type SPVS = {
    spvsID: string
    spvID: string
    invID: string
    stake: number
}

type Tra = {
    traID: string
    tra: string
    date: string
    type: string
    subType: string
    reversed: boolean
    account1ID: string
    account2ID: string
    account3ID: string
    account4ID: string
    account5ID: string
    account6ID: string
    account7ID: string
    amount: number
    traRef: string
    notes: string
    investor: string
    property: string
    spv: string
}

type Account = {
    not: string
    date: string
    proID: string
    invID: string
    split: string
    debit: number
    credit: number
    traType: string
    traRef: string
    notes: string
    traID: string
}

type Transaction = {
    name: string
    accountID: string
    record: [
        {
            not: string
            date: string
            proID: string
            invID: string
            split: string
            debit: number
            credit: number
            traType: string
            traRef: string
            notes: string
            traID: string
        }
    ]
}

type Subset = {
    inv_id: string
    pro_id: string | null
    value: number
    type: string
    date: string
    reference: string | null
}

const TraMain = () => {
    const dispatch = useDispatch()
    const { tranState, tranFormState, tranClass, tranType } = useSelector(
        (state: RootState) => state.tran
    )
    const { role } = useSelector((state: RootState) => state.auth)

    const [tranDashboard, setTranDashboard] = useState<DashboardItem[]>([])

    useEffect(() => {
        setTranDashboard([])

        if (role.tra_i) {
            setTranDashboard((prev) => [
                ...prev,
                {
                    id: "NT",
                    title: "New Transaction",
                    description: "",
                    icon: PlusCircleIcon,
                    background: "bg-green-500",
                },
            ])
        }

        if (role.tra_v) {
            setTranDashboard((prev) => [
                ...prev,
                {
                    id: "VT",
                    title: "View Transactions",
                    description: "",
                    icon: ViewListIcon,
                    background: "bg-blue-500",
                },
            ])
        }
    }, [role])

    const today = new Date()

    const [pros, setPros] = useState<Pro[]>([])
    const [invs, setInvs] = useState<Inv[]>([])
    const [spvs, setSPVs] = useState<SPV[]>([])
    const [spvss, setSPVSs] = useState<SPVS[]>([])
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    // const [empty, setEmpty] = useState(false)
    const [success, setSuccess] = useState(false)
    const [successCountdown, setSuccessCountdown] = useState(5)
    const [errorDetails, setErrorDetails] = useState({
        code: "",
        message: "",
        details: "",
    })

    const [traData, setTraData] = useState({
        traSubType: "",
        date: `${today.getFullYear()}-${
            today.getMonth().toString().length === 1
                ? "0" + (today.getMonth() + 1).toString()
                : (today.getMonth() + 1).toString()
        }-${
            today.getDate().toString().length === 1
                ? "0" + today.getDate().toString()
                : today.getDate().toString()
        }`,
        invID: "",
        proID: "",
        spvID: "",
        amount: 0,
        traRef: "",
        notes: "",
        deduction: 0,
    })
    const {
        traSubType,
        date,
        invID,
        proID,
        amount,
        traRef,
        notes,
        spvID,
        deduction,
    } = traData
    const [amountDisplay, setAmountDisplay] = useState("")
    const [deductionDisplay, setDeductionDisplay] = useState("")
    const [tranTypes, setTranTypes] = useState<TranTypes[]>([])
    const [tranSubTypes, setTranSubTypes] = useState<TranSubTypes[]>([])
    const [tranFields, setTranFields] = useState<string[]>()

    const [transactions, setTransactions] = useState<Transaction[]>([])

    const [investorQuery, setInvestorQuery] = useState("")
    const [investorsShown, setInvestorsShown] = useState(false)
    const [propertyQuery, setPropertyQuery] = useState("")
    const [propertiesShown, setPropertiesShown] = useState(false)
    const [spvQuery, setSPVQuery] = useState("")
    const [spvsShown, setSPVsShown] = useState(false)
    const [invalid, setInvalid] = useState(false)
    const filteredInvestors =
        investorQuery === ""
            ? []
            : invs.filter((recInv) => {
                  return recInv.invID
                      .toLowerCase()
                      .includes(investorQuery.toLowerCase())
              })
    const filteredProperties =
        propertyQuery === ""
            ? []
            : pros.filter((recPro) => {
                  return recPro.proID
                      .toLowerCase()
                      .includes(propertyQuery.toLowerCase())
              })

    const filteredSPVs =
        spvQuery === ""
            ? []
            : spvs.filter((recSPV) => {
                  return recSPV.spvID
                      .toLowerCase()
                      .includes(spvQuery.toLowerCase())
              })

    const getPros = async () => {
        setPros([])
        setLoading(true)
        setError(false)
        const { data, error } = await supabase
            .from<Pro>("R_Pro")
            .select("proID,address,area")
            .order("proID", { ascending: true })
        if (data) {
            setPros(data)
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }

        setLoading(false)
    }
    const getInvs = async () => {
        setPros([])
        setLoading(true)
        setError(false)
        const { data, error } = await supabase
            .from<Inv>("R_Inv")
            .select("invID,title,fore,sur")
            .order("invID", { ascending: true })
        if (data) {
            setInvs(data)
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }

        setLoading(false)
    }
    const getSPVs = async () => {
        setSPVs([])
        setLoading(true)
        setError(false)
        const { data, error } = await supabase
            .from<SPV>("R_SPV")
            .select("spvID,name,proID")
            .order("spvID", { ascending: true })
        if (data) {
            setSPVs(data)
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }

        setLoading(false)
    }
    const getSPVSs = async () => {
        setSPVSs([])
        setLoading(true)
        setError(false)
        const { data, error } = await supabase
            .from<SPVS>("R_SPVS")
            .select("spvsID,spvID,invID,stake")
            .order("spvsID", { ascending: true })
        if (data) {
            setSPVSs(data)
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }

        setLoading(false)
    }

    useEffect(() => {
        setTranTypes(
            mainTranTypes.filter((type) => {
                return type.class === tranClass.id
            })
        )
    }, [tranClass])

    useEffect(() => {
        setTranSubTypes(
            mainTranSubTypes.filter((subType) => {
                return subType.id === tranType.id
            })
        )
        if (tranType.id !== "") {
            setTranFields(
                mainTranTypes.filter((type) => {
                    return type.id === tranType.id
                })[0].fields
            )
        }
    }, [tranType])

    useEffect(() => {
        if (tranFields?.includes("I")) getInvs()
        if (tranFields?.includes("P")) getPros()
        if (tranFields?.includes("S")) {
            getSPVs()
            getSPVSs()
        }
    }, [tranFields])

    useEffect(() => {
        if (successCountdown < 1) {
            setSuccess(false)
            return
        }

        const intervalId = setInterval(() => {
            setSuccessCountdown(successCountdown - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [successCountdown])

    const resetForm = () => {
        setTraData((prevState) => ({
            ...prevState,
            traSubType: "",
        }))
        setTraData((prevState) => ({
            ...prevState,
            date: `${today.getFullYear()}-${
                today.getMonth().toString().length === 1
                    ? "0" + (today.getMonth() + 1).toString()
                    : (today.getMonth() + 1).toString()
            }-${
                today.getDate().toString().length === 1
                    ? "0" + today.getDate().toString()
                    : today.getDate().toString()
            }`,
        }))

        setTraData((prevState) => ({
            ...prevState,
            amount: 0,
        }))
        setAmountDisplay("")

        setTraData((prevState) => ({
            ...prevState,
            deduction: 0,
        }))
        setDeductionDisplay("")

        setTraData((prevState) => ({
            ...prevState,
            invID: "",
        }))
        setTraData((prevState) => ({
            ...prevState,
            proID: "",
        }))
        setTraData((prevState) => ({
            ...prevState,
            spvID: "",
        }))
        setTraData((prevState) => ({
            ...prevState,
            traRef: "",
        }))
        setTraData((prevState) => ({
            ...prevState,
            notes: "",
        }))

        setTransactions([])
    }

    const onChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => {
        if (e.target.name === "date") {
            setTraData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        } else if (e.target.name === "amount") {
            setTraData((prevState) => ({
                ...prevState,
                [e.target.name]: fromNumber(
                    e.target.value.replaceAll(",", "")
                ).toFixed(0),
            }))
            setAmountDisplay(e.target.value)
        } else if (e.target.name === "deduction") {
            setTraData((prevState) => ({
                ...prevState,
                [e.target.name]: fromNumber(
                    e.target.value.replaceAll(",", "")
                ).toFixed(0),
            }))
            setDeductionDisplay(e.target.value)
        } else {
            setTraData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        }
    }

    const onSubmit = () => {
        setTransactions([])

        const sType = tranTypes.filter((sType) => {
            return sType.id === tranType.id
        })[0]
        if (sType.fields.includes("S")) {
            spvss
                .filter((recSPVSS) => {
                    return recSPVSS.spvID === spvID
                })
                .forEach((recSPVSS) => {
                    const propertyID = spvs.filter((recSPV) => {
                        return recSPV.spvID === spvID
                    })[0].proID
                    //Add first two transactions that are always present
                    setTransactions((prevTranscations) => [
                        ...prevTranscations,
                        {
                            name: sType.account1.name,
                            accountID: sType.account1.id,
                            record: [
                                {
                                    not: tranType.name
                                        .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                        .substring(1),
                                    date: date,
                                    proID: propertyID,
                                    invID: recSPVSS.invID,
                                    split: sType.account2.name,
                                    debit:
                                        sType.account1.type === "d"
                                            ? Number(
                                                  (
                                                      amount *
                                                      sType.account1.mult *
                                                      recSPVSS.stake
                                                  ).toFixed(0)
                                              )
                                            : 0,
                                    credit:
                                        sType.account1.type === "c"
                                            ? Number(
                                                  (
                                                      amount *
                                                      sType.account1.mult *
                                                      recSPVSS.stake
                                                  ).toFixed(0)
                                              )
                                            : 0,
                                    traType: traSubType,
                                    traRef: traRef,
                                    notes: notes,
                                    traID: "",
                                },
                            ],
                        },
                        {
                            name: sType.account2.name,
                            accountID: sType.account2.id,
                            record: [
                                {
                                    not: tranType.name
                                        .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                        .substring(1),
                                    date: date,
                                    proID: propertyID,
                                    invID: recSPVSS.invID,
                                    split: sType.account1.name,
                                    debit:
                                        sType.account2.type === "d"
                                            ? Number(
                                                  (
                                                      amount *
                                                      sType.account2.mult *
                                                      recSPVSS.stake
                                                  ).toFixed(0)
                                              )
                                            : 0,
                                    credit:
                                        sType.account2.type === "c"
                                            ? Number(
                                                  (
                                                      amount *
                                                      sType.account2.mult *
                                                      recSPVSS.stake
                                                  ).toFixed(0)
                                              )
                                            : 0,
                                    traType: traSubType,
                                    traRef: traRef,
                                    notes: notes,
                                    traID: "",
                                },
                            ],
                        },
                    ])
                    //Check for 3 and 4 and add if available
                    if (
                        sType.account3.id !== "NA" &&
                        sType.account4.id !== "NA"
                    ) {
                        setTransactions((prevTranscations) => [
                            ...prevTranscations,
                            {
                                name: sType.account3.name,
                                accountID: sType.account3.id,
                                record: [
                                    {
                                        not: tranType.name
                                            .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                            .substring(1),
                                        date: date,
                                        proID: propertyID,
                                        invID: recSPVSS.invID,
                                        split: sType.account4.name,
                                        debit:
                                            sType.account3.type === "d"
                                                ? Number(
                                                      (
                                                          amount *
                                                          sType.account3.mult *
                                                          recSPVSS.stake
                                                      ).toFixed(0)
                                                  )
                                                : 0,
                                        credit:
                                            sType.account3.type === "c"
                                                ? Number(
                                                      (
                                                          amount *
                                                          sType.account3.mult *
                                                          recSPVSS.stake
                                                      ).toFixed(0)
                                                  )
                                                : 0,
                                        traType: traSubType,
                                        traRef: traRef,
                                        notes: notes,
                                        traID: "",
                                    },
                                ],
                            },
                            {
                                name: sType.account4.name,
                                accountID: sType.account4.id,
                                record: [
                                    {
                                        not: tranType.name
                                            .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                            .substring(1),
                                        date: date,
                                        proID: propertyID,
                                        invID: recSPVSS.invID,
                                        split: sType.account3.name,
                                        debit:
                                            sType.account4.type === "d"
                                                ? sType.fields.includes("D")
                                                    ? Number(
                                                          (
                                                              amount *
                                                                  sType.account4
                                                                      .mult *
                                                                  recSPVSS.stake -
                                                              deduction *
                                                                  sType.account4
                                                                      .mult *
                                                                  recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                    : Number(
                                                          (
                                                              amount *
                                                              sType.account4
                                                                  .mult *
                                                              recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                : 0,
                                        credit:
                                            sType.account4.type === "c"
                                                ? sType.fields.includes("D")
                                                    ? Number(
                                                          (
                                                              amount *
                                                                  sType.account4
                                                                      .mult *
                                                                  recSPVSS.stake -
                                                              deduction *
                                                                  sType.account4
                                                                      .mult *
                                                                  recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                    : Number(
                                                          (
                                                              amount *
                                                              sType.account4
                                                                  .mult *
                                                              recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                : 0,
                                        traType: traSubType,
                                        traRef: traRef,
                                        notes: notes,
                                        traID: "",
                                    },
                                ],
                            },
                        ])
                    }
                    //Check for 7 and add 5,6 and 7 together (because of splits)
                    //if 7 is not available, proceed to 5 and 6
                    if (sType.account7.id !== "NA") {
                        setTransactions((prevTranscations) => [
                            ...prevTranscations,
                            {
                                name: sType.account5.name,
                                accountID: sType.account5.id,
                                record: [
                                    {
                                        not: tranType.name
                                            .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                            .substring(1),
                                        date: date,
                                        proID: propertyID,
                                        invID: recSPVSS.invID,
                                        split: "",
                                        debit:
                                            sType.account5.type === "d"
                                                ? sType.fields.includes("D")
                                                    ? Number(
                                                          (
                                                              deduction *
                                                              sType.account5
                                                                  .mult *
                                                              recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                    : Number(
                                                          (
                                                              amount *
                                                              sType.account5
                                                                  .mult *
                                                              recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                : 0,
                                        credit:
                                            sType.account5.type === "c"
                                                ? sType.fields.includes("D")
                                                    ? Number(
                                                          (
                                                              deduction *
                                                              sType.account5
                                                                  .mult *
                                                              recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                    : Number(
                                                          (
                                                              amount *
                                                              sType.account5
                                                                  .mult *
                                                              recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                : 0,
                                        traType: traSubType,
                                        traRef: traRef,
                                        notes: notes,
                                        traID: "",
                                    },
                                ],
                            },
                            {
                                name: sType.account6.name,
                                accountID: sType.account6.id,
                                record: [
                                    {
                                        not: tranType.name
                                            .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                            .substring(1),
                                        date: date,
                                        proID: propertyID,
                                        invID: recSPVSS.invID,
                                        split: sType.account7.name,
                                        debit:
                                            sType.account6.type === "d"
                                                ? sType.fields.includes("D")
                                                    ? Number(
                                                          (
                                                              amount *
                                                                  sType.account6
                                                                      .mult *
                                                                  recSPVSS.stake -
                                                              deduction *
                                                                  sType.account6
                                                                      .mult *
                                                                  recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                    : Number(
                                                          (
                                                              amount *
                                                              sType.account6
                                                                  .mult *
                                                              recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                : 0,
                                        credit:
                                            sType.account6.type === "c"
                                                ? sType.fields.includes("D")
                                                    ? Number(
                                                          (
                                                              amount *
                                                                  sType.account6
                                                                      .mult *
                                                                  recSPVSS.stake -
                                                              deduction *
                                                                  sType.account6
                                                                      .mult *
                                                                  recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                    : Number(
                                                          (
                                                              amount *
                                                              sType.account6
                                                                  .mult *
                                                              recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                : 0,
                                        traType: traSubType,
                                        traRef: traRef,
                                        notes: notes,
                                        traID: "",
                                    },
                                ],
                            },
                            {
                                name: sType.account7.name,
                                accountID: sType.account7.id,
                                record: [
                                    {
                                        not: tranType.name
                                            .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                            .substring(1),
                                        date: date,
                                        proID: propertyID,
                                        invID: recSPVSS.invID,
                                        split: sType.account6.name,
                                        debit:
                                            sType.account7.type === "d"
                                                ? sType.fields.includes("D")
                                                    ? Number(
                                                          (
                                                              amount *
                                                                  sType.account7
                                                                      .mult *
                                                                  recSPVSS.stake -
                                                              deduction *
                                                                  sType.account7
                                                                      .mult *
                                                                  recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                    : Number(
                                                          (
                                                              amount *
                                                              sType.account7
                                                                  .mult *
                                                              recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                : 0,
                                        credit:
                                            sType.account7.type === "c"
                                                ? sType.fields.includes("D")
                                                    ? Number(
                                                          (
                                                              amount *
                                                                  sType.account7
                                                                      .mult *
                                                                  recSPVSS.stake -
                                                              deduction *
                                                                  sType.account7
                                                                      .mult *
                                                                  recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                    : Number(
                                                          (
                                                              amount *
                                                              sType.account7
                                                                  .mult *
                                                              recSPVSS.stake
                                                          ).toFixed(0)
                                                      )
                                                : 0,
                                        traType: traSubType,
                                        traRef: traRef,
                                        notes: notes,
                                        traID: "",
                                    },
                                ],
                            },
                        ])
                    } else if (
                        sType.account5.id !== "NA" &&
                        sType.account6.id !== "NA"
                    ) {
                        setTransactions((prevTranscations) => [
                            ...prevTranscations,
                            {
                                name: sType.account5.name,
                                accountID: sType.account5.id,
                                record: [
                                    {
                                        not: tranType.name
                                            .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                            .substring(1),
                                        date: date,
                                        proID: propertyID,
                                        invID: recSPVSS.invID,
                                        split: sType.account6.name,
                                        debit:
                                            sType.account5.type === "d"
                                                ? Number(
                                                      (
                                                          amount *
                                                          sType.account5.mult *
                                                          recSPVSS.stake
                                                      ).toFixed(0)
                                                  )
                                                : 0,
                                        credit:
                                            sType.account5.type === "c"
                                                ? Number(
                                                      (
                                                          amount *
                                                          sType.account5.mult *
                                                          recSPVSS.stake
                                                      ).toFixed(0)
                                                  )
                                                : 0,
                                        traType: traSubType,
                                        traRef: traRef,
                                        notes: notes,
                                        traID: "",
                                    },
                                ],
                            },
                            {
                                name: sType.account6.name,
                                accountID: sType.account6.id,
                                record: [
                                    {
                                        not: tranType.name
                                            .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                            .substring(1),
                                        date: date,
                                        proID: propertyID,
                                        invID: recSPVSS.invID,
                                        split: sType.account5.name,
                                        debit:
                                            sType.account6.type === "d"
                                                ? Number(
                                                      (
                                                          amount *
                                                          sType.account6.mult *
                                                          recSPVSS.stake
                                                      ).toFixed(0)
                                                  )
                                                : 0,
                                        credit:
                                            sType.account6.type === "c"
                                                ? Number(
                                                      (
                                                          amount *
                                                          sType.account6.mult *
                                                          recSPVSS.stake
                                                      ).toFixed(0)
                                                  )
                                                : 0,
                                        traType: traSubType,
                                        traRef: traRef,
                                        notes: notes,
                                        traID: "",
                                    },
                                ],
                            },
                        ])
                    }

                    // if (sType.account7.id !== "NA") {
                    //     setTransactions((prevTranscations) => [
                    //         ...prevTranscations,
                    //         {
                    //             name: sType.account7.name,
                    //             accountID: sType.account7.id,
                    //             record: [
                    //                 {
                    //                     not: tranType.name
                    //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                    //                         .substring(1),
                    //                     date: date,
                    //                     proID: propertyID,
                    //                     invID: recSPVSS.invID,
                    //                     split: "",
                    //                     debit:
                    //                         sType.account7.type === "d"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account7.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     credit:
                    //                         sType.account7.type === "c"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account7.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     traType: traSubType,
                    //                     traRef: traRef,
                    //                     notes: notes,
                    //                     traID: "",
                    //                 },
                    //             ],
                    //         },
                    //     ])
                    // }

                    // if (sType.account5.id !== "NA") {
                    //     setTransactions((prevTranscations) => [
                    //         ...prevTranscations,
                    //         {
                    //             name: sType.account1.name,
                    //             accountID: sType.account1.id,
                    //             record: [
                    //                 {
                    //                     not: tranType.name
                    //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                    //                         .substring(1),
                    //                     date: date,
                    //                     proID: propertyID,
                    //                     invID: recSPVSS.invID,
                    //                     split: sType.account2.name,
                    //                     debit:
                    //                         sType.account1.type === "d"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account1.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     credit:
                    //                         sType.account1.type === "c"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account1.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     traType: traSubType,
                    //                     traRef: traRef,
                    //                     notes: notes,
                    //                     traID: "",
                    //                 },
                    //             ],
                    //         },
                    //         {
                    //             name: sType.account2.name,
                    //             accountID: sType.account2.id,
                    //             record: [
                    //                 {
                    //                     not: tranType.name
                    //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                    //                         .substring(1),
                    //                     date: date,
                    //                     proID: propertyID,
                    //                     invID: recSPVSS.invID,
                    //                     split: sType.account1.name,
                    //                     debit:
                    //                         sType.account2.type === "d"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account2.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     credit:
                    //                         sType.account2.type === "c"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account2.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     traType: traSubType,
                    //                     traRef: traRef,
                    //                     notes: notes,
                    //                     traID: "",
                    //                 },
                    //             ],
                    //         },
                    //         {
                    //             name: sType.account3.name,
                    //             accountID: sType.account3.id,
                    //             record: [
                    //                 {
                    //                     not: tranType.name
                    //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                    //                         .substring(1),
                    //                     date: date,
                    //                     proID: propertyID,
                    //                     invID: recSPVSS.invID,
                    //                     split: sType.account4.name,
                    //                     debit:
                    //                         sType.account3.type === "d"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account3.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     credit:
                    //                         sType.account3.type === "c"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account3.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     traType: traSubType,
                    //                     traRef: traRef,
                    //                     notes: notes,
                    //                     traID: "",
                    //                 },
                    //             ],
                    //         },
                    //         {
                    //             name: sType.account4.name,
                    //             accountID: sType.account4.id,
                    //             record: [
                    //                 {
                    //                     not: tranType.name
                    //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                    //                         .substring(1),
                    //                     date: date,
                    //                     proID: propertyID,
                    //                     invID: recSPVSS.invID,
                    //                     split: sType.account3.name,
                    //                     debit:
                    //                         sType.account4.type === "d"
                    //                             ? Number(
                    //                                   (
                    //   amount *
                    //       sType.account4
                    //           .mult *
                    //       recSPVSS.stake -
                    //   deduction *
                    //       sType.account2
                    //           .mult *
                    //       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     credit:
                    //                         sType.account4.type === "c"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                           sType.account4
                    //                                               .mult *
                    //                                           recSPVSS.stake -
                    //                                       deduction *
                    //                                           sType.account2
                    //                                               .mult *
                    //                                           recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     traType: traSubType,
                    //                     traRef: traRef,
                    //                     notes: notes,
                    //                     traID: "",
                    //                 },
                    //             ],
                    //         },
                    //         {
                    //             name: sType.account5.name,
                    //             accountID: sType.account5.id,
                    //             record: [
                    //                 {
                    //                     not: tranType.name
                    //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                    //                         .substring(1),
                    //                     date: date,
                    //                     proID: propertyID,
                    //                     invID: recSPVSS.invID,
                    //                     split: sType.account5.name,
                    //                     debit:
                    //                         sType.account5.type === "d"
                    //                             ? Number(
                    //                                   (
                    //                                       deduction *
                    //                                       sType.account5.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     credit:
                    //                         sType.account5.type === "c"
                    //                             ? Number(
                    //                                   (
                    //                                       deduction *
                    //                                       sType.account5.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     traType: traSubType,
                    //                     traRef: traRef,
                    //                     notes: notes,
                    //                     traID: "",
                    //                 },
                    //             ],
                    //         },
                    //     ])
                    // } else if (
                    //     sType.account3.id !== "NA" &&
                    //     sType.account4.id !== "NA"
                    // ) {
                    //     setTransactions((prevTranscations) => [
                    //         ...prevTranscations,
                    //         {
                    //             name: sType.account1.name,
                    //             accountID: sType.account1.id,
                    //             record: [
                    //                 {
                    //                     not: tranType.name
                    //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                    //                         .substring(1),
                    //                     date: date,
                    //                     proID: propertyID,
                    //                     invID: recSPVSS.invID,
                    //                     split: sType.account2.name,
                    //                     debit:
                    //                         sType.account1.type === "d"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account1.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     credit:
                    //                         sType.account1.type === "c"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account1.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     traType: traSubType,
                    //                     traRef: traRef,
                    //                     notes: notes,
                    //                     traID: "",
                    //                 },
                    //             ],
                    //         },
                    //         {
                    //             name: sType.account2.name,
                    //             accountID: sType.account2.id,
                    //             record: [
                    //                 {
                    //                     not: tranType.name
                    //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                    //                         .substring(1),
                    //                     date: date,
                    //                     proID: propertyID,
                    //                     invID: recSPVSS.invID,
                    //                     split: sType.account1.name,
                    //                     debit:
                    //                         sType.account2.type === "d"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account2.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     credit:
                    //                         sType.account2.type === "c"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account2.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     traType: traSubType,
                    //                     traRef: traRef,
                    //                     notes: notes,
                    //                     traID: "",
                    //                 },
                    //             ],
                    //         },
                    //         {
                    //             name: sType.account3.name,
                    //             accountID: sType.account3.id,
                    //             record: [
                    //                 {
                    //                     not: tranType.name
                    //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                    //                         .substring(1),
                    //                     date: date,
                    //                     proID: propertyID,
                    //                     invID: recSPVSS.invID,
                    //                     split: sType.account4.name,
                    //                     debit:
                    //                         sType.account3.type === "d"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account3.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     credit:
                    //                         sType.account3.type === "c"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account3.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     traType: traSubType,
                    //                     traRef: traRef,
                    //                     notes: notes,
                    //                     traID: "",
                    //                 },
                    //             ],
                    //         },
                    //         {
                    //             name: sType.account4.name,
                    //             accountID: sType.account4.id,
                    //             record: [
                    //                 {
                    //                     not: tranType.name
                    //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                    //                         .substring(1),
                    //                     date: date,
                    //                     proID: propertyID,
                    //                     invID: recSPVSS.invID,
                    //                     split: sType.account3.name,
                    //                     debit:
                    //                         sType.account4.type === "d"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account4.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     credit:
                    //                         sType.account4.type === "c"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account4.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     traType: traSubType,
                    //                     traRef: traRef,
                    //                     notes: notes,
                    //                     traID: "",
                    //                 },
                    //             ],
                    //         },
                    //     ])
                    // } else {
                    //     setTransactions((prevTranscations) => [
                    //         ...prevTranscations,
                    //         {
                    //             name: sType.account1.name,
                    //             accountID: sType.account1.id,
                    //             record: [
                    //                 {
                    //                     not: tranType.name
                    //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                    //                         .substring(1),
                    //                     date: date,
                    //                     proID: propertyID,
                    //                     invID: recSPVSS.invID,
                    //                     split: sType.account2.name,
                    //                     debit:
                    //                         sType.account1.type === "d"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account1.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     credit:
                    //                         sType.account1.type === "c"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account1.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     traType: traSubType,
                    //                     traRef: traRef,
                    //                     notes: notes,
                    //                     traID: "",
                    //                 },
                    //             ],
                    //         },
                    //         {
                    //             name: sType.account2.name,
                    //             accountID: sType.account2.id,
                    //             record: [
                    //                 {
                    //                     not: tranType.name
                    //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                    //                         .substring(1),
                    //                     date: date,
                    //                     proID: propertyID,
                    //                     invID: recSPVSS.invID,
                    //                     split: sType.account1.name,
                    //                     debit:
                    //                         sType.account2.type === "d"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account2.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     credit:
                    //                         sType.account2.type === "c"
                    //                             ? Number(
                    //                                   (
                    //                                       amount *
                    //                                       sType.account2.mult *
                    //                                       recSPVSS.stake
                    //                                   ).toFixed(0)
                    //                               )
                    //                             : 0,
                    //                     traType: traSubType,
                    //                     traRef: traRef,
                    //                     notes: notes,
                    //                     traID: "",
                    //                 },
                    //             ],
                    //         },
                    //     ])
                    // }
                })
        } else {
            //Add first two transactions that are always present
            setTransactions((prevTranscations) => [
                ...prevTranscations,
                {
                    name: sType.account1.name,
                    accountID: sType.account1.id,
                    record: [
                        {
                            not: tranType.name
                                .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                .substring(1),
                            date: date,
                            proID: proID,
                            invID: invID,
                            split: sType.account2.name,
                            debit:
                                sType.account1.type === "d"
                                    ? amount * sType.account1.mult
                                    : 0,
                            credit:
                                sType.account1.type === "c"
                                    ? amount * sType.account1.mult
                                    : 0,
                            traType: traSubType,
                            traRef: traRef,
                            notes: notes,
                            traID: "",
                        },
                    ],
                },
                {
                    name: sType.account2.name,
                    accountID: sType.account2.id,
                    record: [
                        {
                            not: tranType.name
                                .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                .substring(1),
                            date: date,
                            proID: proID,
                            invID: invID,
                            split: sType.account1.name,
                            debit:
                                sType.account2.type === "d"
                                    ? amount * sType.account2.mult
                                    : 0,
                            credit:
                                sType.account2.type === "c"
                                    ? amount * sType.account2.mult
                                    : 0,
                            traType: traSubType,
                            traRef: traRef,
                            notes: notes,
                            traID: "",
                        },
                    ],
                },
            ])
            //Check for 3 and 4 and add if available
            if (sType.account3.id !== "NA" && sType.account4.id !== "NA") {
                setTransactions((prevTranscations) => [
                    ...prevTranscations,
                    {
                        name: sType.account3.name,
                        accountID: sType.account3.id,
                        record: [
                            {
                                not: tranType.name
                                    .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                    .substring(1),
                                date: date,
                                proID: proID,
                                invID: invID,
                                split: sType.account4.name,
                                debit:
                                    sType.account3.type === "d"
                                        ? amount * sType.account3.mult
                                        : 0,
                                credit:
                                    sType.account3.type === "c"
                                        ? amount * sType.account3.mult
                                        : 0,
                                traType: traSubType,
                                traRef: traRef,
                                notes: notes,
                                traID: "",
                            },
                        ],
                    },
                    {
                        name: sType.account4.name,
                        accountID: sType.account4.id,
                        record: [
                            {
                                not: tranType.name
                                    .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                    .substring(1),
                                date: date,
                                proID: proID,
                                invID: invID,
                                split: sType.account3.name,
                                debit:
                                    sType.account4.type === "d"
                                        ? amount * sType.account4.mult
                                        : 0,
                                credit:
                                    sType.account4.type === "c"
                                        ? amount * sType.account4.mult
                                        : 0,
                                traType: traSubType,
                                traRef: traRef,
                                notes: notes,
                                traID: "",
                            },
                        ],
                    },
                ])
            }
            //Check for 5 and 6 and add if available
            if (sType.account5.id !== "NA" && sType.account6.id !== "NA") {
                setTransactions((prevTranscations) => [
                    ...prevTranscations,
                    {
                        name: sType.account5.name,
                        accountID: sType.account5.id,
                        record: [
                            {
                                not: tranType.name
                                    .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                    .substring(1),
                                date: date,
                                proID: proID,
                                invID: invID,
                                split: sType.account6.name,
                                debit:
                                    sType.account5.type === "d"
                                        ? amount * sType.account5.mult
                                        : 0,
                                credit:
                                    sType.account5.type === "c"
                                        ? amount * sType.account5.mult
                                        : 0,
                                traType: traSubType,
                                traRef: traRef,
                                notes: notes,
                                traID: "",
                            },
                        ],
                    },
                    {
                        name: sType.account6.name,
                        accountID: sType.account6.id,
                        record: [
                            {
                                not: tranType.name
                                    .split(/(\d\d\.\d)|(\d\.\d)/)[3]
                                    .substring(1),
                                date: date,
                                proID: proID,
                                invID: invID,
                                split: sType.account5.name,
                                debit:
                                    sType.account6.type === "d"
                                        ? amount * sType.account6.mult
                                        : 0,
                                credit:
                                    sType.account6.type === "c"
                                        ? amount * sType.account6.mult
                                        : 0,
                                traType: traSubType,
                                traRef: traRef,
                                notes: notes,
                                traID: "",
                            },
                        ],
                    },
                ])
            }

            // if (sType.account3.id !== "NA" && sType.account4.id !== "NA") {
            //     setTransactions((prevTranscations) => [
            //         ...prevTranscations,
            //         {
            //             name: sType.account1.name,
            //             accountID: sType.account1.id,
            //             record: [
            //                 {
            //                     not: tranType.name
            //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
            //                         .substring(1),
            //                     date: date,
            //                     proID: proID,
            //                     invID: invID,
            //                     split: sType.account2.name,
            //                     debit:
            //                         sType.account1.type === "d"
            //                             ? amount * sType.account1.mult
            //                             : 0,
            //                     credit:
            //                         sType.account1.type === "c"
            //                             ? amount * sType.account1.mult
            //                             : 0,
            //                     traType: traSubType,
            //                     traRef: traRef,
            //                     notes: notes,
            //                     traID: "",
            //                 },
            //             ],
            //         },
            //         {
            //             name: sType.account2.name,
            //             accountID: sType.account2.id,
            //             record: [
            //                 {
            //                     not: tranType.name
            //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
            //                         .substring(1),
            //                     date: date,
            //                     proID: proID,
            //                     invID: invID,
            //                     split: sType.account1.name,
            //                     debit:
            //                         sType.account2.type === "d"
            //                             ? amount * sType.account2.mult
            //                             : 0,
            //                     credit:
            //                         sType.account2.type === "c"
            //                             ? amount * sType.account2.mult
            //                             : 0,
            //                     traType: traSubType,
            //                     traRef: traRef,
            //                     notes: notes,
            //                     traID: "",
            //                 },
            //             ],
            //         },
            //         {
            //             name: sType.account3.name,
            //             accountID: sType.account3.id,
            //             record: [
            //                 {
            //                     not: tranType.name
            //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
            //                         .substring(1),
            //                     date: date,
            //                     proID: proID,
            //                     invID: invID,
            //                     split: sType.account4.name,
            //                     debit:
            //                         sType.account3.type === "d"
            //                             ? amount * sType.account3.mult
            //                             : 0,
            //                     credit:
            //                         sType.account3.type === "c"
            //                             ? amount * sType.account3.mult
            //                             : 0,
            //                     traType: traSubType,
            //                     traRef: traRef,
            //                     notes: notes,
            //                     traID: "",
            //                 },
            //             ],
            //         },
            //         {
            //             name: sType.account4.name,
            //             accountID: sType.account4.id,
            //             record: [
            //                 {
            //                     not: tranType.name
            //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
            //                         .substring(1),
            //                     date: date,
            //                     proID: proID,
            //                     invID: invID,
            //                     split: sType.account3.name,
            //                     debit:
            //                         sType.account4.type === "d"
            //                             ? amount * sType.account4.mult
            //                             : 0,
            //                     credit:
            //                         sType.account4.type === "c"
            //                             ? amount * sType.account4.mult
            //                             : 0,
            //                     traType: traSubType,
            //                     traRef: traRef,
            //                     notes: notes,
            //                     traID: "",
            //                 },
            //             ],
            //         },
            //     ])
            // } else {
            //     setTransactions((prevTranscations) => [
            //         ...prevTranscations,
            //         {
            //             name: sType.account1.name,
            //             accountID: sType.account1.id,
            //             record: [
            //                 {
            //                     not: tranType.name
            //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
            //                         .substring(1),
            //                     date: date,
            //                     proID: proID,
            //                     invID: invID,
            //                     split: sType.account2.name,
            //                     debit:
            //                         sType.account1.type === "d"
            //                             ? amount * sType.account1.mult
            //                             : 0,
            //                     credit:
            //                         sType.account1.type === "c"
            //                             ? amount * sType.account1.mult
            //                             : 0,
            //                     traType: traSubType,
            //                     traRef: traRef,
            //                     notes: notes,
            //                     traID: "",
            //                 },
            //             ],
            //         },
            //         {
            //             name: sType.account2.name,
            //             accountID: sType.account2.id,
            //             record: [
            //                 {
            //                     not: tranType.name
            //                         .split(/(\d\d\.\d)|(\d\.\d)/)[3]
            //                         .substring(1),
            //                     date: date,
            //                     proID: proID,
            //                     invID: invID,
            //                     split: sType.account1.name,
            //                     debit:
            //                         sType.account2.type === "d"
            //                             ? amount * sType.account2.mult
            //                             : 0,
            //                     credit:
            //                         sType.account2.type === "c"
            //                             ? amount * sType.account2.mult
            //                             : 0,
            //                     traType: traSubType,
            //                     traRef: traRef,
            //                     notes: notes,
            //                     traID: "",
            //                 },
            //             ],
            //         },
            //     ])
            // }
        }

        setTransactions((prev) =>
            prev.sort((a, b) => {
                return a.record[0].invID.localeCompare(b.record[0].invID)
            })
        )

        if (
            sType.fields.includes("S") &&
            spvss.filter((recSPVSS) => {
                return recSPVSS.spvID === spvID
            }).length === 0
        ) {
            setError(true)
            setErrorDetails({
                code: "",
                message: "Provided SPV has no shareholding",
                details: "Empty SPV",
            })
        } else {
            setError(false)
            dispatch(setTranFormState(tranFormState + 1))
        }
    }

    const getDistinctInvestor = (data: Transaction[]) => {
        let array: Transaction[] = []
        data.filter(function (item) {
            var i = array.findIndex(
                (x) => x.record[0].invID === item.record[0].invID
            )
            if (i <= -1) {
                array.push(item)
            }
            return null
        })
        return array
    }

    const onSave = async () => {
        const traType = mainTranTypes.filter((traType) => {
            return traType.id === tranType.id
        })[0]

        setSaving(true)

        const newTra = {
            tra: JSON.stringify(transactions),
            date: date,
            type: traType.title,
            subType: traSubType,
            reversed: false,
            account1ID: `A_${traType.account1.id}`,
            account2ID: `A_${traType.account2.id}`,
            account3ID:
                traType.account3.id === "NA" ? "" : `A_${traType.account3.id}`,
            account4ID:
                traType.account4.id === "NA" ? "" : `A_${traType.account4.id}`,
            account5ID:
                traType.account5.id === "NA" ? "" : `A_${traType.account5.id}`,
            account6ID:
                traType.account6.id === "NA" ? "" : `A_${traType.account6.id}`,
            account7ID:
                traType.account7.id === "NA" ? "" : `A_${traType.account7.id}`,
            amount: amount,
            traRef: traRef,
            notes: notes,
            property:
                tranFields?.includes("P") || tranFields?.includes("S")
                    ? transactions[0].record[0].proID
                    : "",
            investor: String(
                getDistinctInvestor(transactions).map((tran) => {
                    return tran.record[0].invID
                })
            ),
            spv: tranFields?.includes("S") ? spvID : "",
        }

        const { data, error } = await supabase
            .from<Tra>("Transactions")
            .insert([newTra])

        if (data) {
            transactions.forEach((tran) => {
                const accountData = tran
                accountData.record[0].traID = data[0].traID
                saveAccount(accountData)
            })
            if (data[0].investor !== "") {
                getDistinctInvestor(transactions).forEach((tran) => {
                    const subSet = {
                        inv_id: tran.record[0].invID,
                        pro_id:
                            tran.record[0].proID === ""
                                ? null
                                : tran.record[0].proID,
                        value:
                            tran.record[0].credit === 0
                                ? tran.record[0].debit
                                : tran.record[0].credit,
                        type: tran.record[0].traType,
                        date: tran.record[0].date,
                        reference:
                            tran.record[0].traRef === ""
                                ? null
                                : tran.record[0].traRef,
                    }
                    saveSubset(subSet)
                })
            }
        }

        setSaving(false)

        if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        } else {
            setSuccessCountdown(5)
            setSuccess(true)
            dispatch(setTranFormState(0))
            dispatch(setTranState("X"))
            dispatch(resetTranSteps())
            resetForm()
        }
    }

    const saveAccount = async (data: Transaction) => {
        await supabase
            .from<Account>(`A_${data.accountID}`)
            .insert([data.record[0]], { returning: "minimal" })
    }

    const saveSubset = async (data: Subset) => {
        await supabase
            .from<Subset>(`T_Subset`)
            .insert(data, { returning: "minimal" })
    }
    return (
        <>
            <div className='flex flex-col content-evenly bg-gray-800 h-full'>
                <div className=' content-start mx-10 mt-5 '>
                    {tranState === "NT" ? (
                        <TranSteps />
                    ) : tranState === "VT" ? (
                        <TraSearch />
                    ) : (
                        ""
                    )}
                </div>
                <div className=' content-end mx-10 mt-10'>
                    {tranFormState > 0 && tranFormState < 4 ? (
                        <div className='mb-5'>
                            <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()

                                        dispatch(
                                            setTranFormState(tranFormState - 1)
                                        )
                                        if (tranFormState === 2) {
                                            resetForm()
                                        }
                                    }}
                                    type='button'
                                    className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                >
                                    <ChevronLeftIcon
                                        className='h-5 w-5'
                                        aria-hidden='true'
                                    />
                                    <span>Previous</span>
                                </button>
                            </span>
                        </div>
                    ) : tranState !== "X" && tranFormState < 4 ? (
                        <div className='mb-5'>
                            <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()

                                        dispatch(setTranState("X"))
                                    }}
                                    type='button'
                                    className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                >
                                    <ChevronLeftIcon
                                        className='h-5 w-5'
                                        aria-hidden='true'
                                    />
                                    <span>Previous</span>
                                </button>
                            </span>
                        </div>
                    ) : (
                        ""
                    )}
                    {tranState === "X" ? (
                        <div className=''>
                            <h2 className='text-lg font-medium text-gray-200'>
                                Transactions
                            </h2>
                            <p className='mt-1 text-sm text-gray-300'></p>
                            <ul className='mt-6 border-t border-b border-yellow-400 py-6 grid grid-cols-1 gap-6 sm:grid-cols-4'>
                                {tranDashboard.map((item, itemIdx) => (
                                    <li key={itemIdx} className='flow-root'>
                                        <div className='relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-slate-600 focus-within:ring-2 focus-within:ring-indigo-500'>
                                            <div
                                                className={classNames(
                                                    item.background,
                                                    "flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg"
                                                )}
                                            >
                                                <item.icon
                                                    className='h-6 w-6 text-white'
                                                    aria-hidden='true'
                                                />
                                            </div>
                                            <div>
                                                <h3 className='text-sm font-medium text-gray-200 hover:text-gray-100'>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault()

                                                            dispatch(
                                                                setTranState(
                                                                    item.id
                                                                )
                                                            )
                                                        }}
                                                        className='focus:outline-none'
                                                    >
                                                        <span
                                                            className='absolute inset-0'
                                                            aria-hidden='true'
                                                        />
                                                        {item.title}
                                                        <span aria-hidden='true'>
                                                            {" "}
                                                            &rarr;
                                                        </span>
                                                    </button>
                                                </h3>
                                                <p className='mt-1 text-sm text-gray-400'>
                                                    {item.description}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : tranState === "NT" ? (
                        tranFormState === 0 ? (
                            <div className=''>
                                <h2 className='text-lg font-medium text-gray-200'>
                                    Transaction Class
                                </h2>
                                <p className='mt-1 text-sm text-gray-300'>
                                    Select the main transaction class.
                                </p>
                                <ul className='mt-6 border-t border-b border-yellow-400 py-6 grid grid-cols-1 gap-6 sm:grid-cols-4'>
                                    {mainTranClasses.map((item, itemIdx) => (
                                        <li key={itemIdx} className='flow-root'>
                                            <div className='relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-slate-600 focus-within:ring-2 focus-within:ring-indigo-500'>
                                                <div
                                                    className={classNames(
                                                        item.background,
                                                        "flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg"
                                                    )}
                                                >
                                                    <item.icon
                                                        className='h-6 w-6 text-white'
                                                        aria-hidden='true'
                                                    />
                                                </div>
                                                <div>
                                                    <h3 className='text-sm font-medium text-gray-200 hover:text-gray-100'>
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                dispatch(
                                                                    setTranClass(
                                                                        {
                                                                            id: item.id,
                                                                            name: item.title,
                                                                        }
                                                                    )
                                                                )
                                                                dispatch(
                                                                    setTranFormState(
                                                                        tranFormState +
                                                                            1
                                                                    )
                                                                )
                                                            }}
                                                            className='focus:outline-none'
                                                        >
                                                            <span
                                                                className='absolute inset-0'
                                                                aria-hidden='true'
                                                            />
                                                            {item.title}
                                                        </button>
                                                    </h3>
                                                    <p className='mt-1 text-sm text-gray-400'>
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : tranFormState === 1 ? (
                            <div className=''>
                                <h2 className='text-lg font-medium text-gray-200'>
                                    {tranClass.name}
                                </h2>
                                <p className='mt-1 text-sm text-gray-300'>
                                    Select the appripriate type of transaction.
                                </p>
                                <ul className='mt-6 border-t border-b border-yellow-400 py-6 grid grid-cols-1 gap-6 sm:grid-cols-2'>
                                    {tranTypes.map((item, itemIdx) => (
                                        <li key={itemIdx} className='flow-root'>
                                            <div className='relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-slate-600 focus-within:ring-2 focus-within:ring-indigo-500'>
                                                <div
                                                    className={classNames(
                                                        item.background,
                                                        "flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg"
                                                    )}
                                                >
                                                    <item.icon
                                                        className='h-6 w-6 text-white'
                                                        aria-hidden='true'
                                                    />
                                                </div>
                                                <div>
                                                    <h3 className='text-sm font-medium text-gray-200 hover:text-gray-100'>
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                dispatch(
                                                                    setTranType(
                                                                        {
                                                                            id: item.id,
                                                                            name: item.title,
                                                                        }
                                                                    )
                                                                )

                                                                dispatch(
                                                                    setTranFormState(
                                                                        tranFormState +
                                                                            1
                                                                    )
                                                                )
                                                            }}
                                                            className='focus:outline-none'
                                                        >
                                                            <span
                                                                className='absolute inset-0'
                                                                aria-hidden='true'
                                                            />
                                                            {item.title}
                                                            <span aria-hidden='true'>
                                                                {" "}
                                                                &rarr;
                                                            </span>
                                                        </button>
                                                    </h3>
                                                    <p className='mt-1 text-sm text-gray-400'>
                                                        {item.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : tranFormState === 2 ? (
                            <div className=''>
                                <form className='mt-7 space-y-8 divide-y divide-gray-200'>
                                    <div className='  space-y-8 divide-y divide-gray-200'>
                                        <div className=''>
                                            <div>
                                                <h3 className='text-lg leading-6 font-medium text-gray-200'>
                                                    {tranType.name}
                                                </h3>
                                                <p className='mt-1 text-sm text-gray-300'>
                                                    Fill in the form below with
                                                    the relevant transaction
                                                    details.
                                                </p>
                                            </div>
                                            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                                                <div className='sm:col-span-3'>
                                                    <label
                                                        htmlFor='traSubType'
                                                        className='block text-sm font-medium text-gray-300'
                                                    >
                                                        Sub-type
                                                    </label>
                                                    <div className='mt-1'>
                                                        <select
                                                            value={traSubType}
                                                            onChange={(e) => {
                                                                onChange(e)
                                                                setInvalid(
                                                                    false
                                                                )
                                                            }}
                                                            id='traSubType'
                                                            name='traSubType'
                                                            className='bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md color'
                                                        >
                                                            <option></option>
                                                            {tranSubTypes.map(
                                                                (subType) =>
                                                                    subType.id ===
                                                                    tranType.id
                                                                        ? subType.subtype.map(
                                                                              (
                                                                                  sType
                                                                              ) => (
                                                                                  <option
                                                                                      key={
                                                                                          sType
                                                                                      }
                                                                                      value={
                                                                                          sType
                                                                                      }
                                                                                  >
                                                                                      {
                                                                                          sType
                                                                                      }
                                                                                  </option>
                                                                              )
                                                                          )
                                                                        : ""
                                                            )}
                                                        </select>
                                                    </div>
                                                    {invalid &&
                                                    traSubType === "" ? (
                                                        <span className='text-sm text-red-500'>
                                                            Transaction sub-type
                                                            cannot be empty
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>

                                                <div className='sm:col-span-1'>
                                                    <label
                                                        htmlFor='date'
                                                        className='block text-sm font-medium text-gray-300'
                                                    >
                                                        Date
                                                    </label>
                                                    <div className='mt-1'>
                                                        <input
                                                            onChange={(e) => {
                                                                onChange(e)
                                                                setInvalid(
                                                                    false
                                                                )
                                                            }}
                                                            value={date}
                                                            type='date'
                                                            name='date'
                                                            id='date'
                                                            className=' pb-1 appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                        />
                                                    </div>
                                                    {invalid &&
                                                    isInTheFuture(date) ? (
                                                        <span className='text-sm text-red-500'>
                                                            Transaction cannot
                                                            be in the future
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>

                                                <div className='sm:col-span-1'>
                                                    <label
                                                        htmlFor='amount'
                                                        className='block text-sm font-medium text-gray-300'
                                                    >
                                                        Amount (AED)
                                                    </label>
                                                    <div className='mt-1'>
                                                        <NumberFormat
                                                            className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                            name='amount'
                                                            id='amount'
                                                            value={
                                                                amountDisplay
                                                            }
                                                            displayType={
                                                                "input"
                                                            }
                                                            allowNegative={
                                                                false
                                                            }
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            decimalScale={2}
                                                            onChange={(
                                                                e: ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                                onChange(e)

                                                                setInvalid(
                                                                    false
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                    {invalid &&
                                                    (String(amount) === "" ||
                                                        isNaN(amount) ||
                                                        amount === 0 ||
                                                        amount < 0) ? (
                                                        <span className='text-sm text-red-500'>
                                                            Amount is invalid or
                                                            empty
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                {tranFields?.includes("D") ? (
                                                    <div className='sm:col-span-1'>
                                                        <label
                                                            htmlFor='deduction'
                                                            className='block text-sm font-medium text-gray-300'
                                                        >
                                                            Gain/Loss (AED)
                                                        </label>
                                                        <div className='mt-1'>
                                                            <NumberFormat
                                                                className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                name='deduction'
                                                                id='deduction'
                                                                value={
                                                                    deductionDisplay
                                                                }
                                                                displayType={
                                                                    "input"
                                                                }
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                decimalScale={2}
                                                                onChange={(
                                                                    e: ChangeEvent<HTMLInputElement>
                                                                ) => {
                                                                    onChange(e)

                                                                    setInvalid(
                                                                        false
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                        {invalid &&
                                                        (String(deduction) ===
                                                            "" ||
                                                            deductionDisplay ===
                                                                "" ||
                                                            isNaN(
                                                                deduction
                                                            )) ? (
                                                            <span className='text-sm text-red-500'>
                                                                Gain/Loss is
                                                                invalid or empty
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {tranFields?.includes("I") ? (
                                                    loading ? (
                                                        <div className='sm:col-span-3'>
                                                            <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                                                                <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                                                                    <svg
                                                                        className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                        fill='none'
                                                                        viewBox='0 0 24 24'
                                                                    >
                                                                        <circle
                                                                            className='opacity-25'
                                                                            cx='12'
                                                                            cy='12'
                                                                            r='10'
                                                                            stroke='currentColor'
                                                                            strokeWidth='4'
                                                                        ></circle>
                                                                        <path
                                                                            className='opacity-75'
                                                                            fill='currentColor'
                                                                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                                        ></path>
                                                                    </svg>
                                                                    Loading ...
                                                                </div>
                                                            </div>{" "}
                                                        </div>
                                                    ) : (
                                                        <div className='sm:col-span-3'>
                                                            <label
                                                                htmlFor='invID'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Investor ID
                                                            </label>

                                                            <Combobox
                                                                onChange={() => {}}
                                                                value={invID}
                                                            >
                                                                <div className='relative'>
                                                                    <Combobox.Input
                                                                        className=' mt-1 bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            setInvestorsShown(
                                                                                true
                                                                            )
                                                                            setInvestorQuery(
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                            setInvalid(
                                                                                false
                                                                            )
                                                                        }}
                                                                    />
                                                                </div>

                                                                {filteredInvestors.length >
                                                                    0 &&
                                                                    investorsShown && (
                                                                        <Combobox.Options
                                                                            static
                                                                            className='bg-gray-700 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-200 rounded-b-xl'
                                                                        >
                                                                            {filteredInvestors.map(
                                                                                (
                                                                                    recInv
                                                                                ) => (
                                                                                    <Combobox.Option
                                                                                        onClick={() => {
                                                                                            setTraData(
                                                                                                (
                                                                                                    prevState
                                                                                                ) => ({
                                                                                                    ...prevState,
                                                                                                    invID: recInv.invID,
                                                                                                })
                                                                                            )
                                                                                            setInvestorsShown(
                                                                                                false
                                                                                            )
                                                                                        }}
                                                                                        key={
                                                                                            recInv.invID
                                                                                        }
                                                                                        value={
                                                                                            recInv.invID
                                                                                        }
                                                                                        className={({
                                                                                            active,
                                                                                        }) =>
                                                                                            classNames(
                                                                                                "cursor-default select-none px-4 py-2",
                                                                                                active
                                                                                                    ? "bg-blue-600 text-white"
                                                                                                    : ""
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {`${recInv.invID} ${recInv.title} ${recInv.fore} ${recInv.sur}`}
                                                                                    </Combobox.Option>
                                                                                )
                                                                            )}
                                                                        </Combobox.Options>
                                                                    )}

                                                                {investorQuery !==
                                                                    "" &&
                                                                    filteredInvestors.length ===
                                                                        0 && (
                                                                        <p className='p-4 text-sm text-gray-500'>
                                                                            No
                                                                            investor
                                                                            found.
                                                                        </p>
                                                                    )}
                                                            </Combobox>
                                                            {invalid &&
                                                            invID === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Investor ID
                                                                    cannot be
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    )
                                                ) : (
                                                    ""
                                                )}

                                                {tranFields?.includes("P") ? (
                                                    loading ? (
                                                        <div className='sm:col-span-3'>
                                                            <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                                                                <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                                                                    <svg
                                                                        className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                        fill='none'
                                                                        viewBox='0 0 24 24'
                                                                    >
                                                                        <circle
                                                                            className='opacity-25'
                                                                            cx='12'
                                                                            cy='12'
                                                                            r='10'
                                                                            stroke='currentColor'
                                                                            strokeWidth='4'
                                                                        ></circle>
                                                                        <path
                                                                            className='opacity-75'
                                                                            fill='currentColor'
                                                                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                                        ></path>
                                                                    </svg>
                                                                    Loading ...
                                                                </div>
                                                            </div>{" "}
                                                        </div>
                                                    ) : (
                                                        <div className='sm:col-span-3'>
                                                            <label
                                                                htmlFor='last-name'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Property ID
                                                            </label>

                                                            <Combobox
                                                                onChange={() => {}}
                                                                value={proID}
                                                            >
                                                                <div className='relative'>
                                                                    <Combobox.Input
                                                                        className=' mt-1 bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            setPropertiesShown(
                                                                                true
                                                                            )
                                                                            setPropertyQuery(
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                            setInvalid(
                                                                                false
                                                                            )
                                                                        }}
                                                                    />
                                                                </div>

                                                                {filteredProperties.length >
                                                                    0 &&
                                                                    propertiesShown && (
                                                                        <Combobox.Options
                                                                            static
                                                                            className='bg-gray-700 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-200 rounded-b-xl'
                                                                        >
                                                                            {filteredProperties.map(
                                                                                (
                                                                                    recPro
                                                                                ) => (
                                                                                    <Combobox.Option
                                                                                        onClick={() => {
                                                                                            setTraData(
                                                                                                (
                                                                                                    prevState
                                                                                                ) => ({
                                                                                                    ...prevState,
                                                                                                    proID: recPro.proID,
                                                                                                })
                                                                                            )
                                                                                            setPropertiesShown(
                                                                                                false
                                                                                            )
                                                                                        }}
                                                                                        key={
                                                                                            recPro.proID
                                                                                        }
                                                                                        value={
                                                                                            recPro.proID
                                                                                        }
                                                                                        className={({
                                                                                            active,
                                                                                        }) =>
                                                                                            classNames(
                                                                                                "cursor-default select-none px-4 py-2",
                                                                                                active
                                                                                                    ? "bg-blue-600 text-white"
                                                                                                    : ""
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {`${recPro.proID} ${recPro.area} ${recPro.address}`}
                                                                                    </Combobox.Option>
                                                                                )
                                                                            )}
                                                                        </Combobox.Options>
                                                                    )}

                                                                {propertyQuery !==
                                                                    "" &&
                                                                    filteredProperties.length ===
                                                                        0 && (
                                                                        <p className='p-4 text-sm text-gray-500'>
                                                                            No
                                                                            property
                                                                            found.
                                                                        </p>
                                                                    )}
                                                            </Combobox>
                                                            {invalid &&
                                                            proID === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property ID
                                                                    cannot be
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    )
                                                ) : (
                                                    ""
                                                )}

                                                {tranFields?.includes("S") ? (
                                                    loading ? (
                                                        <div className='sm:col-span-3'>
                                                            <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                                                                <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                                                                    <svg
                                                                        className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                        fill='none'
                                                                        viewBox='0 0 24 24'
                                                                    >
                                                                        <circle
                                                                            className='opacity-25'
                                                                            cx='12'
                                                                            cy='12'
                                                                            r='10'
                                                                            stroke='currentColor'
                                                                            strokeWidth='4'
                                                                        ></circle>
                                                                        <path
                                                                            className='opacity-75'
                                                                            fill='currentColor'
                                                                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                                        ></path>
                                                                    </svg>
                                                                    Loading ...
                                                                </div>
                                                            </div>{" "}
                                                        </div>
                                                    ) : (
                                                        <div className='sm:col-span-3'>
                                                            <label
                                                                htmlFor='invID'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                SPV ID
                                                            </label>

                                                            <Combobox
                                                                onChange={() => {}}
                                                                value={spvID}
                                                            >
                                                                <div className='relative'>
                                                                    <Combobox.Input
                                                                        className=' mt-1 bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            setSPVsShown(
                                                                                true
                                                                            )
                                                                            setSPVQuery(
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                            setInvalid(
                                                                                false
                                                                            )
                                                                        }}
                                                                    />
                                                                </div>

                                                                {filteredSPVs.length >
                                                                    0 &&
                                                                    spvsShown && (
                                                                        <Combobox.Options
                                                                            static
                                                                            className='bg-gray-700 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-200 rounded-b-xl'
                                                                        >
                                                                            {filteredSPVs.map(
                                                                                (
                                                                                    recSPV
                                                                                ) => (
                                                                                    <Combobox.Option
                                                                                        onClick={() => {
                                                                                            setTraData(
                                                                                                (
                                                                                                    prevState
                                                                                                ) => ({
                                                                                                    ...prevState,
                                                                                                    spvID: recSPV.spvID,
                                                                                                })
                                                                                            )
                                                                                            setSPVsShown(
                                                                                                false
                                                                                            )
                                                                                        }}
                                                                                        key={
                                                                                            recSPV.spvID
                                                                                        }
                                                                                        value={
                                                                                            recSPV.spvID
                                                                                        }
                                                                                        className={({
                                                                                            active,
                                                                                        }) =>
                                                                                            classNames(
                                                                                                "cursor-default select-none px-4 py-2",
                                                                                                active
                                                                                                    ? "bg-blue-600 text-white"
                                                                                                    : ""
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {`${recSPV.spvID} ${recSPV.name} ${recSPV.proID}`}
                                                                                    </Combobox.Option>
                                                                                )
                                                                            )}
                                                                        </Combobox.Options>
                                                                    )}

                                                                {spvQuery !==
                                                                    "" &&
                                                                    filteredSPVs.length ===
                                                                        0 && (
                                                                        <p className='p-4 text-sm text-gray-500'>
                                                                            No
                                                                            SPV
                                                                            found.
                                                                        </p>
                                                                    )}
                                                            </Combobox>
                                                            {invalid &&
                                                            spvID === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    SPV ID
                                                                    cannot be
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    )
                                                ) : (
                                                    ""
                                                )}

                                                <div className='sm:col-span-2'>
                                                    <label
                                                        htmlFor='traRef'
                                                        className='block text-sm font-medium text-gray-300'
                                                    >
                                                        Reference
                                                    </label>
                                                    <div className='mt-1'>
                                                        <input
                                                            onChange={(e) => {
                                                                onChange(e)

                                                                setInvalid(
                                                                    false
                                                                )
                                                            }}
                                                            type='text'
                                                            value={traRef}
                                                            name='traRef'
                                                            id='traRef'
                                                            className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='sm:col-span-4'>
                                                    <label
                                                        htmlFor='notes'
                                                        className='block text-sm font-medium text-gray-300'
                                                    >
                                                        Notes
                                                    </label>
                                                    <div className='mt-1'>
                                                        <input
                                                            onChange={(e) => {
                                                                onChange(e)

                                                                setInvalid(
                                                                    false
                                                                )
                                                            }}
                                                            type='text'
                                                            value={notes}
                                                            name='notes'
                                                            id='notes'
                                                            className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='pt-5'>
                                        <div className='flex justify-end'>
                                            <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        if (
                                                            amount === 0 ||
                                                            isNaN(amount) ||
                                                            String(amount) ===
                                                                "" ||
                                                            amount < 0 ||
                                                            (tranFields?.includes(
                                                                "P"
                                                            ) &&
                                                                proID === "") ||
                                                            (tranFields?.includes(
                                                                "I"
                                                            ) &&
                                                                invID === "") ||
                                                            (tranFields?.includes(
                                                                "S"
                                                            ) &&
                                                                spvID === "") ||
                                                            traSubType === "" ||
                                                            isInTheFuture(
                                                                date
                                                            ) ||
                                                            (tranFields?.includes(
                                                                "D"
                                                            ) &&
                                                                (String(
                                                                    deduction
                                                                ) === "" ||
                                                                    deductionDisplay ===
                                                                        "" ||
                                                                    isNaN(
                                                                        deduction
                                                                    )))
                                                        ) {
                                                            setInvalid(true)
                                                        } else {
                                                            setInvalid(false)

                                                            onSubmit()
                                                        }
                                                    }}
                                                    type='button'
                                                    className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                >
                                                    <span>Continue</span>
                                                    <ChevronRightIcon
                                                        className='h-5 w-5'
                                                        aria-hidden='true'
                                                    />
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        ) : tranFormState === 3 ? (
                            <div className=''>
                                <div className=' mt-7 sm:flex sm:items-center'>
                                    <div className=''>
                                        <h3 className='text-lg leading-6 font-medium text-gray-200'>
                                            Accounts Transaction Entry Preview
                                        </h3>
                                        <p className='mt-1 text-sm text-gray-300'>
                                            Review and confirm the transaction
                                            against the listed accounts
                                        </p>
                                    </div>
                                </div>
                                <div className='mt-8 flex flex-col '>
                                    <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8 '>
                                        <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 h-full w-full'>
                                            {saving ? (
                                                <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                                                    <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                                                        <svg
                                                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            fill='none'
                                                            viewBox='0 0 24 24'
                                                        >
                                                            <circle
                                                                className='opacity-25'
                                                                cx='12'
                                                                cy='12'
                                                                r='10'
                                                                stroke='currentColor'
                                                                strokeWidth='4'
                                                            ></circle>
                                                            <path
                                                                className='opacity-75'
                                                                fill='currentColor'
                                                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                            ></path>
                                                        </svg>
                                                        Saving ...
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className=' shadow ring-1 ring-black ring-opacity-5 h-[25rem] w-12/12 overflow-y-auto'>
                                                    <table className=' table-fixed min-w-full overflow-y-auto'>
                                                        <thead className='bg-gray-900'>
                                                            <tr>
                                                                <th
                                                                    scope='col'
                                                                    className='sticky top-0 z-10 border-b  bg-gray-900 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-100 sm:pl-6'
                                                                >
                                                                    Nature of
                                                                    Transaction
                                                                </th>
                                                                <th
                                                                    scope='col'
                                                                    className='sticky top-0 z-10 border-b  bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-100'
                                                                >
                                                                    Date
                                                                </th>
                                                                <th
                                                                    scope='col'
                                                                    className='sticky top-0 z-10 border-b  bg-gray-900 bg-opacity-75 whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-100'
                                                                >
                                                                    Property ID
                                                                </th>
                                                                <th
                                                                    scope='col'
                                                                    className='sticky top-0 z-10 border-b  bg-gray-900 bg-opacity-75 whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-100'
                                                                >
                                                                    Investor ID
                                                                </th>
                                                                {/* <th
                                                                    scope='col'
                                                                    className='sticky top-0 z-10 border-b  bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-100'
                                                                >
                                                                    Split
                                                                </th> */}
                                                                <th
                                                                    scope='col'
                                                                    className='sticky top-0 z-10 border-b  bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-100'
                                                                >
                                                                    Debit
                                                                </th>
                                                                <th
                                                                    scope='col'
                                                                    className='sticky top-0 z-10 border-b  bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-100'
                                                                >
                                                                    Credit
                                                                </th>
                                                                <th
                                                                    scope='col'
                                                                    className='sticky top-0 z-10 border-b  bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-100'
                                                                >
                                                                    Type
                                                                </th>
                                                                <th
                                                                    scope='col'
                                                                    className='sticky top-0 z-10 border-b  bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-100'
                                                                >
                                                                    Reference
                                                                </th>
                                                                <th
                                                                    scope='col'
                                                                    className='sticky top-0 z-10 border-b  bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-100'
                                                                >
                                                                    Notes
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='bg-gray-700'>
                                                            {transactions.map(
                                                                (
                                                                    transaction
                                                                ) => (
                                                                    <Fragment
                                                                        key={Math.random()}
                                                                    >
                                                                        <tr className='border-b border-yellow-400'>
                                                                            <th
                                                                                colSpan={
                                                                                    10
                                                                                }
                                                                                scope='colgroup'
                                                                                className='bg-gray-800 px-4 py-2 text-left text-sm font-semibold text-yellow-400 sm:px-6'
                                                                            >
                                                                                {
                                                                                    transaction.name
                                                                                }
                                                                            </th>
                                                                        </tr>
                                                                        {transaction.record.map(
                                                                            (
                                                                                record,
                                                                                recordIdx
                                                                            ) => (
                                                                                <tr
                                                                                    key={
                                                                                        record.not
                                                                                    }
                                                                                    // className={classNames(
                                                                                    //     recordIdx ===
                                                                                    //         0
                                                                                    //         ? "border-blue-400"
                                                                                    //         : "border-blue-600",
                                                                                    //     "border-t"
                                                                                    // )}
                                                                                >
                                                                                    <td className='whitespace-nowrap py-3 pl-4 text-sm text-gray-100'>
                                                                                        {
                                                                                            record.not
                                                                                        }
                                                                                    </td>
                                                                                    <td className='whitespace-nowrap py-3 pl-4 text-sm text-gray-100'>
                                                                                        {`${record.date.substring(
                                                                                            8,
                                                                                            11
                                                                                        )}-${record.date.substring(
                                                                                            5,
                                                                                            7
                                                                                        )}-${record.date.substring(
                                                                                            0,
                                                                                            4
                                                                                        )}`}
                                                                                    </td>
                                                                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-100'>
                                                                                        {
                                                                                            record.proID
                                                                                        }
                                                                                    </td>
                                                                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-100'>
                                                                                        {
                                                                                            record.invID
                                                                                        }
                                                                                    </td>
                                                                                    {/* <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-100'>
                                                                                        {
                                                                                            record.split
                                                                                        }
                                                                                    </td> */}
                                                                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-100'>
                                                                                        {record.debit ===
                                                                                        0
                                                                                            ? 0
                                                                                            : (
                                                                                                  record.debit /
                                                                                                  100
                                                                                              ).toLocaleString()}
                                                                                    </td>
                                                                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-100'>
                                                                                        {record.credit ===
                                                                                        0
                                                                                            ? 0
                                                                                            : (
                                                                                                  record.credit /
                                                                                                  100
                                                                                              ).toLocaleString()}
                                                                                    </td>
                                                                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-100'>
                                                                                        {
                                                                                            record.traType
                                                                                        }
                                                                                    </td>
                                                                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-100'>
                                                                                        {
                                                                                            record.traRef
                                                                                        }
                                                                                    </td>
                                                                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-100'>
                                                                                        {
                                                                                            record.notes
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </Fragment>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='pt-5 flex justify-end'>
                                        <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                            {saving ? (
                                                ""
                                            ) : (
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault()

                                                        onSave()
                                                    }}
                                                    type='button'
                                                    className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                >
                                                    <span>Submit</span>
                                                    <ChevronRightIcon
                                                        className='h-5 w-5'
                                                        aria-hidden='true'
                                                    />
                                                </button>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ) : tranFormState === 4 ? (
                            <div className=''>
                                <div className='flex h-screen'>
                                    <div className='m-auto'>
                                        <p className='-mt-20 font-bold text-lg text-yellow-400'>
                                            Transaction submitted successfully
                                        </p>
                                        <div className='pt-5 flex justify-center'>
                                            <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault()

                                                        dispatch(
                                                            setTranFormState(0)
                                                        )
                                                        dispatch(
                                                            setTranState("X")
                                                        )
                                                        dispatch(
                                                            resetTranSteps()
                                                        )
                                                        resetForm()
                                                    }}
                                                    type='button'
                                                    className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                >
                                                    <ChevronLeftIcon
                                                        className='h-5 w-5'
                                                        aria-hidden='true'
                                                    />
                                                    <span>
                                                        Back to Dashboard
                                                    </span>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )
                    ) : tranState === "VT" ? (
                        <TraView />
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={error}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                {`Error ${errorDetails.code}: ${errorDetails.details}`}
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                {errorDetails.message}
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={success}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-green-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <CheckCircleIcon
                                                className='h-6 w-6 text-green-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                Success!
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                Transaction created successfully
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <div className=' rounded-md inline-flex text-green-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                                                {successCountdown > 0
                                                    ? successCountdown
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        </>
    )
}

export default TraMain
