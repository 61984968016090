import { Fragment, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import { RootState } from "../app/store"
import {
    reset,
    setSuccess,
    setSuccessCountdown,
    setSuccessType,
} from "../features/auth/recSlice"

import { supabase } from "../supabaseClient"

import { Transition } from "@headlessui/react"
import {
    XIcon,
    ExclamationCircleIcon,
    ChevronRightIcon,
} from "@heroicons/react/solid"

type SPVS = {
    spvID: string
    invID: string
    shares: number
    stake: number
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const RecSPVSPrev = () => {
    const dispatch = useDispatch()
    const { recFormState, SPVSs, sharePros, shareSPVs } = useSelector(
        (state: RootState) => state.rec
    )
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [errorDetails, setErrorDetails] = useState({
        code: "",
        message: "",
        details: "",
    })

    const onSubmit = async () => {
        SPVSs.forEach((spv) => {
            const proID = shareSPVs.filter((shareSPV) => {
                return shareSPV.spvID === spv.spvID
            })[0]

            const totalShare = sharePros.filter((sharePro) => {
                return sharePro.proID === proID.proID
            })[0]

            const newSPVS = {
                spvID: spv.spvID,
                invID: spv.invID,
                shares: spv.shares,
                stake: spv.shares / (totalShare.shares / 100),
            }
            saveData(newSPVS)
        })
    }

    const saveData = async (newSPVS: SPVS) => {
        setSaving(true)
        const { error } = await supabase
            .from<SPVS>("R_SPVS")
            .insert([newSPVS], { returning: "minimal" })

        setSaving(false)

        if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        } else {
            dispatch(setSuccessType("SPV Shareholding"))
            dispatch(setSuccessCountdown(5))
            dispatch(setSuccess(true))
            dispatch(reset())
        }
    }

    return (
        <>
            <div className='mt-7'>
                <div className='flex flex-row justify-between '>
                    <div className='pt-2 justify-self-start '>
                        <h3 className='text-lg leading-6 font-medium text-gray-200'>
                            SPV Shareholding Preview
                        </h3>
                        <p className='mt-1 max-w-2xl text-sm text-gray-300'>
                            Preview and confirm the new SPV Shareholding details
                        </p>
                    </div>
                    <div className='justify-self-end'></div>
                </div>

                <div className='mt-8 flex flex-col '>
                    <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-auto'>
                        <div className='inline-block min-w-full py-2 align-middle'>
                            <div className='shadow-sm ring-1 ring-black ring-opacity-5'>
                                <table
                                    className='min-w-full border-separate'
                                    style={{ borderSpacing: 0 }}
                                >
                                    <thead className='bg-gray-900'>
                                        <tr>
                                            <th
                                                scope='col'
                                                className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'
                                            >
                                                SPV ID
                                            </th>
                                            <th
                                                scope='col'
                                                className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'
                                            >
                                                Investor ID
                                            </th>
                                            <th
                                                scope='col'
                                                className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:table-cell'
                                            >
                                                Shares
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='bg-gray-700'>
                                        {SPVSs.map(
                                            (singleSPVS, singleSPVSIdx) => (
                                                <tr
                                                    key={`${singleSPVS.spvID}-${singleSPVSIdx}`}
                                                >
                                                    <td
                                                        className={classNames(
                                                            singleSPVSIdx !==
                                                                SPVSs.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6 lg:pl-8"
                                                        )}
                                                    >
                                                        {singleSPVS.spvID}
                                                    </td>

                                                    <td
                                                        className={classNames(
                                                            singleSPVSIdx !==
                                                                SPVSs.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "whitespace-nowrap px-7 py-4 text-sm text-gray-100"
                                                        )}
                                                    >
                                                        {singleSPVS.invID}
                                                    </td>
                                                    <td
                                                        className={classNames(
                                                            singleSPVSIdx !==
                                                                SPVSs.length - 1
                                                                ? "border-b border-gray-200"
                                                                : "",
                                                            "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                        )}
                                                    >
                                                        {Number(
                                                            singleSPVS.shares
                                                        ).toLocaleString()}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pt-5 flex justify-end'>
                    <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                        <button
                            onClick={(e) => {
                                e.preventDefault()

                                onSubmit()
                            }}
                            type='button'
                            className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                        >
                            <span>Submit</span>
                            <ChevronRightIcon
                                className='h-5 w-5'
                                aria-hidden='true'
                            />
                        </button>
                    </span>
                </div>
            </div>
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={error}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                {`Error ${errorDetails.code}: ${errorDetails.details}`}
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                {errorDetails.message}
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        </>
    )
}

export default RecSPVSPrev
