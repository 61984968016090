import { useSelector } from "react-redux"
import { RootState } from "../app/store"

import { useEffect, useState } from "react"

import { supabase } from "../supabaseClient"

type Acc = {
    debit: number
    credit: number
}

type AccCalc = {
    accType: string
    accID: string
    accName: string
    totalDebit: number
    totalCredit: number
}
const accClasses = [
    {
        type: "A",
        id: "A_A_CMA",
        title: "Client Money Account",
    },
    {
        type: "A",
        id: "A_A_RA",
        title: "Rental Account",
    },
    {
        type: "A",
        id: "A_A_SDA",
        title: "Security Deposit Account",
    },
    {
        type: "A",
        id: "A_A_CMWA",
        title: "Client Money Wallet Account",
    },
    {
        type: "A",
        id: "A_A_CA",
        title: "Corporate Account",
    },
    {
        type: "A",
        id: "A_A_P",
        title: "Properties",
    },
    {
        type: "E",
        id: "A_E_SC",
        title: "Share Capital",
    },
    // {
    //     type: "E",
    //     id: "A_E_RE",
    //     title: "Retained Earnings",
    // },
    {
        type: "E",
        id: "A_E_R",
        title: "Reserve",
    },
    {
        type: "L",
        id: "A_L_IWL",
        title: "Investor Wallet (Locked)",
    },
    {
        type: "L",
        id: "A_L_IWO",
        title: "Investor Wallet (Open)",
    },
    {
        type: "L",
        id: "A_L_BI",
        title: "Baytuki Income",
    },
    {
        type: "L",
        id: "A_L_OL",
        title: "Other Liability",
    },
]

const CtrPanel = () => {
    const { nav } = useSelector((state: RootState) => state.app)
    const [ctrData, setCrtData] = useState<AccCalc[]>([])
    const [loading, setLoading] = useState(false)

    const getData = async () => {
        setLoading(true)
        setCrtData([])
        accClasses.forEach(async (acc) => {
            const { data, error } = await supabase.from<Acc>(acc.id).select()

            if (data) {
                setCrtData((prev) => [
                    ...prev,
                    {
                        accType: acc.type,
                        accID: acc.id,
                        accName: acc.title,
                        totalDebit:
                            data.reduce(
                                (parSum, recAcc) => parSum + recAcc.debit,
                                0
                            ) / 100,
                        totalCredit:
                            data.reduce(
                                (parSum, recAcc) => parSum + recAcc.credit,
                                0
                            ) / 100,
                    },
                ])
                setLoading(false)
            } else if (error) {
                // setError(true)
                // setErrorDetails({
                //     code: error.code,
                //     message: error.message,
                //     details: error.details,
                // })
            }
        })
    }

    useEffect(() => {
        if (nav === "Control Panel") {
            getData()
        }
    }, [nav])

    return (
        <div className='flex flex-col content-evenly bg-gray-800 h-full'>
            <div className='content-start mx-10 mt-5'></div>
            <div className=' content-start mx-10 mt-10 overflow-auto '>
                {loading ? (
                    <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                        <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                            <svg
                                className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                            >
                                <circle
                                    className='opacity-25'
                                    cx='12'
                                    cy='12'
                                    r='10'
                                    stroke='currentColor'
                                    strokeWidth='4'
                                ></circle>
                                <path
                                    className='opacity-75'
                                    fill='currentColor'
                                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                ></path>
                            </svg>
                            Loading ...
                        </div>
                    </div>
                ) : (
                    <div className=''>
                        <h2 className='text-lg font-medium text-gray-200'>
                            Control Panel
                        </h2>
                        <div className='text-gray-100 text-lg pl-4 text-center w-2/3 bg-green-800 mt-6 '>
                            Assets
                        </div>
                        <table className='w-2/3 divide-y divide-white table-fixed'>
                            <tbody className='divide-y divide-white bg-gray-900 text-gray-300 text-right'>
                                <tr
                                    key={"Total assets"}
                                    className='font-semibold text-gray-100'
                                >
                                    <td className=' whitespace-nowrap py-4 pl-4 pr-3 w-1/3'>
                                        Account
                                    </td>
                                    <td className='whitespace-nowrap text-right py-4 pl-4 pr-3 sm:pl-6'>
                                        Total Debit
                                    </td>
                                    <td className='whitespace-nowrap text-right py-4 pl-4 pr-3  sm:pl-6'>
                                        Total Credit
                                    </td>
                                    <td className='whitespace-nowrap text-right py-4 pl-4 pr-3 sm:pl-6'>
                                        Balance
                                    </td>
                                </tr>
                                {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_A_CMA"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_A_CMA"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CMA"
                                                    )
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CMA"
                                                    )
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CMA"
                                                    )
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CMA"
                                                    )
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CMA"
                                                    )
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )}
                                {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_A_SDA"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_A_SDA"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_SDA"
                                                    )
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_SDA"
                                                    )
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_SDA"
                                                    )
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_SDA"
                                                    )
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_SDA"
                                                    )
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )}
                                {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_A_RA"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_A_RA"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_RA"
                                                    )
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_RA"
                                                    )
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_RA"
                                                    )
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_RA"
                                                    )
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_RA"
                                                    )
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )}
                                {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_A_CMWA"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_A_CMWA"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CMWA"
                                                    )
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CMWA"
                                                    )
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CMWA"
                                                    )
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CMWA"
                                                    )
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CMWA"
                                                    )
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )}
                                {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_A_CA"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_A_CA"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CA"
                                                    )
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CA"
                                                    )
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CA"
                                                    )
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CA"
                                                    )
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_A_CA"
                                                    )
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )}
                                {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_A_P"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_A_P"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return ctr.accID === "A_A_P"
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return ctr.accID === "A_A_P"
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return ctr.accID === "A_A_P"
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return ctr.accID === "A_A_P"
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return ctr.accID === "A_A_P"
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )}

                                <tr
                                    key={"Calculation Assets"}
                                    className='font-semibold text-gray-100'
                                >
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                        Total Assets
                                    </td>
                                    <td className='whitespace-nowrap text-right py-4 pl-4 pr-3 sm:pl-6'>
                                        {ctrData
                                            .filter((ctr) => {
                                                return ctr.accType === "A"
                                            })
                                            .reduce(
                                                (parSum, ctr) =>
                                                    parSum + ctr.totalDebit,
                                                0
                                            )
                                            .toLocaleString()}
                                    </td>
                                    <td className='whitespace-nowrap text-right py-4 pl-4 pr-3  sm:pl-6'>
                                        {ctrData
                                            .filter((ctr) => {
                                                return ctr.accType === "A"
                                            })
                                            .reduce(
                                                (parSum, ctr) =>
                                                    parSum + ctr.totalCredit,
                                                0
                                            )
                                            .toLocaleString()}
                                    </td>
                                    <td className='whitespace-nowrap text-right py-4 pl-4 pr-3 sm:pl-6'>
                                        {(
                                            ctrData
                                                .filter((ctr) => {
                                                    return ctr.accType === "A"
                                                })
                                                .reduce(
                                                    (parSum, ctr) =>
                                                        parSum + ctr.totalDebit,
                                                    0
                                                ) -
                                            ctrData
                                                .filter((ctr) => {
                                                    return ctr.accType === "A"
                                                })
                                                .reduce(
                                                    (parSum, ctr) =>
                                                        parSum +
                                                        ctr.totalCredit,
                                                    0
                                                )
                                        ).toLocaleString()}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='text-gray-100 text-lg pl-4 text-center w-2/3 bg-red-800 mt-6 '>
                            Liabilities
                        </div>
                        <table className='w-2/3 divide-y divide-white  table-fixed'>
                            <tbody className='w-full divide-y divide-white bg-gray-900 text-gray-300 text-right'>
                                <tr
                                    key={"Total liabilities"}
                                    className='font-semibold text-gray-100'
                                >
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3 w-1/3'>
                                        Account
                                    </td>
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                        Total Debit
                                    </td>
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                        Total Credit
                                    </td>
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                        Balance
                                    </td>
                                </tr>
                                {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_L_IWL"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_L_IWL"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_IWL"
                                                    )
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_IWL"
                                                    )
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_IWL"
                                                    )
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_IWL"
                                                    )
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_IWL"
                                                    )
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )}
                                {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_L_IWO"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_L_IWO"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_IWO"
                                                    )
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_IWO"
                                                    )
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_IWO"
                                                    )
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_IWO"
                                                    )
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_IWO"
                                                    )
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )}
                                {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_L_BI"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_L_BI"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_BI"
                                                    )
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_BI"
                                                    )
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_BI"
                                                    )
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_BI"
                                                    )
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_BI"
                                                    )
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )}
                                {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_L_OL"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_L_OL"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_OL"
                                                    )
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_OL"
                                                    )
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_OL"
                                                    )
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_OL"
                                                    )
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_L_OL"
                                                    )
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )}

                                <tr
                                    key={"Calculation Liabilites"}
                                    className='font-semibold text-gray-100'
                                >
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 w-32'>
                                        Total Liabilities
                                    </td>
                                    <td className='whitespace-nowrap text-right py-4 pl-4 pr-3 sm:pl-6'>
                                        {ctrData
                                            .filter((ctr) => {
                                                return ctr.accType === "L"
                                            })
                                            .reduce(
                                                (parSum, ctr) =>
                                                    parSum + ctr.totalDebit,
                                                0
                                            )
                                            .toLocaleString()}
                                    </td>
                                    <td className='whitespace-nowrap text-right py-4 pl-4 pr-3  sm:pl-6'>
                                        {ctrData
                                            .filter((ctr) => {
                                                return ctr.accType === "L"
                                            })
                                            .reduce(
                                                (parSum, ctr) =>
                                                    parSum + ctr.totalCredit,
                                                0
                                            )
                                            .toLocaleString()}
                                    </td>
                                    <td className='whitespace-nowrap text-right py-4 pl-4 pr-3 sm:pl-6'>
                                        {(
                                            ctrData
                                                .filter((ctr) => {
                                                    return ctr.accType === "L"
                                                })
                                                .reduce(
                                                    (parSum, ctr) =>
                                                        parSum + ctr.totalDebit,
                                                    0
                                                ) -
                                            ctrData
                                                .filter((ctr) => {
                                                    return ctr.accType === "L"
                                                })
                                                .reduce(
                                                    (parSum, ctr) =>
                                                        parSum +
                                                        ctr.totalCredit,
                                                    0
                                                )
                                        ).toLocaleString()}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='text-gray-100 text-lg pl-4 text-center w-2/3 bg-blue-800 mt-6 '>
                            Equity
                        </div>
                        <table className='w-2/3 divide-y divide-white  mb-10 table-fixed'>
                            <tbody className='divide-y divide-white bg-gray-900 text-gray-300 text-right'>
                                <tr
                                    key={"Total equity"}
                                    className='font-semibold text-gray-100'
                                >
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 w-1/3'>
                                        Account
                                    </td>
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                        Total Debit
                                    </td>
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                        Total Credit
                                    </td>
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                        Balance
                                    </td>
                                </tr>
                                {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_E_SC"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_E_SC"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_E_SC"
                                                    )
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_E_SC"
                                                    )
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_E_SC"
                                                    )
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_E_SC"
                                                    )
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_E_SC"
                                                    )
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )}
                                {/* {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_E_RE"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_E_RE"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_E_RE"
                                                    )
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_E_RE"
                                                    )
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_E_RE"
                                                    )
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_E_RE"
                                                    )
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return (
                                                        ctr.accID === "A_E_RE"
                                                    )
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )} */}
                                {ctrData.filter((ctr) => {
                                    return ctr.accID === "A_E_R"
                                })[0] !== undefined && (
                                    <tr
                                        key={
                                            ctrData.filter((ctr) => {
                                                return ctr.accID === "A_E_R"
                                            })[0].accID
                                        }
                                        className='bg-gray-700 text-gray-100'
                                    >
                                        <td className='bg-gray-900 text-gray-100 whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {
                                                ctrData.filter((ctr) => {
                                                    return ctr.accID === "A_E_R"
                                                })[0].accName
                                            }
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return ctr.accID === "A_E_R"
                                                })[0]
                                                .totalDebit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                            {ctrData
                                                .filter((ctr) => {
                                                    return ctr.accID === "A_E_R"
                                                })[0]
                                                .totalCredit.toLocaleString()}
                                        </td>
                                        <td className='whitespace-nowrap py-4 pl-4 pr-3  sm:pl-6'>
                                            {(
                                                ctrData.filter((ctr) => {
                                                    return ctr.accID === "A_E_R"
                                                })[0].totalDebit -
                                                ctrData.filter((ctr) => {
                                                    return ctr.accID === "A_E_R"
                                                })[0].totalCredit
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                )}

                                <tr
                                    key={"Calculation Equity"}
                                    className='font-semibold text-gray-100'
                                >
                                    <td className='whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6'>
                                        Total Equity
                                    </td>
                                    <td className='whitespace-nowrap text-right py-4 pl-4 pr-3 sm:pl-6'>
                                        {ctrData
                                            .filter((ctr) => {
                                                return ctr.accType === "E"
                                            })
                                            .reduce(
                                                (parSum, ctr) =>
                                                    parSum + ctr.totalDebit,
                                                0
                                            )
                                            .toLocaleString()}
                                    </td>
                                    <td className='whitespace-nowrap text-right py-4 pl-4 pr-3  sm:pl-6'>
                                        {ctrData
                                            .filter((ctr) => {
                                                return ctr.accType === "E"
                                            })
                                            .reduce(
                                                (parSum, ctr) =>
                                                    parSum + ctr.totalCredit,
                                                0
                                            )
                                            .toLocaleString()}
                                    </td>
                                    <td className='whitespace-nowrap text-right py-4 pl-4 pr-3 sm:pl-6'>
                                        {(
                                            ctrData
                                                .filter((ctr) => {
                                                    return ctr.accType === "E"
                                                })
                                                .reduce(
                                                    (parSum, ctr) =>
                                                        parSum + ctr.totalDebit,
                                                    0
                                                ) -
                                            ctrData
                                                .filter((ctr) => {
                                                    return ctr.accType === "E"
                                                })
                                                .reduce(
                                                    (parSum, ctr) =>
                                                        parSum +
                                                        ctr.totalCredit,
                                                    0
                                                )
                                        ).toLocaleString()}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='flex flex-row w-2/3 mt-6 mb-20 font-semibold'>
                            <div className=' text-gray-100 text-lg pl-4 py-3 text-right pr-3 w-1/3 bg-gray-900 '>
                                Total Balance
                            </div>
                            <div className='text-gray-100 text-lg pl-4 py-3 text-right w-2/3 bg-teal-600 pr-3 '>
                                {(
                                    ctrData
                                        .filter((ctr) => {
                                            return ctr.accType === "A"
                                        })
                                        .reduce(
                                            (parSum, ctr) =>
                                                parSum + ctr.totalDebit,
                                            0
                                        ) -
                                    ctrData
                                        .filter((ctr) => {
                                            return ctr.accType === "A"
                                        })
                                        .reduce(
                                            (parSum, ctr) =>
                                                parSum + ctr.totalCredit,
                                            0
                                        ) +
                                    ctrData
                                        .filter((ctr) => {
                                            return ctr.accType === "L"
                                        })
                                        .reduce(
                                            (parSum, ctr) =>
                                                parSum + ctr.totalDebit,
                                            0
                                        ) -
                                    ctrData
                                        .filter((ctr) => {
                                            return ctr.accType === "L"
                                        })
                                        .reduce(
                                            (parSum, ctr) =>
                                                parSum + ctr.totalCredit,
                                            0
                                        ) +
                                    ctrData
                                        .filter((ctr) => {
                                            return ctr.accType === "E"
                                        })
                                        .reduce(
                                            (parSum, ctr) =>
                                                parSum + ctr.totalDebit,
                                            0
                                        ) -
                                    ctrData
                                        .filter((ctr) => {
                                            return ctr.accType === "E"
                                        })
                                        .reduce(
                                            (parSum, ctr) =>
                                                parSum + ctr.totalCredit,
                                            0
                                        )
                                ).toLocaleString()}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CtrPanel
