import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../app/store"
import { setTranSteps } from "../features/auth/traSlice"

import { CheckIcon } from "@heroicons/react/solid"

const TraSteps = () => {
    const dispatch = useDispatch()
    const { tranSteps, tranFormState } = useSelector(
        (state: RootState) => state.tran
    )

    useEffect(() => {
        const newSteps = tranSteps.map((step) => ({ ...step }))

        if (tranFormState === 0) {
            newSteps[tranFormState].status = "current"
            newSteps[tranFormState + 1].status = "upcoming"
        } else if (tranFormState === 4) {
            newSteps[tranFormState - 1].status = "complete"
            newSteps[tranFormState].status = "current"
        } else {
            newSteps[tranFormState - 1].status = "complete"
            newSteps[tranFormState].status = "current"
            newSteps[tranFormState + 1].status = "upcoming"
        }
        dispatch(setTranSteps(newSteps))
    }, [tranFormState])

    return (
        <nav aria-label='Progress' className=''>
            <ol className='border border-yellow-400 rounded-md divide-y divide-yellow-400 md:flex md:divide-y-0'>
                {tranSteps.map((step, stepIdx) => (
                    <li key={step.name} className='relative md:flex-1 md:flex'>
                        {step.status === "complete" ? (
                            <div className='group flex items-center w-full'>
                                <span className='px-6 py-4 flex items-center text-sm font-medium'>
                                    <span className='flex-shrink-0 w-10 h-10 flex items-center justify-center bg-blue-600 rounded-full group-hover:bg-indigo-800'>
                                        <CheckIcon
                                            className='w-6 h-6 text-white'
                                            aria-hidden='true'
                                        />
                                    </span>
                                    <span className='ml-4 text-sm font-medium text-blue-500'>
                                        {step.name}
                                    </span>
                                </span>
                            </div>
                        ) : step.status === "current" ? (
                            <div
                                className='px-6 py-4 flex items-center text-sm font-medium'
                                aria-current='step'
                            >
                                <span className='flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-yellow-400 rounded-full'>
                                    <span className='text-yellow-400'>
                                        {step.id + 1}
                                    </span>
                                </span>
                                <span className='ml-4 text-sm font-medium text-yellow-400'>
                                    {step.name}
                                </span>
                            </div>
                        ) : (
                            <div className='group flex items-center'>
                                <span className='px-6 py-4 flex items-center text-sm font-medium'>
                                    <span className='flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full '>
                                        <span className='text-gray-300 '>
                                            {step.id + 1}
                                        </span>
                                    </span>
                                    <span className='ml-4 text-sm font-medium text-gray-300 '>
                                        {step.name}
                                    </span>
                                </span>
                            </div>
                        )}

                        {stepIdx !== tranSteps.length - 1 ? (
                            <>
                                {/* Arrow separator for lg screens and up */}
                                <div
                                    className='hidden md:block absolute top-0 right-0 h-full w-5'
                                    aria-hidden='true'
                                >
                                    <svg
                                        className='h-full w-full text-yellow-400'
                                        viewBox='0 0 22 80'
                                        fill='none'
                                        preserveAspectRatio='none'
                                    >
                                        <path
                                            d='M0 -2L20 40L0 82'
                                            vectorEffect='non-scaling-stroke'
                                            stroke='currentcolor'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                </div>
                            </>
                        ) : null}
                    </li>
                ))}
            </ol>
        </nav>
    )
}

export default TraSteps
