import { Fragment, useState, useEffect, ChangeEvent } from "react"
import { useSelector } from "react-redux"

import { RootState } from "../app/store"

import { supabase } from "../supabaseClient"

import { Transition, Dialog } from "@headlessui/react"

import { utils, writeFile } from "xlsx"

import { XIcon, ExclamationCircleIcon } from "@heroicons/react/solid"
import {
    RefreshIcon,
    PencilAltIcon,
    DocumentDownloadIcon,
} from "@heroicons/react/outline"

type Inv = {
    invID: string
    title: string
    fore: string
    sur: string
    email: string
    mobile: string
    dob: string
    occupation: string
    bracket: string
    risk: string
    created_at: string
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const isInTheFuture = (givenDate: String) => {
    const now = new Date()

    now.setHours(23, 59, 59, 998)

    return (
        new Date(
            `${givenDate.substring(0, 4)}-${givenDate.substring(
                4,
                6
            )}-${givenDate.substring(6, 8)}`
        ) > now
    )
}

const RecInvView = () => {
    const { recFormState, searchTermInv } = useSelector(
        (state: RootState) => state.rec
    )
    const { role } = useSelector((state: RootState) => state.auth)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [edit, setEdit] = useState(false)
    const [saving, setSaving] = useState(false)
    const [errorDetails, setErrorDetails] = useState({
        code: "",
        message: "",
        details: "",
    })

    const [invs, setInvs] = useState<Inv[]>([])
    const [invsResult, setInvsResult] = useState<Inv[]>([])

    const [invData, setInvData] = useState<Inv>({
        invID: "",
        title: "",
        fore: "",
        sur: "",
        email: "",
        mobile: "",
        dob: "",
        occupation: "",
        bracket: "",
        risk: "",
        created_at: "",
    })
    const [invalid, setInvalid] = useState(false)

    const {
        invID,
        title,
        fore,
        sur,
        email,
        mobile,
        dob,
        occupation,
        bracket,
        risk,
    } = invData

    const getInvs = async () => {
        setInvs([])
        setLoading(true)
        setError(false)
        const { data, error } = await supabase
            .from<Inv>("R_Inv")
            .select()
            .order("created_at", { ascending: false })
        if (data) {
            setInvs(data)
            setInvsResult(data)
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }

        setLoading(false)
    }

    useEffect(() => {
        getInvs()
    }, [recFormState])

    useEffect(() => {
        if (searchTermInv === "") {
            setInvsResult(invs)
        } else {
            setInvsResult(
                invs.filter((recInv) => {
                    return (
                        recInv.invID
                            .toLowerCase()
                            .includes(searchTermInv.toLowerCase()) ||
                        recInv.dob
                            .toLowerCase()
                            .includes(searchTermInv.toLowerCase()) ||
                        recInv.email
                            .toLowerCase()
                            .includes(searchTermInv.toLowerCase()) ||
                        recInv.risk
                            .toLowerCase()
                            .includes(searchTermInv.toLowerCase()) ||
                        recInv.fore
                            .toLowerCase()
                            .includes(searchTermInv.toLowerCase()) ||
                        recInv.sur
                            .toLowerCase()
                            .includes(searchTermInv.toLowerCase()) ||
                        recInv.mobile
                            .toLowerCase()
                            .includes(searchTermInv.toLowerCase()) ||
                        recInv.bracket
                            .toLowerCase()
                            .includes(searchTermInv.toLowerCase())
                    )
                })
            )
        }
    }, [searchTermInv])

    const onSubmit = async () => {
        if (
            invID === "" ||
            title === "" ||
            fore === "" ||
            sur === "" ||
            email === "" ||
            !email.includes("@") ||
            !email.includes(".") ||
            email.substring(email.lastIndexOf(".")).length < 3 ||
            mobile === "" ||
            isNaN(parseInt(mobile)) ||
            dob === "" ||
            isInTheFuture(dob) ||
            occupation === "" ||
            bracket === "" ||
            risk === ""
        ) {
            setInvalid(true)
        } else {
            setInvalid(false)
            setSaving(true)
            const { error } = await supabase
                .from<Inv>("R_Inv")
                .update(
                    {
                        title: title,
                        fore: fore,
                        sur: sur,
                        email: email,
                        mobile: mobile,
                        dob: dob,
                        occupation: occupation,
                        bracket: bracket,
                        risk: risk,
                    },
                    { returning: "minimal" }
                )
                .match({ invID: invID })

            setSaving(false)
            if (error) {
                setError(true)
                setErrorDetails({
                    code: error.code,
                    message: error.message,
                    details: error.details,
                })
            } else {
                getInvs()
                setEdit(false)
            }
        }
    }

    const onChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => {
        if (e.target.name === "dob") {
            setInvData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        } else {
            setInvData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        }
    }

    const xlsxExprt = () => {
        const today = new Date()
        const worksheet = utils.json_to_sheet(invs)
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, "Investors")
        writeFile(
            workbook,
            `Investors_${
                today.getDate().toString().length === 1
                    ? "0" + today.getDate().toString()
                    : today.getDate().toString()
            }-${
                today.getMonth().toString().length === 1
                    ? "0" + (today.getMonth() + 1).toString()
                    : (today.getMonth() + 1).toString()
            }-${today.getFullYear()}.xlsx`
        )
    }

    return (
        <>
            {loading ? (
                <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                    <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                        <svg
                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                        >
                            <circle
                                className='opacity-25'
                                cx='12'
                                cy='12'
                                r='10'
                                stroke='currentColor'
                                strokeWidth='4'
                            ></circle>
                            <path
                                className='opacity-75'
                                fill='currentColor'
                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                            ></path>
                        </svg>
                        Loading ...
                    </div>
                </div>
            ) : (
                <div className='mt-7'>
                    <div className='flex flex-row justify-between '>
                        <div className='pt-2 justify-self-start '>
                            <h3 className='text-lg leading-6 font-medium text-gray-200'>
                                Investor List
                            </h3>
                            <p className='mt-1 max-w-2xl text-sm text-gray-300'></p>
                        </div>
                        <div className='justify-self-end'>
                            <div className='flex flex-col'>
                                <span className='relative z-0 inline-flex shadow-sm rounded-md '>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            getInvs()
                                        }}
                                        type='button'
                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <RefreshIcon
                                            className='h-5 w-5 pr-1'
                                            aria-hidden='true'
                                        />
                                        <span>Refresh</span>
                                    </button>
                                </span>
                                <span className='mt-6 relative z-0 inline-flex shadow-sm rounded-md '>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            xlsxExprt()
                                        }}
                                        type='button'
                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <DocumentDownloadIcon
                                            className='h-5 w-5 pr-1'
                                            aria-hidden='true'
                                        />
                                        <span>Excel File Exprt</span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='mx-8 mt-8 flex flex-col '>
                        <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-auto'>
                            <div className='inline-block min-w-full py-2 align-middle'>
                                <div className='shadow-sm ring-1 ring-black ring-opacity-5 max-h-[43rem]'>
                                    <table
                                        className='min-w-full border-separate'
                                        style={{ borderSpacing: 0 }}
                                    >
                                        <thead className='bg-gray-900'>
                                            <tr>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'
                                                >
                                                    Investor ID
                                                </th>
                                                {role.rec_e ? (
                                                    <th
                                                        scope='col'
                                                        className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filtersm:pr-6 lg:pr-8'
                                                    >
                                                        <span className='sr-only'>
                                                            Edit
                                                        </span>
                                                    </th>
                                                ) : (
                                                    ""
                                                )}
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'
                                                >
                                                    Title
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:table-cell'
                                                >
                                                    Forename
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter lg:table-cell'
                                                >
                                                    Surname
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Email
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Mobile
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Date of Birth
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Occupation
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Income Bracket
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Risk Profile
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='bg-gray-700'>
                                            {invsResult.map(
                                                (recInv, recInvIdx) => (
                                                    <tr key={recInv.invID}>
                                                        <td
                                                            className={classNames(
                                                                recInvIdx !==
                                                                    invs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6 lg:pl-8"
                                                            )}
                                                        >
                                                            {recInv.invID}
                                                        </td>
                                                        {role.rec_e ? (
                                                            <td
                                                                className={classNames(
                                                                    recInvIdx !==
                                                                        invs.length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                                                                )}
                                                            >
                                                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                                                    <button
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault()
                                                                            setInvData(
                                                                                recInv
                                                                            )

                                                                            setEdit(
                                                                                true
                                                                            )
                                                                        }}
                                                                        type='button'
                                                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                                    >
                                                                        <PencilAltIcon
                                                                            className='h-5 w-5 pr-1'
                                                                            aria-hidden='true'
                                                                        />
                                                                        <span>
                                                                            Edit
                                                                        </span>
                                                                    </button>
                                                                </span>
                                                            </td>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <td
                                                            className={classNames(
                                                                recInvIdx !==
                                                                    invs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recInv.title}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recInvIdx !==
                                                                    invs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recInv.fore}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recInvIdx !==
                                                                    invs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recInv.sur}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recInvIdx !==
                                                                    invs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recInv.email}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recInvIdx !==
                                                                    invs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recInv.mobile}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recInvIdx !==
                                                                    invs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {`${recInv.dob.substring(
                                                                8,
                                                                11
                                                            )}-${recInv.dob.substring(
                                                                5,
                                                                7
                                                            )}-${recInv.dob.substring(
                                                                0,
                                                                4
                                                            )}`}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recInvIdx !==
                                                                    invs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recInv.occupation}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recInvIdx !==
                                                                    invs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recInv.bracket}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recInvIdx !==
                                                                    invs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recInv.risk}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className='z-20 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={error}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                {`Error ${errorDetails.code}: ${errorDetails.details}`}
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                {errorDetails.message}
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
            <Transition.Root show={edit} as={Fragment}>
                <Dialog as='div' className='relative z-10' onClose={setEdit}>
                    <div className='fixed inset-0' />

                    <div className='fixed inset-0 overflow-hidden'>
                        <div className='absolute inset-0 overflow-hidden'>
                            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='transform transition ease-in-out duration-500 sm:duration-700'
                                    enterFrom='translate-x-full'
                                    enterTo='translate-x-0'
                                    leave='transform transition ease-in-out duration-500 sm:duration-700'
                                    leaveFrom='translate-x-0'
                                    leaveTo='translate-x-full'
                                >
                                    <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                                        <form className='flex h-full flex-col divide-y divide-gray-200 bg-gray-900 shadow-xl'>
                                            <div className='h-0 flex-1 overflow-y-auto'>
                                                <div className='bg-yellow-500 py-6 px-4 sm:px-6'>
                                                    <div className='flex items-center justify-between'>
                                                        <Dialog.Title className='text-lg font-medium text-gray-900'>
                                                            {`Editing Investor ${invID}`}
                                                        </Dialog.Title>
                                                        <div className='ml-3 flex h-7 items-center'></div>
                                                    </div>
                                                    {/* <div className='mt-1'>
                                                        <p className='text-sm text-indigo-300'>
                                                            Get started by
                                                            filling in the
                                                            information below to
                                                            create your new
                                                            project.
                                                        </p>
                                                    </div> */}
                                                </div>
                                                {saving ? (
                                                    <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                                                        <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                                                            <svg
                                                                className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                fill='none'
                                                                viewBox='0 0 24 24'
                                                            >
                                                                <circle
                                                                    className='opacity-25'
                                                                    cx='12'
                                                                    cy='12'
                                                                    r='10'
                                                                    stroke='currentColor'
                                                                    strokeWidth='4'
                                                                ></circle>
                                                                <path
                                                                    className='opacity-75'
                                                                    fill='currentColor'
                                                                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                                ></path>
                                                            </svg>
                                                            Saving ...
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='m-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-1'>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='title'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Title
                                                            </label>
                                                            <div className='mt-1'>
                                                                <select
                                                                    value={
                                                                        title
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    id='title'
                                                                    name='title'
                                                                    className='bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md color'
                                                                >
                                                                    <option></option>
                                                                    <option>
                                                                        Sheikh
                                                                    </option>
                                                                    <option>
                                                                        Sheikha
                                                                    </option>
                                                                    <option>
                                                                        Ms
                                                                    </option>
                                                                    <option>
                                                                        Mrs
                                                                    </option>
                                                                    <option>
                                                                        Miss
                                                                    </option>
                                                                    <option>
                                                                        Mr
                                                                    </option>
                                                                    <option>
                                                                        Dr
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            {invalid &&
                                                            title === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Investor's
                                                                    title is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='fore'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Forename
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={fore}
                                                                    name='fore'
                                                                    id='fore'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            fore === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Investor's
                                                                    forename is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='sur'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Surname
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={sur}
                                                                    name='sur'
                                                                    id='sur'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            sur === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Investor's
                                                                    surename is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='email'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Email
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={
                                                                        email
                                                                    }
                                                                    name='email'
                                                                    id='email'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            (email === "" ||
                                                                !email.includes(
                                                                    "@"
                                                                ) ||
                                                                !email.includes(
                                                                    "."
                                                                ) ||
                                                                email.substring(
                                                                    email.lastIndexOf(
                                                                        "."
                                                                    )
                                                                ).length <
                                                                    3) ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Investor's
                                                                    email is
                                                                    invalid or
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='mobile'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Mobile Number
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={
                                                                        mobile
                                                                    }
                                                                    name='mobile'
                                                                    id='mobile'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            (mobile === "" ||
                                                                isNaN(
                                                                    parseInt(
                                                                        mobile
                                                                    )
                                                                )) ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Investor's
                                                                    mobile
                                                                    number is
                                                                    invalid or
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='dob'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Date of Birth
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )
                                                                    }}
                                                                    value={dob}
                                                                    type='date'
                                                                    name='dob'
                                                                    id='dob'
                                                                    className=' pb-1 appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            (isInTheFuture(
                                                                dob
                                                            ) ||
                                                                dob === "") ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Investor's
                                                                    date of
                                                                    birth cannot
                                                                    be in the
                                                                    future or
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='occupation'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Occupation
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={
                                                                        occupation
                                                                    }
                                                                    name='occupation'
                                                                    id='occupation'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            occupation ===
                                                                "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Investor's
                                                                    occupation
                                                                    is empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='bracket'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Income Bracket
                                                            </label>
                                                            <div className='mt-1'>
                                                                <select
                                                                    value={
                                                                        bracket
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    id='bracket'
                                                                    name='bracket'
                                                                    className='bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md color'
                                                                >
                                                                    <option></option>
                                                                    <option>
                                                                        5,000-9,999
                                                                    </option>
                                                                    <option>
                                                                        10,000-14,999
                                                                    </option>
                                                                    <option>
                                                                        15,000-19,999
                                                                    </option>
                                                                    <option>
                                                                        20,000-24,999
                                                                    </option>
                                                                    <option>
                                                                        25,000-29,999
                                                                    </option>
                                                                    <option>
                                                                        30,000-34,999
                                                                    </option>
                                                                    <option>
                                                                        35,000+
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            {invalid &&
                                                            bracket === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Investor's
                                                                    income
                                                                    bracket is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='risk'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Risk Profile
                                                            </label>
                                                            <div className='mt-1'>
                                                                <select
                                                                    value={risk}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    id='risk'
                                                                    name='risk'
                                                                    className='bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md color'
                                                                >
                                                                    <option></option>
                                                                    <option>
                                                                        Low
                                                                    </option>
                                                                    <option>
                                                                        Medium
                                                                    </option>
                                                                    <option>
                                                                        High
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            {invalid &&
                                                            risk === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Investor's
                                                                    risk profile
                                                                    is empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {saving ? (
                                                ""
                                            ) : (
                                                <div className='flex flex-shrink-0 justify-end px-4 py-4'>
                                                    <button
                                                        type='button'
                                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-gray-200 text-sm font-medium text-gray-900 hover:bg-blue-600 hover:text-gray-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setEdit(false)
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            onSubmit()
                                                        }}
                                                        className='py-2 px-5 ml-5 relatives items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            )}
                                        </form>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default RecInvView
