import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { supabase } from "../../supabaseClient"

type Mtn = {
    maintenance: boolean
    message: boolean
    urgent: boolean
    text: string
}

interface state {
    nav: string
    mtn: {
        maintenance: boolean
        message: boolean
        urgent: boolean
        text: string
    }
    loading: boolean
}

const initialState: state = {
    nav: "",
    mtn: {
        maintenance: false,
        message: false,
        urgent: false,
        text: "",
    },
    loading: false,
}

export const checkMtn = createAsyncThunk(
    "auth/checkMtn",
    async (arg, thunkAPI) => {
        try {
            const { data, error } = await supabase.from<Mtn>("App_Mtn").select()
            if (data) {
                return data
            }
            if (error) {
                return thunkAPI.rejectWithValue(error.message)
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(
                (error as any).description || (error as Error).message
            )
        }
    }
)

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        reset: (state) => {},
        setNav: (state, action) => {
            state.nav = action.payload
        },
        setMtn: (state, action) => {
            state.mtn.maintenance = action.payload.maintenance
            state.mtn.message = action.payload.message
            state.mtn.urgent = action.payload.urgent
            state.mtn.text = action.payload.text
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkMtn.pending, (state) => {
                state.loading = true
            })
            .addCase(checkMtn.fulfilled, (state, action) => {
                if (action.payload) {
                    state.loading = false
                    state.mtn.maintenance = action.payload[0].maintenance
                    state.mtn.message = action.payload[0].message
                    state.mtn.urgent = action.payload[0].urgent
                    state.mtn.text = action.payload[0].text
                }
            })
    },
})

export const { setNav, setMtn } = appSlice.actions
export default appSlice.reducer
