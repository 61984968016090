import { useState, useEffect, Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"

import { RootState } from "../app/store"
import {
    setRecFormState,
    setsharePros,
    setshareSPVs,
    setCSVComplete,
    clearSPVSs,
} from "../features/auth/recSlice"

import { supabase } from "../supabaseClient"
import CSVReader from "./CSVReader"

import { Transition } from "@headlessui/react"
import {
    ChevronRightIcon,
    XIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/solid"

type SPV = {
    spvID: string
    proID: string
}

type SPVS = {
    spvID: string
}

type Pro = {
    proID: string
    shares: number
}

type Inv = {
    invID: string
}

const hasDuplicates = (array: string[]) => {
    var valuesSoFar = Object.create(null)
    for (var i = 0; i < array.length; ++i) {
        var value = array[i]
        if (value in valuesSoFar) {
            return true
        }
        valuesSoFar[value] = true
    }
    return false
}

const RecSPVSForm = () => {
    const dispatch = useDispatch()
    const { recFormState, SPVSs, csvComplete } = useSelector(
        (state: RootState) => state.rec
    )
    const [invalid, setInvalid] = useState(false)
    const [error, setError] = useState(false)
    const [errors, setErrors] = useState<string[]>([])
    const [errorDetails, setErrorDetails] = useState({
        code: "",
        message: "",
        details: "",
    })
    const [verifying, setVerifying] = useState(false)
    const [loading, setLoading] = useState(false)

    const [invs, setInvs] = useState<Inv[]>([])
    const [pros, setPros] = useState<Pro[]>([])
    const [spvs, setSPVs] = useState<SPV[]>([])
    const [spvss, setSPVSs] = useState<string[]>([])

    useEffect(() => {
        if (SPVSs.length !== 0) {
            checkData()
        }
    }, [SPVSs])

    useEffect(() => {
        if (recFormState === 1) {
            getData()
            setErrors([])
            setInvalid(false)
            dispatch(setCSVComplete(false))
            dispatch(clearSPVSs())
        }
    }, [recFormState])

    const getInvs = async () => {
        const { data, error } = await supabase
            .from<Inv>("R_Inv")
            .select("invID")
            .order("invID", { ascending: true })
        if (data) {
            setInvs(data)
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }
    }

    const getPros = async () => {
        const { data, error } = await supabase
            .from<Pro>("R_Pro")
            .select("proID, shares")
            .order("proID", { ascending: true })
        if (data) {
            setPros(data)
            dispatch(setsharePros(data))
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }
    }

    const getSPVs = async () => {
        const { data, error } = await supabase
            .from<SPV>("R_SPV")
            .select("spvID, proID")
            .order("spvID", { ascending: true })
        if (data) {
            setSPVs(data)

            dispatch(setshareSPVs(data))
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }
    }

    const getSPVSs = async () => {
        const { data, error } = await supabase
            .from<SPVS>("R_SPVS")
            .select("spvID")

        if (data) {
            setSPVSs(data.map((dataItem) => dataItem.spvID))
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }
    }

    const getData = async () => {
        setLoading(true)
        setInvs([])
        setPros([])
        setSPVs([])
        setSPVSs([])

        getPros()
        getInvs()
        getSPVs()
        getSPVSs()

        setError(false)

        setLoading(false)
    }

    const checkData = () => {
        setErrors([])
        setInvalid(false)

        if (
            hasDuplicates(
                SPVSs.map((SPVS) => {
                    return SPVS.invID
                })
            )
        ) {
            setInvalid(true)
            setErrors((errors) => [
                ...errors,
                `SPV shareholding contains repeated (duplicate) investors`,
            ])
        }

        const spvIDs = SPVSs.map((recSPV) => {
            return recSPV.spvID
        })
        const uniqueSPVIDs = spvIDs.filter(function (x, i, a) {
            return a.indexOf(x) === i
        })
        uniqueSPVIDs.forEach((spvID) => {
            const property = spvs.filter((recSPV) => {
                return recSPV.spvID === spvID
            })[0]

            if (property) {
                const spvTotalShare = pros.filter((recPro) => {
                    return recPro.proID === property.proID
                })[0].shares
                const entryShares = SPVSs.filter((shareholding) => {
                    if (shareholding.spvID === spvID) {
                        return shareholding
                    }
                })

                const entryTotalShare = entryShares.reduce(
                    (partialSum, a) => partialSum + Number(a.shares),
                    0
                )
                if (entryTotalShare !== spvTotalShare / 100) {
                    setInvalid(true)
                    setErrors((errors) => [
                        ...errors,
                        `Property linked to SPV ID ${spvID} has ${(
                            spvTotalShare / 100
                        ).toLocaleString()} shares but total shares provided is ${entryTotalShare.toLocaleString()}`,
                    ])
                }
            } else {
                setInvalid(true)
                setErrors((errors) => [
                    ...errors,
                    `SPV ID ${spvID} does not have any associated property (verify SPV record before proceeding)`,
                ])
            }
        })
        SPVSs.forEach((shareholding, shareholdingIndx) => {
            if (spvss.includes(shareholding.spvID)) {
                setInvalid(true)
                setErrors((errors) => [
                    ...errors,
                    `Line ${
                        shareholdingIndx + 1
                    }: Shareholding record already exists for SPV (${
                        shareholding.spvID
                    })`,
                ])
            }

            if (
                spvs.filter((spv) => {
                    return spv.spvID === shareholding.spvID
                }).length === 0
            ) {
                setInvalid(true)
                setErrors((errors) => [
                    ...errors,
                    `Line ${shareholdingIndx + 1}: Incorrect SPV ID (${
                        shareholding.spvID
                    })`,
                ])
            }
            if (
                invs.filter((inv) => {
                    return inv.invID === shareholding.invID
                }).length === 0
            ) {
                setInvalid(true)
                setErrors((errors) => [
                    ...errors,
                    `Line ${shareholdingIndx + 1}: Incorrect Investor ID (${
                        shareholding.invID
                    })`,
                ])
            }
        })
    }

    const onSubmit = async () => {
        if (!invalid) {
            dispatch(setRecFormState(recFormState + 1))
            setError(false)
        }
    }

    return (
        <>
            {verifying ? (
                <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                    <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                        <svg
                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                        >
                            <circle
                                className='opacity-25'
                                cx='12'
                                cy='12'
                                r='10'
                                stroke='currentColor'
                                strokeWidth='4'
                            ></circle>
                            <path
                                className='opacity-75'
                                fill='currentColor'
                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                            ></path>
                        </svg>
                        Verifying ...
                    </div>
                </div>
            ) : (
                <div className=''>
                    <form className='mt-7 space-y-8 divide-y divide-gray-200'>
                        <div className='  space-y-8 divide-y divide-gray-200'>
                            <div className=''>
                                <div>
                                    <h3 className='text-lg leading-6 font-medium text-gray-200'>
                                        New SPV Shareholding
                                    </h3>
                                    <p className='mt-1 text-sm text-gray-300'>
                                        Select and upload a comma-separated
                                        values (.csv) file for SPV shareholding
                                        distribution
                                    </p>
                                </div>
                                <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                                    <div className='sm:col-span-6'>
                                        <CSVReader />
                                    </div>
                                    <p className='mt-1 text-md font-semibold text-blue-400 underline'>
                                        <a
                                            href={
                                                process.env.PUBLIC_URL +
                                                "/sampleSPVS.csv"
                                            }
                                            download={"sampleSPVS.csv"}
                                        >
                                            Download template file
                                        </a>
                                    </p>
                                </div>

                                {invalid ? (
                                    <h3 className='mt-6 text-md leading-6 font-medium text-red-500'>
                                        Errors needing rectification:
                                    </h3>
                                ) : (
                                    ""
                                )}

                                <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                                    {invalid
                                        ? errors.map((err, errIndx) => (
                                              <div
                                                  className='sm:col-span-6'
                                                  key={errIndx}
                                              >
                                                  <p className=' text-red-500 text-semibold'>
                                                      {err}
                                                  </p>
                                              </div>
                                          ))
                                        : ""}
                                </div>
                            </div>
                        </div>

                        <div className='pt-5'>
                            <div className='flex justify-end'>
                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                    {csvComplete ? (
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault()
                                                onSubmit()
                                            }}
                                            type='button'
                                            className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                        >
                                            <span>Continue</span>
                                            <ChevronRightIcon
                                                className='h-5 w-5'
                                                aria-hidden='true'
                                            />
                                        </button>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            )}
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={error}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                SPV Shareholding Exists
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                SPV Shareholding{" "}
                                                <span className=' font-bold'>
                                                    {}
                                                </span>{" "}
                                                already exists
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        </>
    )
}

export default RecSPVSForm
