import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../app/store"

import { ShieldCheckIcon } from "@heroicons/react/outline"

import { ChevronLeftIcon } from "@heroicons/react/solid"
import { setSetFormState, setSetState } from "../features/auth/setSlice"

import SetClass from "./SetClass"
import SetRolView from "./SetRolView"

const setDashboard = [
    {
        id: "CR",
        title: "Configure Roles",
        description: "",
        icon: ShieldCheckIcon,
        background: "bg-blue-500",
    },
]

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const AccMain = () => {
    const dispatch = useDispatch()
    const { setFormState, setState } = useSelector(
        (state: RootState) => state.set
    )

    return (
        <div className='flex flex-col content-evenly bg-gray-800 h-full'>
            <div className='content-start mx-10 mt-5'>{/* search bar */}</div>
            <div className=' content-start mx-10 mt-10 '>
                {setFormState > 0 && setFormState < 3 ? (
                    <div className='mb-5'>
                        <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()

                                    dispatch(setSetFormState(setFormState - 1))
                                }}
                                type='button'
                                className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                            >
                                <ChevronLeftIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                />
                                <span>Previous</span>
                            </button>
                        </span>
                    </div>
                ) : setState.id !== "X" && setFormState < 3 ? (
                    <div className='mb-5'>
                        <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()

                                    dispatch(
                                        setSetState({
                                            id: "X",
                                            name: "Dashboard",
                                        })
                                    )
                                }}
                                type='button'
                                className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                            >
                                <ChevronLeftIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                />
                                <span>Previous</span>
                            </button>
                        </span>
                    </div>
                ) : (
                    ""
                )}
                {setState.id === "X" ? (
                    <div className=''>
                        <h2 className='text-lg font-medium text-gray-200'>
                            Settings
                        </h2>
                        <p className='mt-1 text-sm text-gray-300'></p>
                        <ul className='mt-6 border-t border-b border-yellow-400 py-6 grid grid-cols-1 gap-6 sm:grid-cols-4'>
                            {setDashboard.map((item, itemIdx) => (
                                <li key={itemIdx} className='flow-root'>
                                    <div className='relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-slate-600 focus-within:ring-2 focus-within:ring-indigo-500'>
                                        <div
                                            className={classNames(
                                                item.background,
                                                "flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg"
                                            )}
                                        >
                                            <item.icon
                                                className='h-6 w-6 text-white'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div>
                                            <h3 className='text-sm font-medium text-gray-200 hover:text-gray-100'>
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault()

                                                        dispatch(
                                                            setSetState({
                                                                id: item.id,
                                                                name: item.title,
                                                            })
                                                        )
                                                    }}
                                                    className='focus:outline-none'
                                                >
                                                    <span
                                                        className='absolute inset-0'
                                                        aria-hidden='true'
                                                    />
                                                    {item.title}
                                                    <span aria-hidden='true'>
                                                        {" "}
                                                        &rarr;
                                                    </span>
                                                </button>
                                            </h3>
                                            <p className='mt-1 text-sm text-gray-400'>
                                                {item.description}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : setState.id === "CR" ? (
                    <SetRolView />
                ) : (
                    ""
                )}
            </div>
        </div>
    )
}

export default AccMain
