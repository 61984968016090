import { useState, ChangeEvent, useEffect, Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"

import { RootState } from "../app/store"
import { setRecFormState, setRecSPV } from "../features/auth/recSlice"

import { supabase } from "../supabaseClient"

import { Transition, Combobox } from "@headlessui/react"
import {
    ChevronRightIcon,
    XIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/solid"

type SPV = {
    spvID: string
    name: string
    proID: string
    license: string
    signatory: string
    issue: string
    expiry: string
}

type Pro = {
    proID: string
    address: string
    area: string
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const isInTheFuture = (givenDate: String) => {
    const now = new Date()

    now.setHours(23, 59, 59, 998)

    return (
        new Date(
            `${givenDate.substring(0, 4)}-${givenDate.substring(
                4,
                6
            )}-${givenDate.substring(6, 8)}`
        ) > now
    )
}

const RecSPVForm = () => {
    const dispatch = useDispatch()
    const { recFormState, recSPV } = useSelector(
        (state: RootState) => state.rec
    )
    const [invalid, setInvalid] = useState(false)
    const [error, setError] = useState(false)
    const [errorDetails, setErrorDetails] = useState({
        code: "",
        message: "",
        details: "",
    })
    const [verifying, setVerifying] = useState(false)
    const [loading, setLoading] = useState(false)

    const [pros, setPros] = useState<Pro[]>([])
    const [propertyQuery, setPropertyQuery] = useState("")
    const [propertiesShown, setPropertiesShown] = useState(false)

    const filteredProperties =
        propertyQuery === ""
            ? []
            : pros.filter((recPro) => {
                  return recPro.proID
                      .toLowerCase()
                      .includes(propertyQuery.toLowerCase())
              })

    const [spvData, setSPVData] = useState({
        spvID: "",
        name: "",
        proID: "",
        license: "",
        signatory: "",
        issue: "",
        expiry: "",
    })

    useEffect(() => {
        if (recSPV.spvID === "") {
            setSPVData({
                spvID: "",
                name: "",
                proID: "",
                license: "",
                signatory: "",
                issue: "",
                expiry: "",
            })
        } else {
            setSPVData(recSPV)
        }
    }, [recSPV])

    useEffect(() => {
        getPros()
    }, [recFormState])

    const { spvID, name, proID, license, signatory, issue, expiry } = spvData

    const getPros = async () => {
        setPros([])
        setLoading(true)
        setError(false)
        const spvResponse = await supabase.from<SPV>("R_SPV").select("proID")
        const proResponse = await supabase
            .from<Pro>("R_Pro")
            .select("proID,address,area")
            .order("proID", { ascending: true })

        if (spvResponse.data && proResponse.data) {
            setPros(
                proResponse.data.filter((pro) => {
                    return !spvResponse.data
                        .map((dataItem) => dataItem.proID)
                        .includes(pro.proID)
                })
            )
        } else if (proResponse.error) {
            setError(true)
            setErrorDetails({
                code: proResponse.error.code,
                message: proResponse.error.message,
                details: proResponse.error.details,
            })
        }

        setLoading(false)
    }

    const checkAvailable = async (givenID: String) => {
        setVerifying(true)

        const { data } = await supabase
            .from<SPV>("R_SPV")
            .select("spvID")
            .eq("spvID", `${givenID}`)

        setVerifying(false)

        return data?.length
    }

    const onSubmit = async () => {
        if (
            spvID === "" ||
            name === "" ||
            proID === "" ||
            license === "" ||
            signatory === "" ||
            issue === "" ||
            isInTheFuture(issue) ||
            expiry === ""
        ) {
            setInvalid(true)
        } else {
            setInvalid(false)
            if ((await checkAvailable(spvID)) === 0) {
                dispatch(setRecSPV(spvData))
                dispatch(setRecFormState(recFormState + 1))

                setError(false)
            } else {
                setError(true)
            }
        }
    }

    const onChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => {
        if (e.target.name === "issue" || e.target.name === "expiry") {
            setSPVData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        } else if (e.target.name === "spvID") {
            setSPVData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value.replaceAll(" ", ""),
            }))
        } else {
            setSPVData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        }
    }
    return (
        <>
            {verifying ? (
                <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                    <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                        <svg
                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                        >
                            <circle
                                className='opacity-25'
                                cx='12'
                                cy='12'
                                r='10'
                                stroke='currentColor'
                                strokeWidth='4'
                            ></circle>
                            <path
                                className='opacity-75'
                                fill='currentColor'
                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                            ></path>
                        </svg>
                        Verifying ...
                    </div>
                </div>
            ) : (
                <div className=''>
                    <form className='mt-7 space-y-8 divide-y divide-gray-200'>
                        <div className='  space-y-8 divide-y divide-gray-200'>
                            <div className=''>
                                <div>
                                    <h3 className='text-lg leading-6 font-medium text-gray-200'>
                                        New SPV
                                    </h3>
                                    <p className='mt-1 text-sm text-gray-300'>
                                        Fill in the form below with the relevant
                                        SPV details.
                                    </p>
                                </div>
                                <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                                    <div className='sm:col-span-1'>
                                        <label
                                            htmlFor='spvID'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            SPV ID
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                value={spvID}
                                                type='text'
                                                name='spvID'
                                                id='spvID'
                                                className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid && spvID === "" ? (
                                            <span className='text-sm text-red-500'>
                                                SPV's ID is empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-2'>
                                        <label
                                            htmlFor='name'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            Name (Trade Name)
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                type='text'
                                                value={name}
                                                name='name'
                                                id='name'
                                                className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid && name === "" ? (
                                            <span className='text-sm text-red-500'>
                                                SPV's name is empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-1'>
                                        <label
                                            htmlFor='license'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            License Number
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                type='text'
                                                value={license}
                                                name='license'
                                                id='license'
                                                className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid && license === "" ? (
                                            <span className='text-sm text-red-500'>
                                                SPV's license number is empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-2'>
                                        <label
                                            htmlFor='signatory'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            Authorized Signatory
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                type='text'
                                                value={signatory}
                                                name='signatory'
                                                id='signatory'
                                                className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid && signatory === "" ? (
                                            <span className='text-sm text-red-500'>
                                                SPV's authorized signatory is
                                                invalid or empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-1'>
                                        <label
                                            htmlFor='issue'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            License Issue Date
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)
                                                }}
                                                value={issue}
                                                type='date'
                                                name='issue'
                                                id='issue'
                                                className=' pb-1 appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid &&
                                        (isInTheFuture(issue) ||
                                            issue === "") ? (
                                            <span className='text-sm text-red-500'>
                                                SPV's issue date cannot be in
                                                the future or empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-1'>
                                        <label
                                            htmlFor='expiry'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            License Expiry Date
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)
                                                }}
                                                value={expiry}
                                                type='date'
                                                name='expiry'
                                                id='expiry'
                                                className=' pb-1 appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid && expiry === "" ? (
                                            <span className='text-sm text-red-500'>
                                                SPV's expiry date is empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {loading ? (
                                        <div className='sm:col-span-3'>
                                            <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                                                <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                                                    <svg
                                                        className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        fill='none'
                                                        viewBox='0 0 24 24'
                                                    >
                                                        <circle
                                                            className='opacity-25'
                                                            cx='12'
                                                            cy='12'
                                                            r='10'
                                                            stroke='currentColor'
                                                            strokeWidth='4'
                                                        ></circle>
                                                        <path
                                                            className='opacity-75'
                                                            fill='currentColor'
                                                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                        ></path>
                                                    </svg>
                                                    Loading ...
                                                </div>
                                            </div>{" "}
                                        </div>
                                    ) : (
                                        <div className='sm:col-span-3'>
                                            <label
                                                htmlFor='last-name'
                                                className='block text-sm font-medium text-gray-300'
                                            >
                                                Property ID
                                            </label>

                                            <Combobox
                                                onChange={() => {}}
                                                value={proID}
                                            >
                                                <div className='relative'>
                                                    <Combobox.Input
                                                        className=' mt-1 bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                        onChange={(event) => {
                                                            setPropertiesShown(
                                                                true
                                                            )
                                                            setPropertyQuery(
                                                                event.target
                                                                    .value
                                                            )
                                                            setInvalid(false)
                                                        }}
                                                    />
                                                </div>

                                                {filteredProperties.length >
                                                    0 &&
                                                    propertiesShown && (
                                                        <Combobox.Options
                                                            static
                                                            className='bg-gray-700 max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-200 rounded-b-xl'
                                                        >
                                                            {filteredProperties.map(
                                                                (recPro) => (
                                                                    <Combobox.Option
                                                                        onClick={() => {
                                                                            setSPVData(
                                                                                (
                                                                                    prevState
                                                                                ) => ({
                                                                                    ...prevState,
                                                                                    proID: recPro.proID,
                                                                                })
                                                                            )
                                                                            setPropertiesShown(
                                                                                false
                                                                            )
                                                                        }}
                                                                        key={
                                                                            recPro.proID
                                                                        }
                                                                        value={
                                                                            recPro.proID
                                                                        }
                                                                        className={({
                                                                            active,
                                                                        }) =>
                                                                            classNames(
                                                                                "cursor-default select-none px-4 py-2",
                                                                                active
                                                                                    ? "bg-blue-600 text-white"
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    >
                                                                        {`${recPro.proID} ${recPro.area} ${recPro.address}`}
                                                                    </Combobox.Option>
                                                                )
                                                            )}
                                                        </Combobox.Options>
                                                    )}

                                                {propertyQuery !== "" &&
                                                    filteredProperties.length ===
                                                        0 && (
                                                        <p className='p-4 text-sm text-gray-500'>
                                                            No property found.
                                                        </p>
                                                    )}
                                            </Combobox>
                                            {invalid && proID === "" ? (
                                                <span className='text-sm text-red-500'>
                                                    Property ID cannot be empty
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='pt-5'>
                            <div className='flex justify-end'>
                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            onSubmit()
                                        }}
                                        type='button'
                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <span>Continue</span>
                                        <ChevronRightIcon
                                            className='h-5 w-5'
                                            aria-hidden='true'
                                        />
                                    </button>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            )}
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={error}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                SPV Exists
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                A SPV with the given ID{" "}
                                                <span className=' font-bold'>
                                                    {spvID}
                                                </span>{" "}
                                                already exists
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        </>
    )
}

export default RecSPVForm
