import React from "react"

const Mtn = () => {
    return (
        <div className='mx-auto h-screen'>
            <div className='min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8'>
                <div className='max-w-md w-full space-y-8'>
                    <div>
                        <img
                            className='mx-auto h-48 w-auto '
                            src='logo.png'
                            alt='Workflow'
                        />
                        <img
                            className='mx-auto mt-10 bg-yellow-500 h-64 p-5 rounded-md '
                            src='mtn.svg'
                        ></img>
                        <p className=' mt-5 text-blue-400 font-semibold text-xl text-center'>
                            Maintenance in progress
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mtn
