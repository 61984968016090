import { Fragment, useState, useEffect } from "react"

import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../app/store"

import RecSteps from "./RecSteps"
import RecClass from "./RecClass"

import { PlusCircleIcon, ViewListIcon } from "@heroicons/react/outline"
import { ChevronLeftIcon, CheckCircleIcon } from "@heroicons/react/solid"
import { Transition } from "@headlessui/react"

import {
    reset,
    setRecFormState,
    setRecState,
    setSuccess,
    setSuccessCountdown,
} from "../features/auth/recSlice"

import RecInvForm from "./RecInvForm"
import RecInvPrev from "./RecInvPrev"
import RecInvView from "./RecInvView"
import RecInvSearch from "./RecInvSearch"

import RecProForm from "./RecProForm"
import RecProPrev from "./RecProPrev"
import RecProView from "./RecProView"
import RecProSearch from "./RecProSearch"

import RecSPVForm from "./RecSPVForm"
import RecSPVPrev from "./RecSPVPrev"
import RecSPVView from "./RecSPVView"
import RecSPVSearch from "./RecSPVSearch"

import RecSPVSForm from "./RecSPVSForm"
import RecSPVSPrev from "./RecSPVSPrev"
import RecSPVSView from "./RecSPVSView"
import RecSPVSSearch from "./RecSPVSSearch"

type DashboardItem = {
    id: string
    title: string
    description: string
    icon: React.FC<React.SVGProps<SVGSVGElement>>
    background: string
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const RecMain = () => {
    const dispatch = useDispatch()
    const {
        recClass,
        recFormState,
        recState,
        success,
        successCountdown,
        successType,
    } = useSelector((state: RootState) => state.rec)

    const { role } = useSelector((state: RootState) => state.auth)

    const [recDashboard, setRecDashboard] = useState<DashboardItem[]>([])

    useEffect(() => {
        setRecDashboard([])

        if (role.rec_i) {
            setRecDashboard((prev) => [
                ...prev,
                {
                    id: "NR",
                    title: "New Record",
                    description: "",
                    icon: PlusCircleIcon,
                    background: "bg-green-500",
                },
            ])
        }

        if (role.rec_v) {
            setRecDashboard((prev) => [
                ...prev,
                {
                    id: "VR",
                    title: "View Records",
                    description: "",
                    icon: ViewListIcon,
                    background: "bg-blue-500",
                },
            ])
        }
    }, [role])

    useEffect(() => {
        if (successCountdown < 1) {
            dispatch(setSuccess(false))
            return
        }

        const intervalId = setInterval(() => {
            dispatch(setSuccessCountdown(successCountdown - 1))
        }, 1000)

        return () => clearInterval(intervalId)
    }, [successCountdown])
    return (
        <>
            <div className='flex flex-col content-evenly bg-gray-800 h-full'>
                <div className=' content-start mx-10 mt-5'>
                    {recState.id === "NR" ? (
                        <RecSteps />
                    ) : recState.id === "VR" && recFormState === 1 ? (
                        recClass.id === "I" ? (
                            <RecInvSearch />
                        ) : recClass.id === "P" ? (
                            <RecProSearch />
                        ) : recClass.id === "S" ? (
                            <RecSPVSearch />
                        ) : recClass.id === "SS" ? (
                            <RecSPVSSearch />
                        ) : (
                            ""
                        )
                    ) : (
                        ""
                    )}
                </div>
                <div className=' content-start mx-10 mt-10 '>
                    {recFormState > 0 && recFormState < 3 ? (
                        <div className='mb-5'>
                            <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()

                                        dispatch(
                                            setRecFormState(recFormState - 1)
                                        )
                                    }}
                                    type='button'
                                    className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                >
                                    <ChevronLeftIcon
                                        className='h-5 w-5'
                                        aria-hidden='true'
                                    />
                                    <span>Previous</span>
                                </button>
                            </span>
                        </div>
                    ) : recState.id !== "X" && recFormState < 3 ? (
                        <div className='mb-5'>
                            <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()

                                        dispatch(
                                            setRecState({
                                                id: "X",
                                                name: "Dashboard",
                                            })
                                        )
                                    }}
                                    type='button'
                                    className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                >
                                    <ChevronLeftIcon
                                        className='h-5 w-5'
                                        aria-hidden='true'
                                    />
                                    <span>Previous</span>
                                </button>
                            </span>
                        </div>
                    ) : (
                        ""
                    )}
                    {recState.id === "X" ? (
                        <div className=''>
                            <h2 className='text-lg font-medium text-gray-200'>
                                Records
                            </h2>
                            <p className='mt-1 text-sm text-gray-300'></p>
                            <ul className='mt-6 border-t border-b border-yellow-400 py-6 grid grid-cols-1 gap-6 sm:grid-cols-4'>
                                {recDashboard.map((item, itemIdx) => (
                                    <li key={itemIdx} className='flow-root'>
                                        <div className='relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-slate-600 focus-within:ring-2 focus-within:ring-indigo-500'>
                                            <div
                                                className={classNames(
                                                    item.background,
                                                    "flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg"
                                                )}
                                            >
                                                <item.icon
                                                    className='h-6 w-6 text-white'
                                                    aria-hidden='true'
                                                />
                                            </div>
                                            <div>
                                                <h3 className='text-sm font-medium text-gray-200 hover:text-gray-100'>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault()

                                                            dispatch(
                                                                setRecState({
                                                                    id: item.id,
                                                                    name: item.title,
                                                                })
                                                            )
                                                        }}
                                                        className='focus:outline-none'
                                                    >
                                                        <span
                                                            className='absolute inset-0'
                                                            aria-hidden='true'
                                                        />
                                                        {item.title}
                                                        <span aria-hidden='true'>
                                                            {" "}
                                                            &rarr;
                                                        </span>
                                                    </button>
                                                </h3>
                                                <p className='mt-1 text-sm text-gray-400'>
                                                    {item.description}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : recState.id === "NR" ? (
                        recFormState === 0 ? (
                            <RecClass />
                        ) : recFormState === 1 ? (
                            recClass.id === "I" ? (
                                <RecInvForm />
                            ) : recClass.id === "P" ? (
                                <RecProForm />
                            ) : recClass.id === "S" ? (
                                <RecSPVForm />
                            ) : recClass.id === "SS" ? (
                                <RecSPVSForm />
                            ) : (
                                ""
                            )
                        ) : recFormState === 2 ? (
                            recClass.id === "I" ? (
                                <RecInvPrev />
                            ) : recClass.id === "P" ? (
                                <RecProPrev />
                            ) : recClass.id === "S" ? (
                                <RecSPVPrev />
                            ) : recClass.id === "SS" ? (
                                <RecSPVSPrev />
                            ) : (
                                ""
                            )
                        ) : recFormState === 3 ? (
                            <div className=''>
                                <div className='flex h-full'>
                                    <div className='mx-auto'>
                                        <p className=' mt-32 font-bold text-lg text-yellow-400'>
                                            Record submitted successfully
                                        </p>
                                        <div className='pt-5 flex justify-center'>
                                            <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault()

                                                        dispatch(reset())
                                                    }}
                                                    type='button'
                                                    className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                >
                                                    <ChevronLeftIcon
                                                        className='h-5 w-5'
                                                        aria-hidden='true'
                                                    />
                                                    <span>Back to Records</span>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )
                    ) : recState.id === "VR" ? (
                        recFormState === 0 ? (
                            <RecClass />
                        ) : recFormState === 1 ? (
                            recClass.id === "I" ? (
                                <RecInvView />
                            ) : recClass.id === "P" ? (
                                <RecProView />
                            ) : recClass.id === "S" ? (
                                <RecSPVView />
                            ) : recClass.id === "SS" ? (
                                <RecSPVSView />
                            ) : (
                                ""
                            )
                        ) : (
                            ""
                        )
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={success}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-green-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <CheckCircleIcon
                                                className='h-6 w-6 text-green-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                Success!
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                {`${successType} created successfully`}
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <div className=' rounded-md inline-flex text-green-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                                                {successCountdown > 0
                                                    ? successCountdown
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        </>
    )
}

export default RecMain
