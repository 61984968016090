import { createSlice } from "@reduxjs/toolkit"

interface state {
    setState: { id: string; name: string }
    setFormState: number
    setClass: { id: string; name: string }
    setType: { id: string; name: string }
    searchTerm: string
}

const initialState: state = {
    setState: { id: "X", name: "Dashboard" },
    setFormState: 0,
    setClass: { id: "", name: "" },
    setType: { id: "", name: "" },

    searchTerm: "",
}

export const accSlice = createSlice({
    name: "set",
    initialState,
    reducers: {
        reset: (state) => {
            state.setFormState = 0
            state.setState = { id: "X", name: "Dashboard" }
            state.setClass = { id: "", name: "" }
            state.setType = { id: "", name: "" }

            state.searchTerm = ""
        },
        setSetState: (state, action) => {
            state.setState = action.payload
        },
        setSetFormState: (state, action) => {
            state.setFormState = action.payload
        },
        setSetClass: (state, action) => {
            state.setClass = action.payload
        },
        setSetType: (state, action) => {
            state.setType = action.payload
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload
        },
    },
    extraReducers: (builder) => {},
})

export const {
    reset,
    setSetState,
    setSetFormState,
    setSetClass,
    setSetType,

    setSearchTerm,
} = accSlice.actions
export default accSlice.reducer
