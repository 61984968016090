import { Fragment, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { RootState } from "../app/store"
import {
    setSuccess,
    setSuccessCountdown,
    setSuccessType,
    reset,
} from "../features/auth/recSlice"

import { supabase } from "../supabaseClient"

import { Transition } from "@headlessui/react"
import {
    ChevronRightIcon,
    XIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/solid"

type Inv = {
    invID: String
    title: String
    fore: String
    sur: String
    email: String
    mobile: String
    dob: String
    occupation: String
    bracket: String
    risk: String
}

const RecInvPrev = () => {
    const dispatch = useDispatch()
    const { recInv, recFormState } = useSelector(
        (state: RootState) => state.rec
    )

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [errorDetails, setErrorDetails] = useState({
        code: "",
        message: "",
        details: "",
    })

    const onSubmit = async () => {
        setSaving(true)
        const { error } = await supabase
            .from<Inv>("R_Inv")
            .insert([recInv], { returning: "minimal" })

        setSaving(false)

        if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        } else {
            dispatch(setSuccessType("Investor"))
            dispatch(setSuccessCountdown(5))
            dispatch(setSuccess(true))
            dispatch(reset())
        }
    }

    useEffect(() => {
        setError(false)
    }, [recFormState])

    return (
        <>
            {saving ? (
                <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                    <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                        <svg
                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                        >
                            <circle
                                className='opacity-25'
                                cx='12'
                                cy='12'
                                r='10'
                                stroke='currentColor'
                                strokeWidth='4'
                            ></circle>
                            <path
                                className='opacity-75'
                                fill='currentColor'
                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                            ></path>
                        </svg>
                        Saving ...
                    </div>
                </div>
            ) : (
                <div className='mt-7'>
                    <div className=' '>
                        <h3 className='text-lg leading-6 font-medium text-gray-200'>
                            Investor Details Preview
                        </h3>
                        <p className='mt-1 max-w-2xl text-sm text-gray-300'>
                            Preview and confirm the new investor's details
                        </p>
                    </div>
                    <div className='border-b border-gray-200 mt-6 pb-6 '>
                        <dl className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3'>
                            <div className='sm:col-span-3'>
                                <dt className='text-sm font-medium text-gray-300'>
                                    Investor ID
                                </dt>
                                <dd className='mt-1 text-sm text-gray-100'>
                                    {recInv.invID}
                                </dd>
                            </div>
                            <div className='sm:col-span-1'>
                                <dt className='text-sm font-medium text-gray-300'>
                                    Title
                                </dt>
                                <dd className='mt-1 text-sm text-gray-100'>
                                    {recInv.title}
                                </dd>
                            </div>
                            <div className='sm:col-span-1'>
                                <dt className='text-sm font-medium text-gray-300'>
                                    Forename
                                </dt>
                                <dd className='mt-1 text-sm text-gray-100'>
                                    {recInv.fore}
                                </dd>
                            </div>
                            <div className='sm:col-span-1'>
                                <dt className='text-sm font-medium text-gray-300'>
                                    Surname
                                </dt>
                                <dd className='mt-1 text-sm text-gray-100'>
                                    {recInv.sur}
                                </dd>
                            </div>
                            <div className='sm:col-span-1'>
                                <dt className='text-sm font-medium text-gray-300'>
                                    Date of Birth
                                </dt>
                                <dd className='mt-1 text-sm text-gray-100'>
                                    {`${recInv.dob.substring(
                                        8,
                                        11
                                    )}-${recInv.dob.substring(
                                        5,
                                        7
                                    )}-${recInv.dob.substring(0, 4)}`}
                                </dd>
                            </div>
                            <div className='sm:col-span-1'>
                                <dt className='text-sm font-medium text-gray-300'>
                                    Email
                                </dt>
                                <dd className='mt-1 text-sm text-gray-100'>
                                    {recInv.email}
                                </dd>
                            </div>
                            <div className='sm:col-span-1'>
                                <dt className='text-sm font-medium text-gray-300'>
                                    Mobile Number
                                </dt>
                                <dd className='mt-1 text-sm text-gray-100'>
                                    {recInv.mobile}
                                </dd>
                            </div>
                            <div className='sm:col-span-1'>
                                <dt className='text-sm font-medium text-gray-300'>
                                    Occupation
                                </dt>
                                <dd className='mt-1 text-sm text-gray-100'>
                                    {recInv.occupation}
                                </dd>
                            </div>
                            <div className='sm:col-span-1'>
                                <dt className='text-sm font-medium text-gray-300'>
                                    Income Bracket
                                </dt>
                                <dd className='mt-1 text-sm text-gray-100'>
                                    {recInv.bracket}
                                </dd>
                            </div>
                            <div className='sm:col-span-1'>
                                <dt className='text-sm font-medium text-gray-300'>
                                    Risk Profile
                                </dt>
                                <dd className='mt-1 text-sm text-gray-100'>
                                    {recInv.risk}
                                </dd>
                            </div>
                        </dl>
                    </div>
                    <div className='pt-5 flex justify-end'>
                        <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                            <button
                                onClick={(e) => {
                                    e.preventDefault()

                                    onSubmit()
                                }}
                                type='button'
                                className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                            >
                                <span>Submit</span>
                                <ChevronRightIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                />
                            </button>
                        </span>
                    </div>
                </div>
            )}
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={error}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                {`Error ${errorDetails.code}: ${errorDetails.details}`}
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                {errorDetails.message}
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        </>
    )
}

export default RecInvPrev
