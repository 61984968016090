import { Fragment, useState, useEffect } from "react"
import { useSelector } from "react-redux"

import { RootState } from "../app/store"

import { supabase } from "../supabaseClient"

import { Transition } from "@headlessui/react"

import { utils, writeFile } from "xlsx"

import { XIcon, ExclamationCircleIcon } from "@heroicons/react/solid"
import { RefreshIcon, DocumentDownloadIcon } from "@heroicons/react/outline"

type Acc = {
    id: string
    not: string
    date: string
    proID: string
    invID: string
    split: string
    debit: number
    credit: number
    traType: string
    traRef: string
    notes: string
    traID: string
    created_at: string
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const RecProView = () => {
    const { accClass, searchTerm } = useSelector(
        (state: RootState) => state.acc
    )

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [errorDetails, setErrorDetails] = useState({
        code: "",
        message: "",
        details: "",
    })

    const [accs, setAccs] = useState<Acc[]>([])
    const [accsResult, setAccsResult] = useState<Acc[]>([])

    const getAccs = async () => {
        setAccs([])
        setLoading(true)
        setError(false)
        const { data, error } = await supabase
            .from<Acc>(accClass.id)
            .select()
            .order("created_at", { ascending: false })
        if (data) {
            setAccs(data)
            setAccsResult(data)
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }

        setLoading(false)
    }

    const xlsxExprt = () => {
        const today = new Date()
        const newAccs = accs.map((acc) => {
            return { ...acc, credit: acc.credit / 100, debit: acc.debit / 100 }
        })
        const worksheet = utils.json_to_sheet(newAccs)
        const workbook = utils.book_new()
        utils.book_append_sheet(
            workbook,
            worksheet,
            accClass.name.substring(0, 30)
        )
        writeFile(
            workbook,
            `${accClass.name}_${
                today.getDate().toString().length === 1
                    ? "0" + today.getDate().toString()
                    : today.getDate().toString()
            }-${
                today.getMonth().toString().length === 1
                    ? "0" + (today.getMonth() + 1).toString()
                    : (today.getMonth() + 1).toString()
            }-${today.getFullYear()}.xlsx`
        )
    }

    useEffect(() => {
        getAccs()
    }, [accClass])

    useEffect(() => {
        if (searchTerm === "") {
            setAccsResult(accs)
        } else {
            setAccsResult(
                accs.filter((recAcc) => {
                    return (
                        recAcc.invID
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        recAcc.proID
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        recAcc.not
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        recAcc.traRef
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        recAcc.traType
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        recAcc.traID
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        recAcc.id
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                    )
                })
            )
        }
    }, [searchTerm])

    return (
        <>
            {loading ? (
                <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                    <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                        <svg
                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                        >
                            <circle
                                className='opacity-25'
                                cx='12'
                                cy='12'
                                r='10'
                                stroke='currentColor'
                                strokeWidth='4'
                            ></circle>
                            <path
                                className='opacity-75'
                                fill='currentColor'
                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                            ></path>
                        </svg>
                        Loading ...
                    </div>
                </div>
            ) : (
                <div className='mt-7'>
                    <div className='flex flex-row justify-between'>
                        <div className='pt-2 justify-self-start '>
                            <h3 className='text-lg leading-6 font-medium text-gray-200'>
                                {accClass.name}
                            </h3>
                            <p className='mt-1 max-w-2xl text-sm text-gray-300'></p>
                        </div>
                        <div className='justify-self-end'>
                            <div className='flex flex-row'>
                                <div className='flex flex-col'>
                                    <span className=' self-center relative z-0 inline-flex shadow-sm rounded-md'>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault()
                                                getAccs()
                                            }}
                                            type='button'
                                            className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                        >
                                            <RefreshIcon
                                                className='h-5 w-5 pr-1'
                                                aria-hidden='true'
                                            />
                                            <span>Refresh</span>
                                        </button>
                                    </span>
                                    <span className='mt-5 relative z-0 inline-flex shadow-sm rounded-md'>
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault()
                                                xlsxExprt()
                                            }}
                                            type='button'
                                            className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                        >
                                            <DocumentDownloadIcon
                                                className='h-5 w-5 pr-1'
                                                aria-hidden='true'
                                            />
                                            <span>Excel file export</span>
                                        </button>
                                    </span>
                                </div>
                                <table className='ml-6 w-fit divide-y divide-white'>
                                    <tbody className='divide-y divide-white bg-gray-900 text-gray-300 text-base'>
                                        <tr key={"Total debit"}>
                                            <td className='whitespace-nowrap py-4 pl-4 pr-3 font-medium  sm:pl-6'>
                                                Total DR
                                            </td>
                                            <td className='whitespace-nowrap  text-right py-4 pl-4 pr-3 font-medium  sm:pl-6'>
                                                {(
                                                    accs.reduce(
                                                        (parSum, recAcc) =>
                                                            parSum +
                                                            recAcc.debit,
                                                        0
                                                    ) / 100
                                                ).toLocaleString()}
                                            </td>
                                        </tr>
                                        <tr key={"Total credit"}>
                                            <td className='whitespace-nowrap py-4 pl-4 pr-3 font-medium  sm:pl-6'>
                                                Total CR
                                            </td>
                                            <td className='whitespace-nowrap  text-right py-4 pl-4 pr-3 font-medium  sm:pl-6'>
                                                {(
                                                    accs.reduce(
                                                        (parSum, recAcc) =>
                                                            parSum +
                                                            recAcc.credit,
                                                        0
                                                    ) / 100
                                                ).toLocaleString()}
                                            </td>
                                        </tr>
                                        <tr key={"Total balance"}>
                                            <td className='whitespace-nowrap py-4 pl-4 pr-3 font-semibold sm:pl-6'>
                                                Balance
                                            </td>
                                            <td className='whitespace-nowrap text-right py-4 pl-4 pr-3 font-semibold sm:pl-6'>
                                                {(
                                                    (accs.reduce(
                                                        (parSum, recAcc) =>
                                                            parSum +
                                                            recAcc.debit,
                                                        0
                                                    ) -
                                                        accs.reduce(
                                                            (parSum, recAcc) =>
                                                                parSum +
                                                                recAcc.credit,
                                                            0
                                                        )) /
                                                    100
                                                ).toLocaleString()}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className='mx-8 mt-8 flex flex-col'>
                        <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-auto'>
                            <div className='inline-block min-w-full py-2 align-middle'>
                                <div className='shadow-sm ring-1 ring-black ring-opacity-5 max-h-[30rem]'>
                                    <table
                                        className='min-w-full border-separate'
                                        style={{ borderSpacing: 0 }}
                                    >
                                        <thead className='bg-gray-900'>
                                            <tr>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'
                                                >
                                                    Account Entry ID
                                                </th>
                                                <th
                                                    scope='col'
                                                    className=' whitespace-nowrap sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:table-cell'
                                                >
                                                    Nature of Transaction
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter lg:table-cell'
                                                >
                                                    Date
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Property ID
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Investory ID
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Split
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Debit
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Credit
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Type
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Reference
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Notes
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Transaction ID
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='bg-gray-700'>
                                            {accsResult.map(
                                                (recAcc, recAccIdx) => (
                                                    <tr key={recAcc.id}>
                                                        <td
                                                            className={classNames(
                                                                recAccIdx !==
                                                                    accs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6 lg:pl-8"
                                                            )}
                                                        >
                                                            {recAcc.id}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recAccIdx !==
                                                                    accs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recAcc.not}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recAccIdx !==
                                                                    accs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {`${recAcc.date.substring(
                                                                8,
                                                                11
                                                            )}-${recAcc.date.substring(
                                                                5,
                                                                7
                                                            )}-${recAcc.date.substring(
                                                                0,
                                                                4
                                                            )}`}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recAccIdx !==
                                                                    accs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recAcc.proID}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recAccIdx !==
                                                                    accs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recAcc.invID}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recAccIdx !==
                                                                    accs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recAcc.split}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recAccIdx !==
                                                                    accs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {(
                                                                recAcc.debit /
                                                                100
                                                            ).toLocaleString()}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recAccIdx !==
                                                                    accs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {(
                                                                recAcc.credit /
                                                                100
                                                            ).toLocaleString()}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recAccIdx !==
                                                                    accs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recAcc.traType}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recAccIdx !==
                                                                    accs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recAcc.traRef}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recAccIdx !==
                                                                    accs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recAcc.notes}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recAccIdx !==
                                                                    accs.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recAcc.traID}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className=' z-20 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={error}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                {`Error ${errorDetails.code}: ${errorDetails.details}`}
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                {errorDetails.message}
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        </>
    )
}

export default RecProView
