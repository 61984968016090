import { Fragment, useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux"

import { RootState } from "../app/store"

import { supabase } from "../supabaseClient"

import { Transition, Dialog } from "@headlessui/react"

import { utils, writeFile } from "xlsx"

import { XIcon, ExclamationCircleIcon } from "@heroicons/react/solid"
import {
    RefreshIcon,
    ReceiptRefundIcon,
    ExclamationIcon,
    DocumentDownloadIcon,
} from "@heroicons/react/outline"

type Tra = {
    traID: string
    tra: string
    date: string
    type: string
    subType: string
    reversed: boolean
    account1ID: string
    account2ID: string
    account3ID: string
    account4ID: string
    account5ID: string
    account6ID: string
    account7ID: string
    amount: number
    traRef: string
    notes: string
    created_at: string
    investor: string
    property: string
    spv: string
}

type Account = {
    not: string
    date: string
    proID: string
    invID: string
    split: string
    debit: number
    credit: number
    traType: string
    traRef: string
    notes: string
    traID: string
}

type Transaction = {
    name: string
    accountID: string
    record: [
        {
            not: string
            date: string
            proID: string
            invID: string
            split: string
            debit: number
            credit: number
            traType: string
            traRef: string
            notes: string
            traID: string
        }
    ]
}

type Subset = {
    inv_id: string
    pro_id: string | null
    value: number
    type: string
    date: string
    reference: string | null
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const accountNames: { [key: string]: string } = {
    A_A_CMA: "Client Money Account",
    A_A_RA: "Rental Account",
    A_A_SDA: "Security Deposit Account",
    A_A_CMWA: "Client Money Wallet Account",
    A_A_CA: "Corporate Account",
    A_A_P: "Properties",
    A_E_SC: "Share Capital",
    A_E_RE: "Retained Earnings",
    A_E_R: "Reserve",
    A_L_IWL: "Investor Wallet (Locked)",
    A_L_IWO: "Investor Wallet (Open)",
    A_L_BI: "Baytuki Income",
    A_L_OL: "Other Liability",
    A_SPV_Pro: "SPV Properties",
    A_SPV_RBTI: "SPV Receivable from BT - Intercompany",
    A_SPV_PMGF: "SPV Property Management Fees",
    A_SPV_PMTF: "SPV Property Maintenance Fees",
    A_SPV_SPEF: "SPV Sale of Property - Exit Fees",
    A_SPV_AAF: "SPV Annual Administration Fee",
    A_SPV_SFP: "SPV Service Fee for Platform",
    A_SPV_RI: "SPV Rental Income",
    A_SPV_SPI: "SPV Sale of Property - Income",
    A_SPV_UD: "SPV Utilities Deposit",
    A_SPV_RSD: "SPV Refundable Security Deposit to Clients",
    A_SPV_SC: "SPV Share Capital",
    A_SPV_Div: "SPV Dividend",
}

const TraView = () => {
    const { tranFormState, searchTerm } = useSelector(
        (state: RootState) => state.tran
    )
    const { role } = useSelector((state: RootState) => state.auth)

    const cancelButtonRef = useRef(null)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [open, setOpen] = useState(false)
    const [saving, setSaving] = useState(false)
    const [errorDetails, setErrorDetails] = useState({
        code: "",
        message: "",
        details: "",
    })
    const [invalid, setInvalid] = useState(false)
    const [reason, setReason] = useState("")

    const [tras, setTras] = useState<Tra[]>([])
    const [trasResult, setTrasResult] = useState<Tra[]>([])
    const [tra, setTra] = useState<Tra>()

    const getTras = async () => {
        setTras([])
        setLoading(true)
        setError(false)
        const { data, error } = await supabase
            .from<Tra>("Transactions")
            .select()
            .order("created_at", { ascending: false })
        if (data) {
            setTras(data)
            setTrasResult(data)
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }

        setLoading(false)
    }

    useEffect(() => {
        getTras()
    }, [tranFormState])

    useEffect(() => {
        if (searchTerm === "") {
            setTrasResult(tras)
        } else {
            setTrasResult(
                tras.filter((recTra) => {
                    return (
                        recTra.traID
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        recTra.traRef
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        recTra.type
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        recTra.date
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                        (recTra.property &&
                            recTra.property
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())) ||
                        (recTra.spv &&
                            recTra.spv
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())) ||
                        (recTra.investor &&
                            recTra.investor
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()))
                    )
                })
            )
        }
    }, [searchTerm])

    const saveAccount = async (data: Transaction) => {
        await supabase
            .from<Account>(`A_${data.accountID}`)
            .insert([data.record[0]], { returning: "minimal" })
    }

    const saveSubset = async (data: Subset) => {
        await supabase
            .from<Subset>(`T_Subset`)
            .insert(data, { returning: "minimal" })
    }

    const getDistinctInvestor = (data: Transaction[]) => {
        let array: Transaction[] = []
        data.filter(function (item) {
            var i = array.findIndex(
                (x) => x.record[0].invID === item.record[0].invID
            )
            if (i <= -1) {
                array.push(item)
            }
            return null
        })
        return array
    }

    const onReverse = async () => {
        if (reason === "") {
            setInvalid(true)
        } else {
            setSaving(true)
            setOpen(false)
            if (tra) {
                const newTransactions = JSON.parse(tra.tra)

                newTransactions.forEach((transaction: Transaction) => {
                    transaction.record[0].debit === 0
                        ? (transaction.record[0].debit *= 0)
                        : (transaction.record[0].debit *= -1)
                    transaction.record[0].credit === 0
                        ? (transaction.record[0].credit *= 0)
                        : (transaction.record[0].credit *= -1)
                    transaction.record[0].traRef = tra.traID
                    transaction.record[0].notes = `Transaction reversal: ${reason}`
                })

                const newTra = {
                    tra: JSON.stringify(newTransactions),
                    date: new Date().toDateString(),
                    type: tra.type,
                    subType: tra.subType,
                    reversed: true,
                    amount: tra.amount * -1,
                    traRef: tra.traID,
                    notes: `Transaction Reversal: ${reason}`,
                    account1ID: tra.account1ID,
                    account2ID: tra.account2ID,
                    account3ID: tra.account3ID,
                    account4ID: tra.account4ID,
                    account5ID: tra.account5ID,
                    account6ID: tra.account6ID,
                    account7ID: tra.account7ID,
                    investor: tra.investor,
                    property: tra.property,
                    spv: tra.spv,
                }
                const { data } = await supabase
                    .from<Tra>("Transactions")
                    .insert([newTra])
                if (data) {
                    newTransactions.forEach((tran: Transaction) => {
                        const accountData = tran
                        accountData.record[0].traID = data[0].traID
                        saveAccount(accountData)
                    })
                    if (data[0].investor !== "") {
                        getDistinctInvestor(newTransactions).forEach((tran) => {
                            const subSet = {
                                inv_id: tran.record[0].invID,
                                pro_id:
                                    tran.record[0].proID === ""
                                        ? null
                                        : tran.record[0].proID,
                                value:
                                    tran.record[0].credit === 0
                                        ? tran.record[0].debit
                                        : tran.record[0].credit,
                                type: `Reversed: ${tran.record[0].traType}`,
                                date: tran.record[0].date,
                                reference: "Transaction Reversal",
                            }
                            saveSubset(subSet)
                        })
                    }
                }
            }

            const { error } = await supabase
                .from<Tra>("Transactions")
                .update(
                    {
                        reversed: true,
                    },
                    { returning: "minimal" }
                )
                .match({ traID: tra?.traID })

            setSaving(false)
            setReason("")

            if (error) {
                setError(true)
                setErrorDetails({
                    code: error.code,
                    message: error.message,
                    details: error.details,
                })
            } else {
                getTras()
            }
        }
    }

    const xlsxExprt = () => {
        const today = new Date()
        const newTras = tras.map((tran) => {
            const { tra, ...notran } = tran
            return { ...notran, amount: tran.amount / 100 }
        })
        const worksheet = utils.json_to_sheet(newTras)
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, "Transactions")
        writeFile(
            workbook,
            `Transactions_${
                today.getDate().toString().length === 1
                    ? "0" + today.getDate().toString()
                    : today.getDate().toString()
            }-${
                today.getMonth().toString().length === 1
                    ? "0" + (today.getMonth() + 1).toString()
                    : (today.getMonth() + 1).toString()
            }-${today.getFullYear()}.xlsx`
        )
    }

    return (
        <>
            {loading || saving ? (
                <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                    <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                        <svg
                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                        >
                            <circle
                                className='opacity-25'
                                cx='12'
                                cy='12'
                                r='10'
                                stroke='currentColor'
                                strokeWidth='4'
                            ></circle>
                            <path
                                className='opacity-75'
                                fill='currentColor'
                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                            ></path>
                        </svg>
                        Loading ...
                    </div>
                </div>
            ) : (
                <div className='mt-7'>
                    <div className='flex flex-row justify-between '>
                        <div className='pt-2 justify-self-start '>
                            <h3 className='text-lg leading-6 font-medium text-gray-200'>
                                Transactions List
                            </h3>
                            <p className='mt-1 max-w-2xl text-sm text-gray-300'></p>
                        </div>
                        <div className='justify-self-end'>
                            <div className='flex flex-col'>
                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            getTras()
                                        }}
                                        type='button'
                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <RefreshIcon
                                            className='h-5 w-5 pr-1'
                                            aria-hidden='true'
                                        />
                                        <span>Refresh</span>
                                    </button>
                                </span>
                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            xlsxExprt()
                                        }}
                                        type='button'
                                        className='mt-6 py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <DocumentDownloadIcon
                                            className='h-5 w-5 pr-1'
                                            aria-hidden='true'
                                        />
                                        <span>Excel file export</span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='mx-8 mt-8 flex flex-col '>
                        <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-auto'>
                            <div className='inline-block min-w-full py-2 align-middle'>
                                <div className='shadow-sm ring-1 ring-black ring-opacity-5 max-h-[39rem]'>
                                    <table
                                        className='min-w-full border-separate'
                                        style={{ borderSpacing: 0 }}
                                    >
                                        <thead className='bg-gray-900'>
                                            <tr>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'
                                                >
                                                    Transaction ID
                                                </th>
                                                {role.tra_e ? (
                                                    <th
                                                        scope='col'
                                                        className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filtersm:pr-6 lg:pr-8'
                                                    >
                                                        <span className='sr-only'>
                                                            Reverse
                                                        </span>
                                                    </th>
                                                ) : (
                                                    ""
                                                )}
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:table-cell'
                                                >
                                                    Date
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter lg:table-cell'
                                                >
                                                    Type
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter lg:table-cell'
                                                >
                                                    Subtype
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Amount
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Investor ID(s)
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Property ID
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    SPV ID
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Accounts
                                                </th>

                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Reference
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Notes
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='bg-gray-700'>
                                            {trasResult.map(
                                                (recTra, recTraIdx) => (
                                                    <tr key={recTra.traID}>
                                                        <td
                                                            className={classNames(
                                                                recTraIdx !==
                                                                    tras.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6 lg:pl-8"
                                                            )}
                                                        >
                                                            {recTra.traID}
                                                        </td>
                                                        {role.tra_e ? (
                                                            <td
                                                                className={classNames(
                                                                    recTraIdx !==
                                                                        tras.length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                                                                )}
                                                            >
                                                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                                                    {recTra.reversed ? (
                                                                        <button
                                                                            type='button'
                                                                            className='cursor-not-allowed py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-gray-200 text-sm font-medium text-gray-900   focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                                        >
                                                                            <ReceiptRefundIcon
                                                                                className='h-5 w-5 pr-1'
                                                                                aria-hidden='true'
                                                                            />
                                                                            <span>
                                                                                Transaction
                                                                                Reversed
                                                                            </span>
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault()
                                                                                setTra(
                                                                                    recTra
                                                                                )
                                                                                setOpen(
                                                                                    true
                                                                                )
                                                                            }}
                                                                            type='button'
                                                                            className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                                        >
                                                                            <ReceiptRefundIcon
                                                                                className='h-5 w-5 pr-1'
                                                                                aria-hidden='true'
                                                                            />
                                                                            <span>
                                                                                Reverse
                                                                                Transaction
                                                                            </span>
                                                                        </button>
                                                                    )}
                                                                </span>
                                                            </td>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <td
                                                            className={classNames(
                                                                recTraIdx !==
                                                                    tras.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {`${recTra.date.substring(
                                                                8,
                                                                11
                                                            )}-${recTra.date.substring(
                                                                5,
                                                                7
                                                            )}-${recTra.date.substring(
                                                                0,
                                                                4
                                                            )}`}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recTraIdx !==
                                                                    tras.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recTra.type}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recTraIdx !==
                                                                    tras.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recTra.subType}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recTraIdx !==
                                                                    tras.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {(
                                                                recTra.amount /
                                                                100
                                                            ).toLocaleString()}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recTraIdx !==
                                                                    tras.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recTra.investor
                                                                ? recTra.investor
                                                                      .split(
                                                                          ","
                                                                      )
                                                                      .map(
                                                                          (
                                                                              inv
                                                                          ) => (
                                                                              <ul>
                                                                                  <li
                                                                                      className=''
                                                                                      key={
                                                                                          inv
                                                                                      }
                                                                                  >
                                                                                      {
                                                                                          inv
                                                                                      }
                                                                                  </li>
                                                                              </ul>
                                                                          )
                                                                      )
                                                                : ""}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recTraIdx !==
                                                                    tras.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recTra.property}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recTraIdx !==
                                                                    tras.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recTra.spv}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recTraIdx !==
                                                                    tras.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            <div className='mb-3'>
                                                                {
                                                                    accountNames[
                                                                        recTra
                                                                            .account1ID
                                                                    ]
                                                                }
                                                            </div>
                                                            <div className='mb-3'>
                                                                {
                                                                    accountNames[
                                                                        recTra
                                                                            .account2ID
                                                                    ]
                                                                }
                                                            </div>
                                                            {recTra.account3ID !==
                                                            "NA" ? (
                                                                <div className='mb-3'>
                                                                    {
                                                                        accountNames[
                                                                            recTra
                                                                                .account3ID
                                                                        ]
                                                                    }
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {recTra.account4ID !==
                                                            "NA" ? (
                                                                <div className='mb-3'>
                                                                    {
                                                                        accountNames[
                                                                            recTra
                                                                                .account4ID
                                                                        ]
                                                                    }
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {recTra.account5ID !==
                                                            "NA" ? (
                                                                <div className='mb-3'>
                                                                    {
                                                                        accountNames[
                                                                            recTra
                                                                                .account5ID
                                                                        ]
                                                                    }
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {recTra.account6ID !==
                                                            "NA" ? (
                                                                <div className='mb-3'>
                                                                    {
                                                                        accountNames[
                                                                            recTra
                                                                                .account6ID
                                                                        ]
                                                                    }
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {recTra.account7ID !==
                                                            "NA" ? (
                                                                <div className=''>
                                                                    {
                                                                        accountNames[
                                                                            recTra
                                                                                .account7ID
                                                                        ]
                                                                    }
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </td>

                                                        <td
                                                            className={classNames(
                                                                recTraIdx !==
                                                                    tras.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recTra.traRef}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recTraIdx !==
                                                                    tras.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recTra.notes}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as='div'
                    className='relative z-10'
                    initialFocus={cancelButtonRef}
                    onClose={setOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    <div className='fixed z-10 inset-0 overflow-y-auto'>
                        <div className='flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                                enterTo='opacity-100 translate-y-0 sm:scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            >
                                <Dialog.Panel className='relative bg-slate-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6'>
                                    <div className='sm:flex sm:items-start'>
                                        <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-600 sm:mx-0 sm:h-10 sm:w-10'>
                                            <ExclamationIcon
                                                className='h-7 w-7 text-gray-100'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                            <Dialog.Title
                                                as='h3'
                                                className='text-lg leading-6 font-medium text-gray-100 mt-2'
                                            >
                                                Warning!
                                            </Dialog.Title>
                                            <div className='mt-5'>
                                                <p className='text-sm text-gray-300'>
                                                    Are you sure you want to
                                                    reverse this transaction?
                                                    <p className='font-bold'>
                                                        This action cannot be
                                                        undone.
                                                    </p>
                                                </p>
                                            </div>
                                            <div className='sm:col-span-2 mt-5 '>
                                                <label
                                                    htmlFor='sur'
                                                    className='block text-sm font-medium text-gray-300'
                                                >
                                                    Reversal reason:
                                                </label>
                                                <div className='mt-1'>
                                                    <input
                                                        onChange={(e) => {
                                                            setReason(
                                                                e.target.value
                                                            )

                                                            setInvalid(false)
                                                        }}
                                                        type='text'
                                                        value={reason}
                                                        name='reason'
                                                        id='reason'
                                                        className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                    />
                                                </div>
                                                {invalid && reason === "" ? (
                                                    <span className='text-sm text-red-500'>
                                                        Transaction reversal
                                                        reason cannot be empty
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                                        <button
                                            type='button'
                                            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
                                            onClick={() => onReverse()}
                                        >
                                            Reverse
                                        </button>
                                        <button
                                            type='button'
                                            className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:mt-0 sm:w-auto sm:text-sm'
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className=' z-20 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={error}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                {`Error ${errorDetails.code}: ${errorDetails.details}`}
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                {errorDetails.message}
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        </>
    )
}

export default TraView
