import { Fragment, useState, useEffect, ChangeEvent } from "react"
import { useSelector } from "react-redux"

import { RootState } from "../app/store"

import { supabase } from "../supabaseClient"

import { Transition, Dialog, Switch } from "@headlessui/react"

import { utils, writeFile } from "xlsx"

import { XIcon, ExclamationCircleIcon } from "@heroicons/react/solid"

import NumberFormat from "react-number-format"

import {
    RefreshIcon,
    PencilAltIcon,
    DocumentDownloadIcon,
} from "@heroicons/react/outline"

type Pro = {
    proID: string
    shares: number
    address: string
    area: string
    size: string
    bed: string
    bath: string
    utility: string
    amenities: string
    tenanted: boolean
    rent: number
    deposit: number
    other: number
    price: number
    purCost: number
    traCost: number
    status: string
    created_at: string
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
}

const fromNumber = (original: String) => {
    if (original.includes(".")) {
        switch (original.substring(original.lastIndexOf(".")).length) {
            case 1:
                return Number(`${original.replace(",", "")}00`) * 100

            case 2:
                return Number(`${original.replace(",", "")}0`) * 100

            case 3:
                return Number(`${original.replace(",", "")}`) * 100

            default:
                return (
                    Number(
                        original.substring(0, original.lastIndexOf(".") + 3)
                    ) * 100
                )
        }
    } else {
        return Number(original) * 100
    }
}

const RecProView = () => {
    const { searchTermPro, recFormState } = useSelector(
        (state: RootState) => state.rec
    )

    const { role } = useSelector((state: RootState) => state.auth)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [edit, setEdit] = useState(false)
    const [saving, setSaving] = useState(false)
    const [invalid, setInvalid] = useState(false)
    const [errorDetails, setErrorDetails] = useState({
        code: "",
        message: "",
        details: "",
    })

    const [pros, setPros] = useState<Pro[]>([])
    const [prosResult, setProsResult] = useState<Pro[]>([])

    const [proData, setProData] = useState<Pro>({
        proID: "",
        shares: 0,
        address: "",
        area: "",
        size: "",
        bed: "",
        bath: "",
        utility: "",
        amenities: "",
        tenanted: false,
        rent: 0,
        deposit: 0,
        other: 0,
        price: 0,
        purCost: 0,
        traCost: 0,
        status: "",
        created_at: "",
    })
    const [displayNums, setDisplayNums] = useState({
        sharesDis: "",
        rentDis: "",
        depositDis: "",
        otherDis: "",
        priceDis: "",
        purCostDis: "",
        traCostDis: "",
    })

    const {
        proID,
        shares,
        address,
        area,
        size,
        bed,
        bath,
        utility,
        amenities,
        tenanted,
        rent,
        deposit,
        other,
        price,
        purCost,
        traCost,
        status,
    } = proData

    const {
        sharesDis,
        rentDis,
        depositDis,
        otherDis,
        priceDis,
        purCostDis,
        traCostDis,
    } = displayNums

    const getPros = async () => {
        setPros([])
        setLoading(true)
        setError(false)
        const { data, error } = await supabase
            .from<Pro>("R_Pro")
            .select()
            .order("created_at", { ascending: false })
        if (data) {
            setPros(data)
            setProsResult(data)
        } else if (error) {
            setError(true)
            setErrorDetails({
                code: error.code,
                message: error.message,
                details: error.details,
            })
        }

        setLoading(false)
    }

    useEffect(() => {
        getPros()
    }, [recFormState])

    useEffect(() => {
        if (searchTermPro === "") {
            setProsResult(pros)
        } else {
            setProsResult(
                pros.filter((recInv) => {
                    return (
                        recInv.proID
                            .toLowerCase()
                            .includes(searchTermPro.toLowerCase()) ||
                        recInv.area
                            .toLowerCase()
                            .includes(searchTermPro.toLowerCase()) ||
                        recInv.status
                            .toLowerCase()
                            .includes(searchTermPro.toLowerCase())
                    )
                })
            )
        }
    }, [searchTermPro])

    const onSubmit = async () => {
        if (
            proID === "" ||
            shares < 1 ||
            isNaN(shares) ||
            String(shares) === "" ||
            address === "" ||
            area === "" ||
            size === "" ||
            bed === "" ||
            bath === "" ||
            utility === "" ||
            amenities === "" ||
            rent < 1 ||
            isNaN(rent) ||
            String(rent) === "" ||
            deposit < 1 ||
            isNaN(deposit) ||
            String(deposit) === "" ||
            other < 0 ||
            isNaN(other) ||
            price < 1 ||
            isNaN(price) ||
            String(price) === "" ||
            purCost < 1 ||
            isNaN(purCost) ||
            String(purCost) === "" ||
            traCost < 1 ||
            isNaN(traCost) ||
            String(traCost) === "" ||
            status === ""
        ) {
            setInvalid(true)
        } else {
            setInvalid(false)
            setSaving(true)
            const { error } = await supabase
                .from<Pro>("R_Pro")
                .update(
                    {
                        shares: shares,
                        address: address,
                        area: area,
                        size: size,
                        bed: bed,
                        bath: bath,
                        utility: utility,
                        amenities: amenities,
                        tenanted: tenanted,
                        rent: rent,
                        deposit: deposit,
                        other: other,
                        price: price,
                        purCost: purCost,
                        traCost: traCost,
                        status: status,
                    },
                    { returning: "minimal" }
                )
                .match({ proID: proID })

            setSaving(false)
            if (error) {
                setError(true)
                setErrorDetails({
                    code: error.code,
                    message: error.message,
                    details: error.details,
                })
            } else {
                getPros()
                setEdit(false)
            }
        }
    }

    const onChange = (
        e:
            | ChangeEvent<HTMLInputElement>
            | ChangeEvent<HTMLSelectElement>
            | boolean
    ) => {
        if (typeof e === "boolean") {
            setProData((prevState) => ({
                ...prevState,
                tenanted: e,
            }))
        } else {
            switch (e.target.name) {
                case "shares":
                    setDisplayNums((prevState) => ({
                        ...prevState,
                        sharesDis: e.target.value,
                    }))
                    setProData((prevState) => ({
                        ...prevState,
                        [e.target.name]: fromNumber(
                            e.target.value.replaceAll(",", "")
                        ),
                    }))
                    break
                case "rent":
                    setDisplayNums((prevState) => ({
                        ...prevState,
                        rentDis: e.target.value,
                    }))
                    setProData((prevState) => ({
                        ...prevState,
                        [e.target.name]: fromNumber(
                            e.target.value.replaceAll(",", "")
                        ).toFixed(0),
                    }))
                    break
                case "deposit":
                    setDisplayNums((prevState) => ({
                        ...prevState,
                        depositDis: e.target.value,
                    }))
                    setProData((prevState) => ({
                        ...prevState,
                        [e.target.name]: fromNumber(
                            e.target.value.replaceAll(",", "")
                        ).toFixed(0),
                    }))
                    break
                case "other":
                    setDisplayNums((prevState) => ({
                        ...prevState,
                        otherDis: e.target.value,
                    }))
                    setProData((prevState) => ({
                        ...prevState,
                        [e.target.name]: fromNumber(
                            e.target.value.replaceAll(",", "")
                        ).toFixed(0),
                    }))
                    break
                case "price":
                    setDisplayNums((prevState) => ({
                        ...prevState,
                        priceDis: e.target.value,
                    }))
                    setProData((prevState) => ({
                        ...prevState,
                        [e.target.name]: fromNumber(
                            e.target.value.replaceAll(",", "")
                        ).toFixed(0),
                    }))
                    break
                case "purCost":
                    setDisplayNums((prevState) => ({
                        ...prevState,
                        purCostDis: e.target.value,
                    }))
                    setProData((prevState) => ({
                        ...prevState,
                        [e.target.name]: fromNumber(
                            e.target.value.replaceAll(",", "")
                        ).toFixed(0),
                    }))
                    break
                case "traCost":
                    setDisplayNums((prevState) => ({
                        ...prevState,
                        traCostDis: e.target.value,
                    }))
                    setProData((prevState) => ({
                        ...prevState,
                        [e.target.name]: fromNumber(
                            e.target.value.replaceAll(",", "")
                        ).toFixed(0),
                    }))
                    break

                default:
                    setProData((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value,
                    }))
                    break
            }
        }
    }

    const xlsxExprt = () => {
        const today = new Date()
        const newPros = pros.map((pro) => {
            return {
                ...pro,
                shares: pro.shares / 100,
                rent: pro.rent / 100,
                deposit: pro.deposit / 100,
                other: pro.other / 100,
                price: pro.price / 100,
                purCost: pro.purCost / 100,
                traCost: pro.traCost / 100,
            }
        })
        const worksheet = utils.json_to_sheet(newPros)
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, "Properties")
        writeFile(
            workbook,
            `Properties_${
                today.getDate().toString().length === 1
                    ? "0" + today.getDate().toString()
                    : today.getDate().toString()
            }-${
                today.getMonth().toString().length === 1
                    ? "0" + (today.getMonth() + 1).toString()
                    : (today.getMonth() + 1).toString()
            }-${today.getFullYear()}.xlsx`
        )
    }

    return (
        <>
            {loading ? (
                <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                    <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                        <svg
                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                        >
                            <circle
                                className='opacity-25'
                                cx='12'
                                cy='12'
                                r='10'
                                stroke='currentColor'
                                strokeWidth='4'
                            ></circle>
                            <path
                                className='opacity-75'
                                fill='currentColor'
                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                            ></path>
                        </svg>
                        Loading ...
                    </div>
                </div>
            ) : (
                <div className='mt-7'>
                    <div className='flex flex-row justify-between '>
                        <div className='pt-2 justify-self-start '>
                            <h3 className='text-lg leading-6 font-medium text-gray-200'>
                                Property List
                            </h3>
                            <p className='mt-1 max-w-2xl text-sm text-gray-300'></p>
                        </div>
                        <div className='justify-self-end'>
                            <div className='flex flex-col'>
                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            getPros()
                                        }}
                                        type='button'
                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <RefreshIcon
                                            className='h-5 w-5 pr-1'
                                            aria-hidden='true'
                                        />
                                        <span>Refresh</span>
                                    </button>
                                </span>
                                <span className='mt-6 relative z-0 inline-flex shadow-sm rounded-md'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            xlsxExprt()
                                        }}
                                        type='button'
                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <DocumentDownloadIcon
                                            className='h-5 w-5 pr-1'
                                            aria-hidden='true'
                                        />
                                        <span>Excel file export</span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='mx-8 mt-8 flex flex-col '>
                        <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-auto'>
                            <div className='inline-block min-w-full py-2 align-middle'>
                                <div className='shadow-sm ring-1 ring-black ring-opacity-5 max-h-[43rem]'>
                                    <table
                                        className='min-w-full border-separate'
                                        style={{ borderSpacing: 0 }}
                                    >
                                        <thead className='bg-gray-900'>
                                            <tr>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8'
                                                >
                                                    Property ID
                                                </th>
                                                {role.rec_e ? (
                                                    <th
                                                        scope='col'
                                                        className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filtersm:pr-6 lg:pr-8'
                                                    >
                                                        <span className='sr-only'>
                                                            Edit
                                                        </span>
                                                    </th>
                                                ) : (
                                                    ""
                                                )}
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter sm:table-cell'
                                                >
                                                    Shares
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter lg:table-cell'
                                                >
                                                    Address
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Area
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Size
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Bed
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Bath
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Utility
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Amenities
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Tenanted
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Rent
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Deposit
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Other
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Price
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Purchase Cost
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='whitespace-nowrap  sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Transaction Cost
                                                </th>
                                                <th
                                                    scope='col'
                                                    className='sticky top-0 z-10 border-b border-gray-300 bg-gray-900 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-200 backdrop-blur backdrop-filter'
                                                >
                                                    Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='bg-gray-700'>
                                            {prosResult.map(
                                                (recPro, recProIdx) => (
                                                    <tr key={recPro.proID}>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-100 sm:pl-6 lg:pl-8"
                                                            )}
                                                        >
                                                            {recPro.proID}
                                                        </td>
                                                        {role.rec_e ? (
                                                            <td
                                                                className={classNames(
                                                                    recProIdx !==
                                                                        pros.length -
                                                                            1
                                                                        ? "border-b border-gray-200"
                                                                        : "",
                                                                    "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                                                                )}
                                                            >
                                                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                                                    <button
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault()
                                                                            setProData(
                                                                                recPro
                                                                            )

                                                                            setDisplayNums(
                                                                                {
                                                                                    sharesDis:
                                                                                        (
                                                                                            recPro.shares /
                                                                                            100
                                                                                        ).toString(),
                                                                                    rentDis:
                                                                                        (
                                                                                            recPro.rent /
                                                                                            100
                                                                                        ).toString(),
                                                                                    depositDis:
                                                                                        (
                                                                                            recPro.deposit /
                                                                                            100
                                                                                        ).toString(),
                                                                                    otherDis:
                                                                                        (
                                                                                            recPro.other /
                                                                                            100
                                                                                        ).toString(),
                                                                                    priceDis:
                                                                                        (
                                                                                            recPro.price /
                                                                                            100
                                                                                        ).toString(),
                                                                                    purCostDis:
                                                                                        (
                                                                                            recPro.purCost /
                                                                                            100
                                                                                        ).toString(),
                                                                                    traCostDis:
                                                                                        (
                                                                                            recPro.traCost /
                                                                                            100
                                                                                        ).toString(),
                                                                                }
                                                                            )
                                                                            setEdit(
                                                                                true
                                                                            )
                                                                        }}
                                                                        type='button'
                                                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                                    >
                                                                        <PencilAltIcon
                                                                            className='h-5 w-5 pr-1'
                                                                            aria-hidden='true'
                                                                        />
                                                                        <span>
                                                                            Edit
                                                                        </span>
                                                                    </button>
                                                                </span>
                                                            </td>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {(
                                                                recPro.shares /
                                                                100
                                                            ).toLocaleString()}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recPro.address}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recPro.area}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recPro.size}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recPro.bed}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recPro.bath}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recPro.utility}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recPro.amenities}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recPro.tenanted ===
                                                            false
                                                                ? "No"
                                                                : "Yes"}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {(
                                                                recPro.rent /
                                                                100
                                                            ).toLocaleString()}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {(
                                                                recPro.deposit /
                                                                100
                                                            ).toLocaleString()}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {(
                                                                recPro.other /
                                                                100
                                                            ).toLocaleString()}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {(
                                                                recPro.price /
                                                                100
                                                            ).toLocaleString()}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {(
                                                                recPro.purCost /
                                                                100
                                                            ).toLocaleString()}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {(
                                                                recPro.traCost /
                                                                100
                                                            ).toLocaleString()}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                recProIdx !==
                                                                    pros.length -
                                                                        1
                                                                    ? "border-b border-gray-200"
                                                                    : "",
                                                                "whitespace-nowrap px-3 py-4 text-sm text-gray-100"
                                                            )}
                                                        >
                                                            {recPro.status}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className=' z-20 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={error}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                {`Error ${errorDetails.code}: ${errorDetails.details}`}
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                {errorDetails.message}
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
            <Transition.Root show={edit} as={Fragment}>
                <Dialog as='div' className='relative z-10' onClose={setEdit}>
                    <div className='fixed inset-0' />

                    <div className='fixed inset-0 overflow-hidden'>
                        <div className='absolute inset-0 overflow-hidden'>
                            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
                                <Transition.Child
                                    as={Fragment}
                                    enter='transform transition ease-in-out duration-500 sm:duration-700'
                                    enterFrom='translate-x-full'
                                    enterTo='translate-x-0'
                                    leave='transform transition ease-in-out duration-500 sm:duration-700'
                                    leaveFrom='translate-x-0'
                                    leaveTo='translate-x-full'
                                >
                                    <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                                        <form className='flex h-full flex-col divide-y divide-gray-200 bg-gray-900 shadow-xl'>
                                            <div className='h-0 flex-1 overflow-y-auto'>
                                                <div className='bg-yellow-500 py-6 px-4 sm:px-6'>
                                                    <div className='flex items-center justify-between'>
                                                        <Dialog.Title className='text-lg font-medium text-gray-900'>
                                                            {`Editing Property ${proID}`}
                                                        </Dialog.Title>
                                                        <div className='ml-3 flex h-7 items-center'></div>
                                                    </div>
                                                    {/* <div className='mt-1'>
                                                        <p className='text-sm text-indigo-300'>
                                                            Get started by
                                                            filling in the
                                                            information below to
                                                            create your new
                                                            project.
                                                        </p>
                                                    </div> */}
                                                </div>
                                                {saving ? (
                                                    <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                                                        <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                                                            <svg
                                                                className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                fill='none'
                                                                viewBox='0 0 24 24'
                                                            >
                                                                <circle
                                                                    className='opacity-25'
                                                                    cx='12'
                                                                    cy='12'
                                                                    r='10'
                                                                    stroke='currentColor'
                                                                    strokeWidth='4'
                                                                ></circle>
                                                                <path
                                                                    className='opacity-75'
                                                                    fill='currentColor'
                                                                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                                                ></path>
                                                            </svg>
                                                            Saving ...
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='m-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-1'>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='shares'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Total Shares
                                                            </label>
                                                            <div className='mt-1'>
                                                                <NumberFormat
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                    name='shares'
                                                                    id='shares'
                                                                    value={
                                                                        sharesDis
                                                                    }
                                                                    displayType={
                                                                        "input"
                                                                    }
                                                                    thousandSeparator={
                                                                        true
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    onChange={(
                                                                        e: ChangeEvent<HTMLInputElement>
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                />
                                                            </div>

                                                            {invalid &&
                                                            (shares < 1 ||
                                                                isNaN(shares) ||
                                                                String(
                                                                    shares
                                                                ) === "") ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    total shares
                                                                    is invalid
                                                                    or empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='address'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Address
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={
                                                                        address
                                                                    }
                                                                    name='address'
                                                                    id='address'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            address === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    address is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='area'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Area
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={area}
                                                                    name='area'
                                                                    id='area'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            area === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    area is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='size'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Size
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={size}
                                                                    name='size'
                                                                    id='size'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            size === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    size is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='bed'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Bed
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={bed}
                                                                    name='bed'
                                                                    id='bed'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            bed === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    bed is empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='bath'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Bath
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={bath}
                                                                    name='bath'
                                                                    id='bath'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            bath === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    bath is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='utility'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Utility
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={
                                                                        utility
                                                                    }
                                                                    name='utility'
                                                                    id='utility'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            utility === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    utility is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='amenities'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Amenities
                                                            </label>
                                                            <div className='mt-1'>
                                                                <input
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    type='text'
                                                                    value={
                                                                        amenities
                                                                    }
                                                                    name='amenities'
                                                                    id='amenities'
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            amenities === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    amenities is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='amenities'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Tenanted
                                                            </label>
                                                            <div className='mt-1'>
                                                                <Switch
                                                                    checked={
                                                                        tenanted
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )
                                                                    }}
                                                                    className={classNames(
                                                                        tenanted
                                                                            ? "bg-yellow-400"
                                                                            : "bg-gray-200",
                                                                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                    )}
                                                                >
                                                                    <span className='sr-only'>
                                                                        Use
                                                                        setting
                                                                    </span>
                                                                    <span
                                                                        className={classNames(
                                                                            tenanted
                                                                                ? "translate-x-5"
                                                                                : "translate-x-0",
                                                                            "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                                        )}
                                                                    >
                                                                        <span
                                                                            className={classNames(
                                                                                tenanted
                                                                                    ? "opacity-0 ease-out duration-100"
                                                                                    : "opacity-100 ease-in duration-200",
                                                                                "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                            )}
                                                                            aria-hidden='true'
                                                                        >
                                                                            <svg
                                                                                className='h-3 w-3 text-gray-400'
                                                                                fill='none'
                                                                                viewBox='0 0 12 12'
                                                                            >
                                                                                <path
                                                                                    d='M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2'
                                                                                    stroke='currentColor'
                                                                                    strokeWidth={
                                                                                        2
                                                                                    }
                                                                                    strokeLinecap='round'
                                                                                    strokeLinejoin='round'
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <span
                                                                            className={classNames(
                                                                                tenanted
                                                                                    ? "opacity-100 ease-in duration-200"
                                                                                    : "opacity-0 ease-out duration-100",
                                                                                "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                                                            )}
                                                                            aria-hidden='true'
                                                                        >
                                                                            <svg
                                                                                className='h-3 w-3 text-indigo-600'
                                                                                fill='currentColor'
                                                                                viewBox='0 0 12 12'
                                                                            >
                                                                                <path d='M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z' />
                                                                            </svg>
                                                                        </span>
                                                                    </span>
                                                                </Switch>
                                                            </div>
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='rent'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Rent (per annum)
                                                            </label>
                                                            <div className='mt-1'>
                                                                <NumberFormat
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                    name='rent'
                                                                    id='rent'
                                                                    value={
                                                                        rentDis
                                                                    }
                                                                    displayType={
                                                                        "input"
                                                                    }
                                                                    thousandSeparator={
                                                                        true
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    onChange={(
                                                                        e: ChangeEvent<HTMLInputElement>
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            (rent < 1 ||
                                                                isNaN(rent) ||
                                                                String(
                                                                    purCost
                                                                ) === "") ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    rent is
                                                                    invalid or
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='deposit'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Deposit
                                                            </label>
                                                            <div className='mt-1'>
                                                                <NumberFormat
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                    name='deposit'
                                                                    id='deposit'
                                                                    value={
                                                                        depositDis
                                                                    }
                                                                    displayType={
                                                                        "input"
                                                                    }
                                                                    thousandSeparator={
                                                                        true
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    onChange={(
                                                                        e: ChangeEvent<HTMLInputElement>
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            (deposit < 1 ||
                                                                isNaN(
                                                                    deposit
                                                                ) ||
                                                                String(
                                                                    deposit
                                                                ) === "") ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    deposit is
                                                                    invalid or
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='other'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Other
                                                            </label>
                                                            <div className='mt-1'>
                                                                <NumberFormat
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                    name='other'
                                                                    id='other'
                                                                    value={
                                                                        otherDis
                                                                    }
                                                                    displayType={
                                                                        "input"
                                                                    }
                                                                    thousandSeparator={
                                                                        true
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    onChange={(
                                                                        e: ChangeEvent<HTMLInputElement>
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            (other < 0 ||
                                                                isNaN(
                                                                    other
                                                                )) ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    other is
                                                                    invalid
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='price'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Purchase Price
                                                            </label>
                                                            <div className='mt-1'>
                                                                <NumberFormat
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                    name='price'
                                                                    id='price'
                                                                    value={
                                                                        priceDis
                                                                    }
                                                                    displayType={
                                                                        "input"
                                                                    }
                                                                    thousandSeparator={
                                                                        true
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    onChange={(
                                                                        e: ChangeEvent<HTMLInputElement>
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            (price < 1 ||
                                                                isNaN(price) ||
                                                                String(
                                                                    price
                                                                ) === "") ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    purchase
                                                                    price is
                                                                    invalid or
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='purCost'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Purchase Cost
                                                            </label>
                                                            <div className='mt-1'>
                                                                <NumberFormat
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                    name='purCost'
                                                                    id='purCost'
                                                                    value={
                                                                        purCostDis
                                                                    }
                                                                    displayType={
                                                                        "input"
                                                                    }
                                                                    thousandSeparator={
                                                                        true
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    onChange={(
                                                                        e: ChangeEvent<HTMLInputElement>
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            (purCost < 1 ||
                                                                isNaN(
                                                                    purCost
                                                                ) ||
                                                                String(
                                                                    purCost
                                                                ) === "") ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    purchase
                                                                    price is
                                                                    invalid or
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='traCost'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Transaction Cost
                                                            </label>
                                                            <div className='mt-1'>
                                                                <NumberFormat
                                                                    className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                                                    name='traCost'
                                                                    id='traCost'
                                                                    value={
                                                                        traCostDis
                                                                    }
                                                                    displayType={
                                                                        "input"
                                                                    }
                                                                    thousandSeparator={
                                                                        true
                                                                    }
                                                                    decimalScale={
                                                                        2
                                                                    }
                                                                    onChange={(
                                                                        e: ChangeEvent<HTMLInputElement>
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                            {invalid &&
                                                            (traCost < 1 ||
                                                                isNaN(
                                                                    traCost
                                                                ) ||
                                                                String(
                                                                    traCost
                                                                ) === "") ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    transaction
                                                                    cost is
                                                                    invalid or
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className='sm:col-span-2'>
                                                            <label
                                                                htmlFor='status'
                                                                className='block text-sm font-medium text-gray-300'
                                                            >
                                                                Status
                                                            </label>
                                                            <div className='mt-1'>
                                                                <select
                                                                    value={
                                                                        status
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        onChange(
                                                                            e
                                                                        )

                                                                        setInvalid(
                                                                            false
                                                                        )
                                                                    }}
                                                                    id='status'
                                                                    name='status'
                                                                    className='bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md color'
                                                                >
                                                                    <option></option>
                                                                    <option>
                                                                        Not
                                                                        Funded
                                                                    </option>
                                                                    <option>
                                                                        Funded
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            {invalid &&
                                                            status === "" ? (
                                                                <span className='text-sm text-red-500'>
                                                                    Property's
                                                                    status is
                                                                    empty
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {saving ? (
                                                ""
                                            ) : (
                                                <div className='flex flex-shrink-0 justify-end px-4 py-4'>
                                                    <button
                                                        type='button'
                                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-gray-200 text-sm font-medium text-gray-900 hover:bg-blue-600 hover:text-gray-100 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            setEdit(false)
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            onSubmit()
                                                        }}
                                                        className='py-2 px-5 ml-5 relatives items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            )}
                                        </form>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default RecProView
