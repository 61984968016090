import { useState, ChangeEvent, useEffect, Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"

import { RootState } from "../app/store"
import { setRecFormState, setRecInv } from "../features/auth/recSlice"

import { supabase } from "../supabaseClient"

import { Transition } from "@headlessui/react"
import {
    ChevronRightIcon,
    XIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/solid"

type Inv = {
    invID: string
    title: string
    fore: string
    sur: string
    email: string
    mobile: string
    dob: string
    occupation: string
    bracket: string
    risk: string
}

const isInTheFuture = (givenDate: String) => {
    const now = new Date()

    now.setHours(23, 59, 59, 998)

    return (
        new Date(
            `${givenDate.substring(0, 4)}-${givenDate.substring(
                4,
                6
            )}-${givenDate.substring(6, 8)}`
        ) > now
    )
}

const RecInvForm = () => {
    const dispatch = useDispatch()
    const { recFormState, recInv } = useSelector(
        (state: RootState) => state.rec
    )
    const [invalid, setInvalid] = useState(false)
    const [error, setError] = useState(false)
    const [verifying, setVerifying] = useState(false)

    const today = new Date()

    const [invData, setInvData] = useState({
        invID: "",
        title: "",
        fore: "",
        sur: "",
        email: "",
        mobile: "",
        dob: `${today.getFullYear()}-${
            today.getMonth().toString().length === 1
                ? "0" + (today.getMonth() + 1).toString()
                : (today.getMonth() + 1).toString()
        }-${
            today.getDate().toString().length === 1
                ? "0" + today.getDate().toString()
                : today.getDate().toString()
        }`,
        occupation: "",
        bracket: "",
        risk: "",
    })

    useEffect(() => {
        if (recInv.invID === "") {
            setInvData({
                invID: "",
                title: "",
                fore: "",
                sur: "",
                email: "",
                mobile: "",
                dob: "",
                occupation: "",
                bracket: "",
                risk: "",
            })
        } else {
            setInvData(recInv)
        }
    }, [recInv])

    const {
        invID,
        title,
        fore,
        sur,
        email,
        mobile,
        dob,
        occupation,
        bracket,
        risk,
    } = invData

    const checkAvailable = async (givenID: String) => {
        setVerifying(true)

        const { data } = await supabase
            .from<Inv>("R_Inv")
            .select("invID")
            .eq("invID", `${givenID}`)

        setVerifying(false)

        return data?.length
    }

    const onSubmit = async () => {
        if (
            invID === "" ||
            title === "" ||
            fore === "" ||
            sur === "" ||
            email === "" ||
            !email.includes("@") ||
            !email.includes(".") ||
            email.substring(email.lastIndexOf(".")).length < 3 ||
            mobile === "" ||
            isNaN(parseInt(mobile)) ||
            dob === "" ||
            isInTheFuture(dob) ||
            occupation === "" ||
            bracket === "" ||
            risk === ""
        ) {
            setInvalid(true)
        } else {
            setInvalid(false)
            if ((await checkAvailable(invID)) === 0) {
                dispatch(setRecInv(invData))
                dispatch(setRecFormState(recFormState + 1))

                setError(false)
            } else {
                setError(true)
            }
        }
    }

    const onChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => {
        if (e.target.name === "dob") {
            setInvData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        } else if (e.target.name === "invID") {
            setInvData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value.replaceAll(" ", ""),
            }))
        } else {
            setInvData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        }
    }
    return (
        <>
            {verifying ? (
                <div className='md:max-w-3xl max-w-md mx-auto mt-5 flex flex-col items-center'>
                    <div className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-gray-100 bg-blue-600 transition ease-in-out duration-150 cursor-not-allowed'>
                        <svg
                            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                        >
                            <circle
                                className='opacity-25'
                                cx='12'
                                cy='12'
                                r='10'
                                stroke='currentColor'
                                strokeWidth='4'
                            ></circle>
                            <path
                                className='opacity-75'
                                fill='currentColor'
                                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                            ></path>
                        </svg>
                        Verifying ...
                    </div>
                </div>
            ) : (
                <div className=''>
                    <form className='mt-7 space-y-8 divide-y divide-gray-200'>
                        <div className='  space-y-8 divide-y divide-gray-200'>
                            <div className=''>
                                <div>
                                    <h3 className='text-lg leading-6 font-medium text-gray-200'>
                                        New Investor
                                    </h3>
                                    <p className='mt-1 text-sm text-gray-300'>
                                        Fill in the form below with the relevant
                                        investor details.
                                    </p>
                                </div>
                                <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                                    <div className='sm:col-span-1'>
                                        <label
                                            htmlFor='invID'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            Investor ID
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                value={invID}
                                                type='text'
                                                name='invID'
                                                id='invID'
                                                className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid && invID === "" ? (
                                            <span className='text-sm text-red-500'>
                                                Investor's ID is empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-1'>
                                        <label
                                            htmlFor='title'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            Title
                                        </label>
                                        <div className='mt-1'>
                                            <select
                                                value={title}
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                id='title'
                                                name='title'
                                                className='bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md color'
                                            >
                                                <option></option>
                                                <option>Sheikh</option>
                                                <option>Sheikha</option>
                                                <option>Ms</option>
                                                <option>Mrs</option>
                                                <option>Miss</option>
                                                <option>Mr</option>
                                                <option>Dr</option>
                                            </select>
                                        </div>
                                        {invalid && title === "" ? (
                                            <span className='text-sm text-red-500'>
                                                Investor's title is empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-2'>
                                        <label
                                            htmlFor='fore'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            Forename
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                type='text'
                                                value={fore}
                                                name='fore'
                                                id='fore'
                                                className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid && fore === "" ? (
                                            <span className='text-sm text-red-500'>
                                                Investor's forename is empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-2'>
                                        <label
                                            htmlFor='sur'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            Surname
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                type='text'
                                                value={sur}
                                                name='sur'
                                                id='sur'
                                                className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid && sur === "" ? (
                                            <span className='text-sm text-red-500'>
                                                Investor's surename is empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-2'>
                                        <label
                                            htmlFor='email'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            Email
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                type='email'
                                                value={email}
                                                name='email'
                                                id='email'
                                                className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid &&
                                        (email === "" ||
                                            !email.includes("@") ||
                                            !email.includes(".") ||
                                            email.substring(
                                                email.lastIndexOf(".")
                                            ).length < 3) ? (
                                            <span className='text-sm text-red-500'>
                                                Investor's email is invalid or
                                                empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-2'>
                                        <label
                                            htmlFor='mobile'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            Mobile Number
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                type='text'
                                                value={mobile}
                                                name='mobile'
                                                id='mobile'
                                                className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid &&
                                        (mobile === "" ||
                                            isNaN(parseInt(mobile))) ? (
                                            <span className='text-sm text-red-500'>
                                                Investor's mobile number is
                                                invalid or empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-1'>
                                        <label
                                            htmlFor='dob'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            Date of Birth
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)
                                                }}
                                                value={dob}
                                                type='date'
                                                name='dob'
                                                id='dob'
                                                className=' pb-1 appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid &&
                                        (isInTheFuture(dob) || dob === "") ? (
                                            <span className='text-sm text-red-500'>
                                                Investor's date of birth cannot
                                                be in the future or empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-2'>
                                        <label
                                            htmlFor='occupation'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            Occupation
                                        </label>
                                        <div className='mt-1'>
                                            <input
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                type='text'
                                                value={occupation}
                                                name='occupation'
                                                id='occupation'
                                                className=' appearance-none bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md'
                                            />
                                        </div>
                                        {invalid && occupation === "" ? (
                                            <span className='text-sm text-red-500'>
                                                Investor's occupation is empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-2'>
                                        <label
                                            htmlFor='bracket'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            Income Bracket
                                        </label>
                                        <div className='mt-1'>
                                            <select
                                                value={bracket}
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                id='bracket'
                                                name='bracket'
                                                className='bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md color'
                                            >
                                                <option></option>
                                                <option>5,000-9,999</option>
                                                <option>10,000-14,999</option>
                                                <option>15,000-19,999</option>
                                                <option>20,000-24,999</option>
                                                <option>25,000-29,999</option>
                                                <option>30,000-34,999</option>
                                                <option>35,000+</option>
                                            </select>
                                        </div>
                                        {invalid && bracket === "" ? (
                                            <span className='text-sm text-red-500'>
                                                Investor's income bracket is
                                                empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className='sm:col-span-2'>
                                        <label
                                            htmlFor='risk'
                                            className='block text-sm font-medium text-gray-300'
                                        >
                                            Risk Profile
                                        </label>
                                        <div className='mt-1'>
                                            <select
                                                value={risk}
                                                onChange={(e) => {
                                                    onChange(e)

                                                    setInvalid(false)
                                                }}
                                                id='risk'
                                                name='risk'
                                                className='bg-gray-600 text-gray-100 shadow-sm focus:ring-yellow-500 focus:border-yellow-500 block w-full sm:text-sm border-gray-400 rounded-md color'
                                            >
                                                <option></option>
                                                <option>Low</option>
                                                <option>Medium</option>
                                                <option>High</option>
                                            </select>
                                        </div>
                                        {invalid && risk === "" ? (
                                            <span className='text-sm text-red-500'>
                                                Investor's risk profile is empty
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='pt-5'>
                            <div className='flex justify-end'>
                                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault()
                                            onSubmit()
                                        }}
                                        type='button'
                                        className='py-2 px-5 relative inline-flex items-center rounded-md border-0  bg-blue-600 text-sm font-medium text-gray-100 hover:bg-gray-200 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500'
                                    >
                                        <span>Continue</span>
                                        <ChevronRightIcon
                                            className='h-5 w-5'
                                            aria-hidden='true'
                                        />
                                    </button>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            )}
            <>
                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live='assertive'
                    className='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start'
                >
                    <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
                        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                        <Transition
                            show={error}
                            as={Fragment}
                            enter='transform ease-out duration-300 transition'
                            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <div className='max-w-sm w-full bg-yellow-100 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
                                <div className='p-4'>
                                    <div className='flex items-start'>
                                        <div className='flex-shrink-0'>
                                            <ExclamationCircleIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='ml-3 w-0 flex-1 pt-0.5'>
                                            <p className='text-sm font-medium text-gray-800'>
                                                Investor Exists
                                            </p>
                                            <p className='mt-1 text-sm text-gray-900'>
                                                An investor with the given ID{" "}
                                                <span className=' font-bold'>
                                                    {invID}
                                                </span>{" "}
                                                already exists
                                            </p>
                                        </div>
                                        <div className='ml-4 flex-shrink-0 flex'>
                                            <button
                                                type='button'
                                                className=' rounded-md inline-flex text-blue-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                onClick={() => {
                                                    setError(false)
                                                }}
                                            >
                                                <span className='sr-only'>
                                                    Close
                                                </span>
                                                <XIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </>
        </>
    )
}

export default RecInvForm
