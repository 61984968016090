import { useSelector } from "react-redux"
import { RootState } from "../app/store"

import SideNav from "../components/SideNav"

import TraMain from "../components/TraMain"
import RecMain from "../components/RecMain"
import AccMain from "../components/AccMain"
import SetMain from "../components/SetMain"
import CtrPanel from "../components/CtrPanel"

const Main2 = () => {
    const { nav } = useSelector((state: RootState) => state.app)

    return (
        <>
            <div className='flex flex-row h-screen'>
                <div className='w-1/6'>
                    <SideNav />
                </div>
                <div className='w-5/6 bg-gray-800 '>
                    {nav === "Control Panel" ? (
                        <CtrPanel />
                    ) : nav === "Transactions" ? (
                        <TraMain />
                    ) : nav === "Accounts" ? (
                        <AccMain />
                    ) : nav === "Records" ? (
                        <RecMain />
                    ) : nav === "Settings" ? (
                        <SetMain />
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    )
}

export default Main2
