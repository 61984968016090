import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { supabase } from "../../supabaseClient"

import { Session } from "@supabase/gotrue-js/src/lib/types"

interface state {
    user: string
    session: Session | null
    isError: boolean
    isSuccess: boolean
    isLoading: boolean
    message: string
    role: {
        id: string
        user_email: string
        ctr_v: boolean
        acc_v: boolean
        rep_v: boolean
        tra_v: boolean
        tra_i: boolean
        tra_e: boolean
        rec_v: boolean
        rec_i: boolean
        rec_e: boolean
        set_r: boolean
    }
}

const initialState: state = {
    user: "",
    session: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    role: {
        id: "",
        user_email: "",
        ctr_v: false,
        acc_v: false,
        rep_v: false,
        tra_v: false,
        tra_i: false,
        tra_e: false,
        rec_v: false,
        rec_i: false,
        rec_e: false,
        set_r: false,
    },
}

export const login = createAsyncThunk(
    "auth/login",
    async (email: string, thunkAPI) => {
        try {
            const { error } = await supabase.auth.signIn(
                {
                    email: email,
                },
                { shouldCreateUser: false }
            )
            if (error) {
                return thunkAPI.rejectWithValue(error.message)
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(
                (error as any).description || (error as Error).message
            )
        }
    }
)

export const logout = createAsyncThunk("auth/logout", async (arg, thunkAPI) => {
    try {
        const { error } = await supabase.auth.signOut()
        if (error) {
            return thunkAPI.rejectWithValue(error.message)
        }
    } catch (error) {
        return thunkAPI.rejectWithValue(
            (error as any).description || (error as Error).message
        )
    }
})

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ""
        },
        setSession: (state) => {
            state.session = supabase.auth.session()
        },
        updateSession: (state, action) => {
            state.session = action.payload
        },
        setRole: (state, action) => {
            state.role = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload as string
            })
            .addCase(logout.fulfilled, (state) => {
                state.role = {
                    id: "",
                    user_email: "",
                    ctr_v: false,
                    acc_v: false,
                    rep_v: false,
                    tra_v: false,
                    tra_i: false,
                    tra_e: false,
                    rec_v: false,
                    rec_i: false,
                    rec_e: false,
                    set_r: false,
                }
            })
    },
})

export const { reset, setSession, updateSession, setRole } = authSlice.actions
export default authSlice.reducer
