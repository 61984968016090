import { useState, CSSProperties } from "react"

import { useDispatch } from "react-redux"
import { setSPVSs, setCSVComplete, clearSPVSs } from "../features/auth/recSlice"

import {
    useCSVReader,
    lightenDarkenColor,
    formatFileSize,
} from "react-papaparse"

const GREY = "#CCC"
const GREY_LIGHT = "rgba(255, 255, 255, 0.9)"
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919"
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
    DEFAULT_REMOVE_HOVER_COLOR,
    40
)
const YELLOW = "rgb(234 179 8)"

const styles = {
    zone: {
        alignItems: "center",
        border: `2px dashed ${GREY}`,
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        padding: 20,
    } as CSSProperties,
    file: {
        background: "linear-gradient(to bottom, #EEE, #DDD)",
        borderRadius: 20,
        display: "flex",
        height: 120,
        width: 120,
        position: "relative",
        zIndex: 10,
        flexDirection: "column",
        justifyContent: "center",
    } as CSSProperties,
    info: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        paddingLeft: 10,
        paddingRight: 10,
    } as CSSProperties,
    size: {
        backgroundColor: GREY_LIGHT,
        borderRadius: 3,
        marginBottom: "0.5em",
        justifyContent: "center",
        display: "flex",
    } as CSSProperties,
    name: {
        backgroundColor: GREY_LIGHT,
        borderRadius: 3,
        fontSize: 12,
        marginBottom: "0.5em",
    } as CSSProperties,
    progressBar: {
        bottom: 14,
        position: "absolute",
        width: "100%",
        paddingLeft: 10,
        paddingRight: 10,
    } as CSSProperties,
    zoneHover: {
        borderColor: YELLOW,
    } as CSSProperties,
    default: {
        borderColor: GREY,
    } as CSSProperties,
    remove: {
        height: 23,
        position: "absolute",
        right: 6,
        top: 6,
        width: 23,
    } as CSSProperties,
}

type rowData = {
    data: { spvID: string; invID: string; shares: string }
    errors: any
    meta: any
}

export default function CSVReader() {
    const { CSVReader } = useCSVReader()
    const [zoneHover, setZoneHover] = useState(false)
    const [removeHoverColor, setRemoveHoverColor] = useState(
        DEFAULT_REMOVE_HOVER_COLOR
    )
    const dispatch = useDispatch()

    return (
        <CSVReader
            config={{
                skipEmptyLines: true,
                header: true,
                beforeFirstChunk: () => {
                    dispatch(clearSPVSs())
                },
                step: (row: rowData) => {
                    if (row.data.shares.includes(",")) {
                        const newRow = {
                            ...row,
                            data: {
                                ...row.data,
                                shares: row.data.shares.replaceAll(",", ""),
                            },
                        }
                        dispatch(setSPVSs(newRow.data))
                    } else {
                        dispatch(setSPVSs(row.data))
                    }
                },
                complete: () => {
                    dispatch(setCSVComplete(true))
                },
            }}
            onUploadAccepted={(results: any) => {
                // dispatch(setSPVSs(results.data))
                // dispatch(setCSVComplete(true))
            }}
            onDragOver={(event: DragEvent) => {
                event.preventDefault()
                setZoneHover(true)
            }}
            onDragLeave={(event: DragEvent) => {
                event.preventDefault()
                setZoneHover(false)
            }}
            noClick
        >
            {({
                getRootProps,
                acceptedFile,
                ProgressBar,
                getRemoveFileProps,
                Remove,
            }: any) => (
                <>
                    <div
                        {...getRootProps()}
                        style={Object.assign(
                            {},
                            styles.zone,
                            zoneHover && styles.zoneHover
                        )}
                    >
                        {acceptedFile ? (
                            <>
                                <div style={styles.file}>
                                    <div style={styles.info}>
                                        <span style={styles.size}>
                                            {formatFileSize(acceptedFile.size)}
                                        </span>
                                        <span style={styles.name}>
                                            {acceptedFile.name}
                                        </span>
                                    </div>
                                    <div style={styles.progressBar}>
                                        <ProgressBar />
                                    </div>
                                    <div
                                        {...getRemoveFileProps()}
                                        style={styles.remove}
                                        onMouseOver={(event: Event) => {
                                            event.preventDefault()
                                            setRemoveHoverColor(
                                                REMOVE_HOVER_COLOR_LIGHT
                                            )
                                        }}
                                        onMouseOut={(event: Event) => {
                                            event.preventDefault()
                                            setRemoveHoverColor(
                                                DEFAULT_REMOVE_HOVER_COLOR
                                            )
                                        }}
                                    >
                                        <Remove color={removeHoverColor} />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <span className='text-gray-200'>
                                Drag csv file into dotted oval
                            </span>
                        )}
                    </div>
                </>
            )}
        </CSVReader>
    )
}
