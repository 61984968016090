import { ExclamationIcon } from "@heroicons/react/solid"

import { useSelector } from "react-redux"
import { RootState } from "../app/store"

const MtnAlert = () => {
    const { mtn } = useSelector((state: RootState) => state.app)
    if (mtn.urgent) {
        return (
            <div className='rounded-md bg-red-900 p-4'>
                <div className='flex'>
                    <div className='flex-shrink-0'>
                        <ExclamationIcon
                            className='h-10 w-auto text-red-100'
                            aria-hidden='true'
                        />
                    </div>
                    <div className='ml-3'>
                        <h3 className='text-sm font-medium text-gray-100'>
                            Urgent Maintenance Alert
                        </h3>
                        <div className='mt-2 text-sm text-red-100'>
                            <p>{mtn.text}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='rounded-md bg-yellow-800 p-4'>
                <div className='flex'>
                    <div className='flex-shrink-0'>
                        <ExclamationIcon
                            className='h-10 w-auto text-yellow-100'
                            aria-hidden='true'
                        />
                    </div>
                    <div className='ml-3'>
                        <h3 className='text-sm font-medium text-gray-100'>
                            Maintenance Alert
                        </h3>
                        <div className='mt-2 text-sm text-yellow-100'>
                            <p>{mtn.text}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MtnAlert
